export default {
  centerDemo: 'Solar Energy Center',
  login: 'Login',
  sideBarItems: {
    home: 'Home',
    realTime: 'Real Time',
    siteComparation: 'Site Comparation',
    fangDe: 'factory2',
    fuQiangXin: 'factory3',
    fuQiangXinDemoPage: 'Demo Page',
    lineChart: 'Line Chart'
  },
  realTime: {
    title: 'Real Time',
    status: {
      systemNormal: 'Normal',
      noPowerData: 'No Power Data',
      partialSystemError: 'Partial Error',
      backfill: 'Backfill Data',
      deviceDisconnected: 'Disconnected',
      deviceDisconnectedShort: 'Shortly Disconnected',
      noData: 'No Data',
      poorPower: 'Poor Power Generation Efficiency'
    },
    table: {
      Capacity: 'Capacity(kWp)',
      irradiance: 'Irradiance(W/m²)',
      PVTemperature: 'PV Temperature(°C)',
      DCPower: 'DC Power(kW)',
      ACPower: 'AC Power(kW)',
      TodayCommission: 'Today(kWh)',
      INV: 'INV',
      RA: 'RA(%)',
      PR: 'PR(%)',
      SiteStatus: 'Site Status'
    },
    dataFound: 'Data Found'
  },
  siteComparation: {
    goodRate: 'Availability',
    peakWatt: 'Mean Peak Watt (kWh/kWp) Comparation',
    INVpeakWatt: 'Each of INV Peak Watt (kWh/kWp) Comparation'
  },
  fangDe: {
    title: 'Solar Energy Production and Consumption Analysis',
    selectTime: 'Set period to download csv file',
    to: 'To',
    chartTitleHour: 'Power Generation And Electricity Informations Per Hour',
    chartTitleDay: 'Power Generation And Electricity Informations Per Day',
    chartTitleMon: 'Power Generation And Electricity Informations Per Month'
  },
  fuQiangXin: {
    title: 'Aggregated Electricity Informations',
    chartTitle: 'Solar Photovoltaic System Monitoring Screen (LIVE)'
  },
  FuQiangXinDemoPage: {
    title: 'Live Data Informations',
    status: {
      systemNormal: 'System Normal',
      partialSystemError: 'Partial System Error',
      systemError: 'System Error',
      deviceDisconnected: 'Device Disconnected'
    }
  },
  lineChart: {
    title: 'Charts',
    selectDate: '',
    startTime: '',
    endTime: '',
    endDate: '',
    tableTr: {
      id: 'ID',
      device: 'Devices',
      curveColor: 'Color',
      curveType: 'Type',
      func: 'Button'
    }
  },
  tableTr: {
    delete: 'Delete',
    show: 'Show'
  },
  siteInfo: {
    energyInfo: 'Energy Info',
    DeviceStatus: 'Device Status',
    DeviceHistory: 'Device History',
    DataAnalysis: 'Data Analysis',
    DesignInfo: 'Design Info',
    FailureAnalysis: 'Failure Analysis',
    LatestInformation: 'Latest Information',
    InvestmentAnalysis: 'Investment Analysis',
    report: 'Report',
    otherInfo: 'Other Information'
  },
  siteInfoDetail: {
    siteInfo: 'Site Info',
    siteInfoKey: {
      startUpTime: 'Startup Time',
      customId: 'Custom Id',
      deviceDirection: 'Device Vane',
      deviceRegion: 'Device Region',
      country: 'Country',
      county: 'County',
      townShip: 'Township'
    },
    siteInfoChoseDate: {
      最近30天: 'Last 30 Days',
      時歷史: 'Hourly History',
      日歷史: 'Daily History',
      月歷史: 'Monthly History',
      年歷史: 'Yearly History',
      變流器歷史: 'inverter History'
    },
    hightChartName: {
      Energy: 'PowerGeneration (kWh)',
      insolation: 'Sunshine (kWh/m²)',
      PerformanceRatio: 'PerformanceRatio',
      PVTemperature: 'PVTemperature (°C)',
      Revenue: 'Revenue (NT)'
    },
    accumulativePowerGeneration: 'Accumulative Power Generation',
    todayPowerGeneration: 'Today Power Generation',
    showMore: 'show More',
    liveInfo: 'Live Info',
    irr: 'Irradiance (w/m²)',
    eToday: 'Insolation (kWh/m²)',
    peak: 'Peak Watt (Today) (kWh/kWp)',
    pr: 'PR (%)',
    lowCurrent: 'Low Volt Power (kW)',
    highCurrent: 'High Volt Power (kW)',
    moduleTemp: 'PV Temp (°C)',
    envTemp: 'Ambient Temperature (°C)',
    anemometer: 'Instant Wind Speed (m/s)',
    windVane: 'Wind Vane (°)',
    waterLevel: 'Water Level (m)'
  },
  deviceStatus: {
    deviceLiveInformation: 'Device Live Information(Per Minutes)',
    invLiveInformation: 'INV Live Information',
    connectTime: 'Connecting Time:',
    download: 'Download',
    confirm: 'Confirm',
    hightChartName: {
      cumulativePowerGeneration: 'CumulativePowerGeneration (kWh)',
      todayPowerGeneration: 'TodayPowerGeneration (kWh)',
      acPowerGeneration: 'AcPowerGeneration',
      dcPowerGeneration: 'DcPowerGeneration'
    },
    tableTitle: {
      inv: 'INV',
      connectionStatus: 'Connection status',
      accumulativePowerGeneration: 'AccumulativePowerGeneration (kWh)',
      todayPowerGeneration: 'TodayPowerGeneration (kWh)',
      dcPowerGeneration: 'DcPowerGeneration (kW)',
      acPowerGeneration: 'AcPowerGeneration (kW)',
      invEffectiveness: 'InvEffectiveness (%)',
      frequency: 'Frequency(Hz)',
      abVoltage: 'Vab (V)',
      bcVoltage: 'Vbc (V)',
      caVoltage: 'Vca (V)',
      APhaseCurrent: 'A phase current (A)',
      BPhaseCurrent: 'B phase current (A)',
      CPhaseCurrent: 'C phase current (A)'
    }
  },
  deviceHistory: {
    selectINV: 'Select INV',
    Date: 'Date',
    download: 'Download',
    specifiedINVInfoPerMin: 'Specified INV Information Per Min',
    confirm: 'Confirm',
    tableTitle: {
      Time: 'Time',
      frequency: 'Frequency(Hz)',
      abVoltage: 'Vab (V)',
      bcVoltage: 'Vbc (V)',
      caVoltage: 'Vca (V)',
      APhaseCurrent: 'A phase current (A)',
      BPhaseCurrent: 'B phase current (A)',
      CPhaseCurrent: 'C phase current (A)'
    }
  },
  dataAnalysis: {
    electricityRevenue: 'Electricity Revenue',
    INVEfficiency: 'INV Efficiency',
    averageInsolation: 'Average Insolation',
    moduleTemp: 'module Temp',
    PR: 'PR',
    peakWatt: 'Peak Watt',
    peakWattHeatmap: 'Peak Watt Heat Map',
    CO2Reduction: 'CO2 Reduction',
    invTemperature: 'INV Temp.',
    insulation: 'Insulation',
    anemometer: 'Anemometer',
    availability: 'Availability',
    selectINV: 'Select INV',
    today: 'Start Date',
    hour: 'H',
    date: 'D',
    month: 'M',
    year: 'Y',
    INVComparison: 'Statistics and Income',
    INVComparison1: 'Inverter Effectiveness',
    INVComparison2: 'Sunshine',
    INVComparison3: 'Temperature',
    INVComparison4: 'PR Value',
    INVComparison5: 'Peak Watt',
    INVComparison6: 'Peak Watt Heat Map',
    INVComparison7: 'CO2',
    INVComparison8: 'INV Temperature',
    INVComparison9: 'Insulation',
    INVComparison10: 'Anemometer',
    INVComparison11: 'Availability',
    powerGeneration: 'Power Generation',
    investmentIncome: 'Investment Income',
    download: 'Download',
    INV: 'Inverter',
    dataAnalysisChartName: {
      time: 'Time',
      eleHour: 'Power generation hours (h)',
      PowerGeneration: 'PowerGeneration (kWh)',
      investmentIncome: 'InvestmentIncome',
      Conversionefficiency: 'Conversion efficiency(%)',
      Sunshine: 'Sunshine (kWh/m²)',
      avgSunshine: 'AvgSunshine (kWh/m²)',
      PVTemperature: 'PV Temperature (°C)',
      Temperature: 'Temperature (°C)',
      PR: 'PR Value (%)',
      PeakWatt: 'Peak Watt (kWh/kWp)',
      SunshineHour: 'Sunshine hours(h)',
      CO2: 'CO2 (kg)',
      tree: 'Tree'
    }
  },
  pvstringfailureanalysis: {
    liveInfo: 'Live Information',
    groupConfiguration: 'Group Configuration',
    circleChart: 'Circle Chart',
    historyData: 'History Data'
  },
  queryhistorydata: {
    selectTime: 'Select Time'
  },
  powerFee: {
    pvInfo: 'Real-time details of serial monitoring values',
    connectTime: 'Connection Time',
    download: 'Download'
  },
  weekday: {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun'
  }
}
