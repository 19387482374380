// import { BButton } from 'bootstrap-vue'

export default {
  name: 'EnergyManagement',
  // components: {
  //   BButton
  // },
  data: function data() {
    return {
      title: '電能管理'
    };
  }
};