import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "mt-5"
  }, [_c('DualListBox', {
    attrs: {
      "source": _vm.dualSource,
      "destination": _vm.dualDestination,
      "label": "name"
    },
    on: {
      "onChangeList": _vm.onChangeList
    }
  })], 1), _c('b-row', {
    staticClass: "mt-2",
    attrs: {
      "align-h": "start"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "lg",
      "variant": "primary",
      "disabled": !_vm.dualDestination.length
    },
    on: {
      "click": _vm.addGroup
    }
  }, [_vm._v("新增群組")])], 1), _c('b-row', [_c('b-list-group', _vm._l(_vm.groups, function (i, idx) {
    return _c('b-list-group-item', {
      key: i.id,
      staticClass: "d-flex flex-row align-items-center mt-4"
    }, [_c('div', {
      staticClass: "mr-2"
    }, [_vm._v("第 " + _vm._s(i[0]['群組']) + " 組")]), _vm._l(i, function (j, jdx) {
      return _c('div', {
        key: j.id
      }, [_vm._v(_vm._s(j.name + ' ' + (jdx + 1 === i.length ? '' : ',') + ' ') + " ")]);
    }), _c('b-badge', {
      staticClass: "ml-2 mr-2 align-self-end",
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_vm._v(_vm._s(i.length))]), _c('b-button', {
      staticStyle: {
        "margin-left": "auto"
      },
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.cancelGroup(i, idx);
        }
      }
    }, [_vm._v("取消")])], 2);
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };