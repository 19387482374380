export default {
  name: 'CircleChart',
  data: function data() {
    return {
      options: {
        chart: {
          type: 'pie'
        },
        series: [{
          data: [1, 2, 3]
        }]
      }
    };
  }
};