import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm$data, _vm$data$factoryName, _vm$data4$factoryName, _vm$data2, _vm$data4$address, _vm$data3, _vm$data4, _vm$data4$cap$split$, _vm$data4$conbinedDat, _vm$data5;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bg d-flex justify-content-center align-items-center overflow-hidden"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoadingData,
      "spinner-variant": "secondary",
      "no-wrap": ""
    }
  }), _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "contentRow d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "contentColumn"
  }, [_vm._m(0), _c('div', {
    staticClass: "block totalPower shineLine d-flex flex-column justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._m(1), _c('div', [_c('span', {
    staticClass: "spacing-5 num"
  }, [_vm._v(_vm._s(_vm.data2.todayPowerSum))]), _vm._v("  度 ")])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._m(2), _c('div', [_c('span', {
    staticClass: "spacing-5 num"
  }, [_vm._v(_vm._s(_vm.data2.monthPowerSum))]), _vm._v("  度 ")])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._m(3), _c('div', [_c('span', {
    staticClass: "spacing-5 num"
  }, [_vm._v(_vm._s(_vm.data2.yearPowerSum))]), _vm._v("  度 ")])])]), _c('div', {
    staticClass: "block totalSite shineLine d-flex flex-column"
  }, [_vm._m(4), _c('div', {
    staticClass: "blockContent d-flex flex-column justify-content-between"
  }, [_vm._m(5), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "region_title"
  }, [_vm._v("北區")]), _c('div', {
    staticClass: "num siteNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.northSiteTotal) + " ")]), _c('div', {
    staticClass: "num deviceNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.northCapacityTotal) + " MW ")])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "region_title"
  }, [_vm._v("中區")]), _c('div', {
    staticClass: "num siteNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.midSiteTotal) + " ")]), _c('div', {
    staticClass: "num deviceNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.midCapacityTotal) + " MW ")])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "region_title"
  }, [_vm._v("南區")]), _c('div', {
    staticClass: "num siteNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.southSiteTotal) + " ")]), _c('div', {
    staticClass: "num deviceNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.southCapacityTotal) + " MW ")])]), _c('div', {
    staticClass: "my-1 divider"
  }), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "region_title"
  }, [_vm._v("總計")]), _c('div', {
    staticClass: "num siteNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.siteTotal) + " ")]), _c('div', {
    staticClass: "num deviceNum"
  }, [_vm._v(" " + _vm._s(_vm.data3.capacityTotal) + " MW ")])])])])]), _c('div', {
    staticClass: "contentColumn"
  }, [_c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData4Show,
      expression: "isData4Show"
    }],
    staticClass: "block siteInfo shineLine d-flex justify-content-center align-items-center",
    class: {
      'en-text': (_vm$data = _vm.data4) === null || _vm$data === void 0 ? void 0 : (_vm$data$factoryName = _vm$data.factoryName) === null || _vm$data$factoryName === void 0 ? void 0 : _vm$data$factoryName.includes('TotalEnergies')
    }
  }, [_c('div', [_c('div', [_vm._v(" 案場名稱： "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$data4$factoryName = (_vm$data2 = _vm.data4) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.factoryName) !== null && _vm$data4$factoryName !== void 0 ? _vm$data4$factoryName : "--"))])]), _c('div', [_vm._v(" 案場地點： "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$data4$address = (_vm$data3 = _vm.data4) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.address) !== null && _vm$data4$address !== void 0 ? _vm$data4$address : "--"))])]), _c('div', [_vm._v(" 裝置容量： "), _c('span', {
    staticClass: "spacing-5 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$data4 = _vm.data4) !== null && _vm$data4 !== void 0 && _vm$data4.cap ? (_vm$data4$cap$split$ = _vm.data4.cap.split("，共")[1]) !== null && _vm$data4$cap$split$ !== void 0 && _vm$data4$cap$split$.split("kWp")[0] ? _vm.data4.cap.split("，共")[1].split("kWp")[0].trim() : _vm.extractNumber(_vm.data4.cap) : "--") + " kWp")])]), _c('div', [_vm._v(" 併網時間： "), _c('span', {
    staticClass: "spacing-5 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$data4$conbinedDat = (_vm$data5 = _vm.data4) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.conbinedDate) !== null && _vm$data4$conbinedDat !== void 0 ? _vm$data4$conbinedDat : "--"))])])])])]), _c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData5Show,
      expression: "isData5Show"
    }],
    staticClass: "block photo"
  }, [_c('b-carousel', {
    attrs: {
      "id": "carousel-fade",
      "fade": "",
      "interval": _vm.imageTimeInterval
    },
    model: {
      value: _vm.slideIndex,
      callback: function callback($$v) {
        _vm.slideIndex = $$v;
      },
      expression: "slideIndex"
    }
  }, _vm._l(_vm.showImageList, function (i, index) {
    return _c('b-carousel-slide', {
      key: index,
      scopedSlots: _vm._u([{
        key: "img",
        fn: function fn() {
          return [_c('div', {
            staticClass: "photo"
          }, [_c('img', {
            staticClass: "siteImg",
            attrs: {
              "src": i,
              "alt": "Site Image"
            }
          })])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)]), _c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData6Show,
      expression: "isData6Show"
    }],
    ref: "refTodayPower",
    staticClass: "block powerWrap shineLine d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "desc d-flex font-weight-bold"
  }, [_c('div', [_vm._v(" 今日發電量： "), _c('span', {
    staticClass: "spacing-5"
  }, [_vm._v(_vm._s(_vm.data6.dayKwhSum))]), _vm._v(" 度   ")]), _c('div', [_vm._v(" 售電金額： "), _c('span', [_vm._v(_vm._s(_vm.data6.daySellPriceSum))]), _vm._v(" 元 ")])]), _c('div', {
    staticClass: "powerChart"
  }, [_c('highcharts', {
    attrs: {
      "options": _vm.data6.chart
    }
  })], 1)])])], 1), _c('div', {
    staticClass: "contentColumn"
  }, [_c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData7Show,
      expression: "isData7Show"
    }],
    staticClass: "weather block shineLine d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-auto"
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.today))]), _c('div', {
    staticClass: "county"
  }, [_vm._v(" " + _vm._s(_vm.data7.address) + " ")]), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm.data7.desc) + " ")])]), _c('div', {
    staticClass: "d-flex align-items-center position-relative"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "temp ml-auto"
  }, [_vm._v(_vm._s(_vm.data7.tempRangeDesc))]), _c('div', {
    staticClass: "d-flex ml-auto"
  }, [_vm.data7.rain ? _c('div', {
    staticClass: "d-flex align-items-center ml-auto"
  }, [_c('img', {
    staticClass: "rainIcon",
    attrs: {
      "src": require("../assets/umbrella.png"),
      "alt": "Umbrella Image"
    }
  }), _c('span', {
    staticClass: "rainText"
  }, [_vm._v(" " + _vm._s(_vm.data7.rain) + " mm ")])]) : _vm._e(), _vm.data7.clouds ? _c('div', {
    staticClass: "d-flex align-items-center ml-3"
  }, [_c('img', {
    staticClass: "cloudIcon",
    attrs: {
      "src": require("../assets/cloud.png"),
      "alt": "Umbrella Image"
    }
  }), _c('span', {
    staticClass: "rainText"
  }, [_vm._v(" " + _vm._s(_vm.data7.clouds) + " % ")])]) : _vm._e()])]), _c('div', {
    staticClass: "weatherImgWrap"
  }, [_c('img', {
    staticClass: "weatherImg",
    attrs: {
      "src": _vm.data7.imgUrl
    }
  })])])]), _c('div', [_c('div', {
    staticClass: "weekWeatherTitle d-flex align-items-center"
  }, [_c('div', {
    staticClass: "text-nowrap mr-3"
  }, [_vm._v("一週天氣預報")]), _c('div', {
    staticClass: "divider w-100"
  })]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, _vm._l(_vm.data7.weekDayList, function (weekObj) {
    return _c('div', {
      key: weekObj.weekday,
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('div', {
      staticClass: "weekDayText"
    }, [_vm._v(_vm._s(weekObj.weekday))]), _c('div', {
      staticClass: "weekWeatherImg",
      style: {
        backgroundImage: "url( ".concat(weekObj.imgUrl, " )")
      }
    }), _c('div', {
      staticClass: "weekTempText"
    }, [_vm._v(_vm._s(weekObj.tempRange))])]);
  }), 0)])])]), _c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData8Show,
      expression: "isData8Show"
    }],
    staticClass: "block powerWrap shineLine d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "desc d-flex font-weight-bold"
  }, [_c('div', [_vm._v(" 本月發電量： "), _c('span', {
    staticClass: "spacing-5"
  }, [_vm._v(_vm._s(_vm.data8.monKwhSum))]), _vm._v(" 度   ")]), _c('div', [_vm._v(" 售電金額： "), _c('span', [_vm._v(_vm._s(_vm.data8.monSellPriceSum))]), _vm._v(" 元 ")])]), _c('div', {
    staticClass: "powerChart"
  }, [_c('highcharts', {
    attrs: {
      "options": _vm.data8.chart
    }
  })], 1)])]), _c('transition', {
    attrs: {
      "name": "cardLooping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isData9Show,
      expression: "isData9Show"
    }],
    staticClass: "block powerWrap shineLine d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "desc d-flex font-weight-bold"
  }, [_c('div', [_vm._v(" 今年發電量： "), _c('span', {
    staticClass: "spacing-5"
  }, [_vm._v(_vm._s(_vm.data9.yearKwhSum))]), _vm._v(" 度   ")]), _c('div', [_vm._v(" 售電金額： "), _c('span', [_vm._v(_vm._s(_vm.data9.yearSellPriceSum))]), _vm._v(" 元 ")])]), _c('div', {
    staticClass: "powerChart"
  }, [_c('highcharts', {
    attrs: {
      "options": _vm.data9.chart
    }
  })], 1)])])], 1)]), _c('transition', {
    attrs: {
      "name": "timeFlipping"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isUpdateTimeShow,
      expression: "isUpdateTimeShow"
    }],
    staticClass: "footer"
  }, [_vm._v(" 更新於 " + _vm._s(_vm.updateTime) + " ")])])], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "block logo"
  }, [_c('img', {
    staticClass: "logoImg",
    attrs: {
      "src": require("../assets/acme-logo-lg.png"),
      "alt": "ACME Logo Image"
    }
  }), _c('div', {
    staticClass: "logoTitle"
  }, [_vm._v("進金生能源服務股份有限公司")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticClass: "flashImg",
    attrs: {
      "src": require("../assets/flash.png"),
      "alt": "Flash Icon Image"
    }
  }), _vm._v("今日總發電量 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticClass: "flashImg",
    attrs: {
      "src": require("../assets/flash.png"),
      "alt": "Flash Icon Image"
    }
  }), _vm._v("本月總發電量 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('img', {
    staticClass: "flashImg",
    attrs: {
      "src": require("../assets/flash.png"),
      "alt": "Flash Icon Image"
    }
  }), _vm._v("今年總發電量 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "blockTitle d-flex align-items-center"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/acme-icon.png"),
      "alt": "ACME Icon Image",
      "height": "100%"
    }
  }), _c('div', {
    staticClass: "iconText"
  }, [_vm._v("進金生維護運轉中")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "region_title"
  }), _c('div', {
    staticClass: "siteNum"
  }, [_vm._v("案場數")]), _c('div', {
    staticClass: "deviceTitle"
  }, [_vm._v("   裝置量")])]);
}];
export { render, staticRenderFns };