var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("信件補發")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "m-2"
  }, [_vm._v("群組列表 :")]), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2 mb-3"
  }, [_c('date-picker', {
    staticClass: "datePickerInput m-2",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "placeholder": "寄送日期",
      "format": "YYYY-MM-DD",
      "value-type": "format",
      "disabled-date": _vm.disabledDateAfterYesterday
    },
    on: {
      "change": function change($event) {
        return _vm.getDailyreport(null);
      }
    },
    model: {
      value: _vm.sendTime,
      callback: function callback($$v) {
        _vm.sendTime = $$v;
      },
      expression: "sendTime"
    }
  }), _c('span', {
    staticClass: "mr-2"
  }, [_c('b-form-input', {
    attrs: {
      "list": "input-group",
      "size": "sm"
    },
    on: {
      "change": function change($event) {
        return _vm.getDailyreport(true);
      }
    },
    model: {
      value: _vm.selectDailyReport,
      callback: function callback($$v) {
        _vm.selectDailyReport = $$v;
      },
      expression: "selectDailyReport"
    }
  }), _c('datalist', {
    attrs: {
      "id": "input-group"
    }
  }, _vm._l(_vm.dailyReportArray, function (i, idx) {
    return _c('option', {
      key: idx,
      domProps: {
        "value": i.group
      }
    }, [_vm._v(" " + _vm._s(i.factories) + " ")]);
  }), 0)], 1), _c('button', {
    staticClass: "deleteButton",
    on: {
      "click": function click($event) {
        _vm.selectDailyReport = '';
      }
    }
  }, [_vm._v(" 清空 ")])], 1), _c('div', {
    staticClass: "m-2"
  }, [_vm._v("收件人 :")]), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2 mb-3"
  }, _vm._l(_vm.sendmailArray, function (i, idx) {
    return _c('div', {
      key: idx,
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-input', {
      staticClass: "textInput m-2",
      attrs: {
        "value": i,
        "size": "sm"
      }
    }), _c('button', {
      staticClass: "deleteButton",
      on: {
        "click": function click($event) {
          return _vm.emailSplice(idx);
        }
      }
    }, [_vm._v("移除")])], 1);
  }), 0), _c('div', {
    staticClass: "m-2"
  }, [_vm._v("新增收件人 :")]), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2 mb-3"
  }, [_c('b-form-input', {
    staticClass: "textInput m-2",
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.newEmailsValue,
      callback: function callback($$v) {
        _vm.newEmailsValue = $$v;
      },
      expression: "newEmailsValue"
    }
  }), _c('button', {
    staticClass: "confirmButton",
    on: {
      "click": _vm.emailPush
    }
  }, [_vm._v("新增")])], 1), _c('div', {
    staticClass: "m-2"
  }, [_vm._v("增加資訊 :")]), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2 mb-3"
  }, [_c('b-form-checkbox-group', {
    staticClass: "ml-3 mt-1",
    attrs: {
      "options": _vm.selectPROptions
    },
    model: {
      value: _vm.selectedPR,
      callback: function callback($$v) {
        _vm.selectedPR = $$v;
      },
      expression: "selectedPR"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2 mb-3"
  }, [_vm.isLoading ? _c('b-spinner', {
    staticClass: "m-4",
    attrs: {
      "big": ""
    }
  }) : _vm._e(), _vm._l(_vm.dailySiteArray, function (data, dataIdx) {
    return _c('div', {
      key: dataIdx,
      staticClass: "m-2 p-1",
      staticStyle: {
        "background": "#eee",
        "width": "480px"
      }
    }, [_c('table', {
      staticClass: "w-100 emailTable"
    }, [_c('tr', [_c('td', [_vm._v("案場名稱")]), _c('td', [_vm._v(_vm._s(data.reports.factoryTwName))])]), _c('tr', [_c('td', [_vm._v("案場設置容量")]), _c('td', [_vm._v(_vm._s(data.reports.capacity) + " kWp")])]), _vm._l(_vm.editableItems, function (item, itemIdx) {
      return _c('tr', {
        key: itemIdx
      }, [_c('td', [_vm._v(_vm._s(item.title))]), _c('td', [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [_vm.isEditable ? _c('b-form-input', {
        staticStyle: {
          "width": "200px"
        },
        attrs: {
          "type": "number",
          "size": "sm"
        },
        model: {
          value: data.reports[item.key],
          callback: function callback($$v) {
            _vm.$set(data.reports, item.key, _vm._n($$v));
          },
          expression: "data.reports[item.key]"
        }
      }) : _c('span', [_vm._v(" " + _vm._s(_vm.dbDataHandle(data.reports[item.key])) + " ")]), _vm._v("  " + _vm._s(item.unit) + " ")], 1)])]);
    })], 2)]);
  })], 2), _c('div', {
    staticClass: "d-flex justify-content-center mb-3"
  }, [_c('b-button', {
    staticClass: "confirmButton mx-2",
    on: {
      "click": function click($event) {
        _vm.isEditable = !_vm.isEditable;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditable ? "確認" : "修改") + " ")]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "confirmButton mx-2",
    style: {
      cursor: _vm.isEditable ? 'not-allowed' : 'pointer'
    },
    attrs: {
      "title": _vm.isEditable ? '請確認修改資訊內容' : '',
      "disabled": _vm.isEditable
    },
    on: {
      "click": _vm.sendmail
    }
  }, [_vm._v(" 信件寄送 ")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };