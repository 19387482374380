// import cookies from 'vue-cookies'
// import axios from 'axios'
export default {
  name: 'DeviceInfo',
  components: {},
  data: function data() {
    return {
      tableShow: false,
      fakeArr: [{
        show: false
      }, {
        show: false
      }, {
        show: false
      }, {
        show: false
      }, {
        show: false
      }, {
        show: false
      }, {
        show: false
      }, {
        show: false
      }]
    };
  },
  computed: {},
  methods: {
    change: function change(index) {
      this.fakeArr[index].show = !this.fakeArr[index].show;
    }
  },
  mounted: function mounted() {}
};