var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p-5",
    staticStyle: {
      "background-color": "#f2f2f2"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };