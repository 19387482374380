var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "borderColor mb-3"
  }, [_c('b-alert', {
    staticClass: "text-center font-weight-bold m-5px",
    attrs: {
      "show": !_vm.otherInfoData,
      "variant": "secondary"
    }
  }, [_vm._v(" 目前暫無資料 ")]), _c('div', {
    staticClass: "px-2 px-sm-3 row row-cols-1 row-cols-lg-2 row-cols-xl-3"
  }, _vm._l(_vm.otherInfoData, function (info, infoKey, index) {
    return _c('div', {
      key: index,
      staticClass: "py-3 px-2 py-sm-4 px-sm-4"
    }, [_c('div', {
      staticClass: "title h4 mb-0 py-3 px-4"
    }, [_vm._v(_vm._s(infoKey))]), _c('div', {
      staticClass: "content py-3 px-4"
    }, [_c('div', {
      staticClass: "content_table"
    }, _vm._l(info, function (dataValue, dataKey, idx) {
      return _c('div', {
        key: idx,
        staticClass: "content_row"
      }, [_c('div', {
        staticClass: "content_title px-4 py-2"
      }, [_vm._v(_vm._s(dataKey))]), _c('div', {
        staticClass: "content_num py-2"
      }, [_vm._v(_vm._s(dataValue))])]);
    }), 0)])]);
  }), 0)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };