export default {
  name: 'PvStringFailureAnalysis',
  data: function data() {
    return {
      activeOrder: 0,
      routeQuery: ''
    };
  },
  methods: {
    selectAnalysis: function selectAnalysis(idx) {
      this.activeOrder = idx;
    }
  },
  computed: {
    lang: function lang() {
      return this.$route.params.lang;
    }
  },
  created: function created() {
    this.routeQuery = this.$route.query;
  }
};