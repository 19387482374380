import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "borderColor borderColorBackground mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap text-center mb-3"
  }, [_c('div', {
    staticClass: "font-weight-bold pageButton datepickerButton",
    class: {
      active: _vm.order === 1
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePage(1);
      }
    }
  }, [_vm._v(" 串接資料 ")]), _c('div', {
    staticClass: "font-weight-bold pageButton datepickerButton",
    class: {
      active: _vm.order === 2
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePage(2);
      }
    }
  }, [_vm._v(" PVsyst ")]), _c('div', {
    staticClass: "font-weight-bold pageButton datepickerButton",
    class: {
      active: _vm.order === 3
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePage(3);
      }
    }
  }, [_vm._v(" 檔案資料 ")]), _c('div', {
    staticClass: "font-weight-bold pageButton datepickerButton",
    class: {
      active: _vm.order === 4
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePage(4);
      }
    }
  }, [_vm._v(" KPI設定 ")]), _vm.order !== 3 && !_vm.resError ? _c('div', {
    staticClass: "d-flex align-items-center ml-auto"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.order === 4 ? [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "m-1 d-flex align-items-center"
  }, [_vm._v(" 基礎PR衰退率: "), _c('b-form-input', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "type": "number",
      "size": "sm",
      "min": 0
    },
    model: {
      value: _vm.basicDeclineRate,
      callback: function callback($$v) {
        _vm.basicDeclineRate = _vm._n($$v);
      },
      expression: "basicDeclineRate"
    }
  })], 1), _c('label', {
    staticClass: "m-1 d-flex align-items-center"
  }, [_vm._v(" 逐年PR衰退率: "), _c('b-form-input', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "type": "number",
      "size": "sm",
      "min": 0
    },
    model: {
      value: _vm.declineRate,
      callback: function callback($$v) {
        _vm.declineRate = _vm._n($$v);
      },
      expression: "declineRate"
    }
  })], 1), _c('label', {
    staticClass: "m-1 d-flex align-items-center"
  }, [_vm._v(" 新增幾年: "), _c('b-form-input', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "number",
      "size": "sm",
      "min": 1
    },
    model: {
      value: _vm.newDataLength,
      callback: function callback($$v) {
        _vm.newDataLength = _vm._n($$v);
      },
      expression: "newDataLength"
    }
  })], 1), _c('button', {
    staticClass: "confirmButton mx-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.kpiSettingNewData
    }
  }, [_vm._v(" 新增 ")])])] : _vm._e(), _c('button', {
    staticClass: "confirmButton mx-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.isEditing = !_vm.isEditing;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditing ? "確認" : "修改") + " ")]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "confirmButton mx-1",
    style: {
      cursor: _vm.isEditing ? 'not-allowed' : 'pointer'
    },
    attrs: {
      "type": "button",
      "title": _vm.isEditing ? '請確認修改資料' : '',
      "disabled": _vm.isEditing
    },
    on: {
      "click": function click($event) {
        return _vm.handleUpdateData(_vm.order);
      }
    }
  }, [_vm._v(" 上傳 ")])], 2)]) : _vm._e()]), _vm.order === 4 ? _c('div', {
    staticClass: "text-center font-weight-bold"
  }, [_vm._v(" PR衰退公式 => 起始年PR - (((基礎PR衰退率 + ((第幾年 - 1) * 逐年PR衰退率))) * 起始年PR) ")]) : _vm._e(), _c('div', {
    staticClass: "overflow-auto bg-white"
  }, [_vm.isLoading ? _c('b-spinner', {
    staticClass: "mx-3 my-4",
    attrs: {
      "variant": "secondary"
    }
  }) : _vm._e(), _c('b-alert', {
    staticClass: "text-center my-2 mx-1 font-weight-bold",
    attrs: {
      "show": _vm.resError,
      "variant": "secondary"
    }
  }, [_vm._v(" 目前暫無資料，待資料上傳 ")]), _vm.order === 1 || _vm.order === 2 ? _c('table', {
    staticClass: "w-100 text-center"
  }, [_c('tr', _vm._l(_vm.tableHeader, function (i, idx) {
    return _c('th', {
      key: idx
    }, [_vm.isEditing ? _c('b-form-input', {
      staticClass: "mx-auto text-center",
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "value": i.indexOf('__EMPTY') === -1 ? i : '',
        "size": "sm"
      },
      on: {
        "input": function input($event) {
          return _vm.changeTableTitle($event.target.value, idx, _vm.tableHeader[idx]);
        }
      }
    }) : _c('span', [_vm._v(_vm._s(i.indexOf("__EMPTY") === -1 ? i : ""))])], 1);
  }), 0), _vm._l(_vm.tableData, function (i, idx) {
    return _c('tr', {
      key: idx
    }, _vm._l(_vm.tableHeader, function (j, idx) {
      return _c('td', {
        key: idx
      }, [_vm.isEditing ? _c('b-form-input', {
        staticClass: "mx-auto text-center",
        staticStyle: {
          "width": "120px"
        },
        attrs: {
          "value": i[j],
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i[j] = $event.target.value;
          }
        }
      }) : _c('span', [_vm._v(_vm._s(i[j]))])], 1);
    }), 0);
  })], 2) : _vm._e(), _vm.order === 3 && !!_vm.pdfArray.length ? _c('table', {
    staticClass: "w-100 text-center"
  }, [_c('tr', [_c('th', [_vm._v("檔名")]), _vm.dataAdmin.includes(_vm.userDetail.role) ? _c('th', [_vm._v("刪除")]) : _vm._e()]), _vm._l(_vm.pdfArray, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_c('a', {
      attrs: {
        "href": i.value,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(i.name))])]), _vm.dataAdmin.includes(_vm.userDetail.role) ? _c('td', [_c('button', {
      staticClass: "deleteButton m-5px",
      staticStyle: {
        "width": "70px"
      },
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.checkDeletePdf(i.name);
        }
      }
    }, [_vm._v(" X ")])]) : _vm._e()]);
  })], 2) : _vm._e(), _vm.order === 4 ? [_c('div', {
    staticClass: "d-flex align-items-center mt-2",
    staticStyle: {
      "margin-bottom": "-25px"
    }
  }, [_c('label', {
    staticClass: "btn btn-sm btn-info m-0 mr-2"
  }, [_c('i', {
    staticClass: "fas fa-file-upload"
  }), _vm._v(" KPI 資料輸入 "), _c('input', {
    staticClass: "border border-black rounded d-none",
    attrs: {
      "type": "file",
      "accept": ".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    on: {
      "change": _vm.handleUploadInputKpi
    }
  })]), _vm.uploadKpiFileName ? _c('div', {
    staticClass: "text-secondary",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(" " + _vm._s(_vm.uploadKpiFileName) + " ")]) : _vm._e()]), _vm._l(_vm.kpiSetting, function (i, idx) {
    var _i$availabilityManual, _i$availabilityManual2, _i$availabilityManual3;
    return _c('div', {
      key: idx,
      staticClass: "kpiSettingTable mt-10"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('b-form-checkbox', {
      staticClass: "ml-1",
      attrs: {
        "id": String(idx)
      },
      model: {
        value: _vm.kpiSettingManualList[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.kpiSettingManualList, idx, $$v);
        },
        expression: "kpiSettingManualList[idx]"
      }
    }, [_vm._v(" 啟用手動設定 ")]), _vm.kpiSetting.length > 1 ? _c('button', {
      staticClass: "deleteButton mb-1",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.kpiSettingSplice(idx);
        }
      }
    }, [_vm._v(" 移除 ")]) : _vm._e()], 1), _c('div', {
      staticClass: "overflow-auto"
    }, [_c('table', {
      staticClass: "w-100 text-center"
    }, [_c('tr', [_c('th', [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(i.year))]) : _c('b-form-input', {
      staticClass: "mx-auto w-75",
      attrs: {
        "value": i.year,
        "size": "sm"
      },
      on: {
        "input": function input($event) {
          i.year = $event.target.value;
        }
      }
    })], 1), _vm._l(_vm.monthArray, function (month) {
      return _c('th', {
        key: month,
        staticClass: "text-black-50"
      }, [_vm._v(" " + _vm._s(month) + " ")]);
    })], 2), _c('tr', [_c('td', [_vm._v("目標 PR")]), _vm._l(i.PR, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.PR[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', [_vm._v("妥善率")]), _vm._l(i.properRate, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.properRate[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', [_vm._v("峰瓩值")]), _vm._l(i.peakWatt, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.peakWatt[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', [_vm._v("保證 PR")]), _vm._l(i.guaranteePr, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.guaranteePr[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("保證發電量")]), _vm._l(i.guaranteePower, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.guaranteePower[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("目標發電量")]), _vm._l(i.acpGoalContract, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.acpGoalContract[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _vm.kpiSettingManualList[idx] ? [_c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ 日照量 ]")]), _vm._l(i.irrActualManual, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.irrActualManual[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ 發電量 ]")]), _vm._l(i.acpActualManual, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.acpActualManual[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ PR ]")]), _vm._l(i.PRActualManual, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.PRActualManual[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ Data_down ]")]), _vm._l((_i$availabilityManual = i.availabilityManual) === null || _i$availabilityManual === void 0 ? void 0 : _i$availabilityManual.data_down, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.availabilityManual.data_down[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ T_useful ]")]), _vm._l((_i$availabilityManual2 = i.availabilityManual) === null || _i$availabilityManual2 === void 0 ? void 0 : _i$availabilityManual2.t_useful, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.availabilityManual.t_useful[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2), _c('tr', [_c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v("設定 [ T_down ]")]), _vm._l((_i$availabilityManual3 = i.availabilityManual) === null || _i$availabilityManual3 === void 0 ? void 0 : _i$availabilityManual3.t_down, function (n, idx) {
      return _c('td', {
        key: idx
      }, [!_vm.isEditing ? _c('span', [_vm._v(_vm._s(n))]) : _c('b-form-input', {
        staticClass: "mx-auto",
        attrs: {
          "value": n,
          "size": "sm"
        },
        on: {
          "input": function input($event) {
            i.availabilityManual.t_down[idx] = Number($event.target.value);
          }
        }
      })], 1);
    })], 2)] : _vm._e()], 2)])]);
  })] : _vm._e()], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };