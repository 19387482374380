import _objectWithoutProperties from "/home/vsts/work/1/s/client/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/home/vsts/work/1/s/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _excluded = ["_cellVariants"];
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { BFormSelect, BTable, BPagination, BSpinner, BAlert } from 'bootstrap-vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import downloadCsv from 'download-csv';
import i18n from '../lang/lang';
import { disabledDateAfterToday } from '@/utils/datetime';
export default {
  name: 'DeviceHistory',
  components: {
    BFormSelect: BFormSelect,
    BTable: BTable,
    BPagination: BPagination,
    BSpinner: BSpinner,
    BAlert: BAlert
  },
  data: function data() {
    return {
      sortBy: '時間',
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      tableQueryParams: {
        tableQueryDate: new Date(),
        tableQuerySelectNum: 1
      },
      tableLoading: false,
      sortDesc: true,
      tableTitle: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('deviceHistory', ['tableField', 'selectOptions', 'tableData'])), mapGetters('deviceHistory', ['hasTableData'])), mapState(['dark', 'tableThColor'])), {}, {
    rows: function rows() {
      return this.tableData.length;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions('deviceHistory', ['handleTableField', 'setInvSelectOptions', 'fetchTableData'])), {}, {
    disabledDateAfterToday: disabledDateAfterToday,
    confirmSearch: function confirmSearch() {
      var _this = this;
      if (!this.tableQueryParams.tableQueryDate) {
        return this.$swal('請選擇日期');
      }
      this.tableLoading = true;
      this.tableTitle = 'INV' + ('0' + this.tableQueryParams.tableQuerySelectNum).slice(-2);
      this.fetchTableData(this.tableQueryParams).then(function () {
        _this.tableLoading = false;
      });
    },
    downloadAnalysis: function downloadAnalysis() {
      // remove _cellVariants
      var downloadData = this.tableData.map(function (obj) {
        var _cellVariants = obj._cellVariants,
          newObject = _objectWithoutProperties(obj, _excluded);
        return newObject;
      });
      downloadCsv(downloadData, {}, '裝置資訊表.csv');
    },
    tableLang: function tableLang() {
      var deviceHistoryTableTitle = ['Time', 'frequency', 'abVoltage', 'bcVoltage', 'caVoltage', 'APhaseCurrent', 'BPhaseCurrent', 'CPhaseCurrent'];
      for (var i = 0; i < deviceHistoryTableTitle.length; i++) {
        if (this.tableField[i]) {
          this.tableField[i].label = i18n.t("deviceHistory.tableTitle.".concat(deviceHistoryTableTitle[i]));
        }
      }
      for (var _i = deviceHistoryTableTitle.length; _i < this.tableField.length; _i++) {
        if (this.tableField[_i]) {
          var str = this.tableField[_i].key.replace('MTTP', 'PV');
          var changelangstr = str.replace('電壓', ' ').replace('電流', ' ');
          this.tableField[_i].label = i18n.locale === 'en' ? changelangstr : str;
        }
      }
    }
  }),
  created: function created() {
    this.confirmSearch();
    this.handleTableField();
    this.setInvSelectOptions();
    this.tableLang();
  }
};