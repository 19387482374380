import _objectSpread from "/home/vsts/work/1/s/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState, mapMutations } from 'vuex';
import downloadBtn from '../../components/DownloadButton';
export default {
  name: 'PowerFee',
  components: {
    downloadBtn: downloadBtn
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState('pvStringFailure', ['tableGroup'])),
  methods: _objectSpread({}, mapMutations('pvStringFailure', ['SET_GROUP_TABLE'])),
  mounted: function mounted() {
    this.SET_GROUP_TABLE();
  }
};