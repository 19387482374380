import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": "lg"
    }
  }, [_c('div', {
    staticClass: "pageTitle mx-1"
  }, [_vm._v("RA 資料分析")]), _c('div', {
    staticClass: "borderColor mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2"
  }, [_c('div', {
    staticClass: "mx-2 mr-4 my-2"
  }, [_vm._v(" 案場: "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInput,
      expression: "searchInput"
    }],
    staticClass: "filterInput ml-2",
    attrs: {
      "type": "text",
      "name": "factoryNameInput",
      "list": "factoryList",
      "placeholder": "案場名稱"
    },
    domProps: {
      "value": _vm.searchInput
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.searchInput = $event.target.value;
      }
    }
  }), _c('datalist', {
    attrs: {
      "id": "factoryList"
    }
  }, _vm._l(_vm.filteredFactoryInfo, function (i, idx) {
    return _c('option', {
      key: idx,
      domProps: {
        "value": i.factory + '_' + i.factoryId
      }
    });
  }), 0)]), _c('div', {
    staticClass: "ml-2 mr-4 my-2"
  }, [_vm._v(" 日期: "), _c('date-picker', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "type": "date",
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "placeholder": "請選擇日期",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('div', {
    staticClass: "ml-2 mr-4 my-2"
  }, [_vm._v(" 時間: "), _c('date-picker', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "180px"
    },
    attrs: {
      "type": "time",
      "value-type": "HH:mm:ss",
      "format": "HH:mm",
      "range": "",
      "placeholder": "請選擇時間",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.selectedTimeRange,
      callback: function callback($$v) {
        _vm.selectedTimeRange = $$v;
      },
      expression: "selectedTimeRange"
    }
  })], 1), _c('div', {
    staticClass: "mx-2 my-2 btnWrap"
  }, [_c('button', {
    staticClass: "confirmButton p-1",
    class: {
      disableButton: _vm.loadingData
    },
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.search
    }
  }, [_vm._v(" 查詢 ")])]), Object.keys(_vm.raData).length ? _c('div', {
    staticClass: "mr-2 my-2 btnWrap"
  }, [_c('button', {
    staticClass: "btn btn-sm text-nowrap toggleExpandBtn",
    class: [_vm.isAllExpanded ? "btn-expand" : "btn-collapse"],
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.isAllExpanded = !_vm.isAllExpanded;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isAllExpanded ? "收合" : "展開") + " ")])]) : _vm._e()]), Object.keys(_vm.raData).length ? _c('span', [_c('div', {
    staticClass: "m-2"
  }, [_c('hr', {
    staticClass: "mx-2 mt-4"
  })]), _c('div', {
    staticClass: "m-2"
  }, [_c('span', {
    staticClass: "mx-2 mb-2 text-secondary"
  }, [_vm._v("RA 資料下載")])])]) : _vm._e(), Object.keys(_vm.raData).length ? _c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2"
  }, [_c('div', {
    staticClass: "mx-2 mr-4 my-2"
  }, [_vm._v(" 日期: "), _c('span', {
    staticClass: "ml-2 text-secondary"
  }, [_vm._v(_vm._s(_vm.downloadInfo[1]))])]), _c('div', {
    staticClass: "mx-2 mr-4 my-2 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "btn btn-sm btn-info m-0 text-nowrap"
  }, [_c('i', {
    staticClass: "fas fa-file-upload"
  }), _vm._v(" 檔案上傳 "), _c('input', {
    ref: "inputFile",
    staticClass: "ml-2 border border-black rounded d-none",
    attrs: {
      "type": "file",
      "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    on: {
      "change": _vm.handleChangeFiles
    }
  })]), _c('div', {
    staticClass: "ml-2 text-secondary"
  }, [_vm._v(_vm._s(_vm.uploadFileName))])]), _c('div', {
    staticClass: "ml-2 mr-4 my-2"
  }, [_vm._v(" 時間: "), _c('date-picker', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "type": "time",
      "value-type": "HH:mm",
      "format": "HH:mm",
      "placeholder": "請選擇時間",
      "time-picker-options": _vm.downloadTimeRange
    },
    model: {
      value: _vm.selectedDownloadTime,
      callback: function callback($$v) {
        _vm.selectedDownloadTime = $$v;
      },
      expression: "selectedDownloadTime"
    }
  })], 1), _c('div', {
    staticClass: "mx-2 my-2 btnWrap"
  }, [_c('button', {
    staticClass: "confirmButton p-1",
    class: {
      disableButton: _vm.loadingData
    },
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(" 下載 ")])])]) : _vm._e()]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "tableContent"
  }, [_c('div', {
    staticClass: "d-flex text-center border tableTitle"
  }, [_c('div', {
    staticStyle: {
      "width": "30%"
    }
  }, [_vm._v("時間")]), _c('div', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("INV")]), _c('div', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("PV")]), _c('div', {
    staticStyle: {
      "width": "20%"
    }
  }, [_vm._v("RA")]), _c('div', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("IRR")])]), _vm._l(_vm.raData, function (dataItem, dataKey, idx) {
    return _c('div', {
      key: dataKey,
      staticClass: "d-flex justify-content-around align-items-center text-center border-bottom"
    }, [_c('div', {
      staticClass: "position-relative timeText",
      staticStyle: {
        "width": "30%"
      },
      attrs: {
        "aria-expanded": dataItem.isTimeExpanded,
        "aria-controls": 'collapse-time-' + idx
      },
      on: {
        "click": function click($event) {
          dataItem.isTimeExpanded = !dataItem.isTimeExpanded;
        }
      }
    }, [_vm._v(" " + _vm._s(dataKey) + " "), _c('span', {
      staticClass: "expandTableBtn"
    }, [_c('i', {
      staticClass: "fas",
      class: [dataItem.isTimeExpanded ? 'fa-chevron-right' : 'fa-chevron-down']
    })])]), _c('div', {
      staticStyle: {
        "width": "70%"
      }
    }, [_c('b-collapse', {
      attrs: {
        "id": 'collapse-time-' + idx
      },
      model: {
        value: dataItem.isTimeExpanded,
        callback: function callback($$v) {
          _vm.$set(dataItem, "isTimeExpanded", $$v);
        },
        expression: "dataItem.isTimeExpanded"
      }
    }, _vm._l(dataItem.invObj, function (invObjItem, invKey) {
      return _c('div', {
        key: idx + invKey,
        staticClass: "d-flex align-items-center invWrap"
      }, [_c('div', {
        staticClass: "position-relative invToggle",
        staticStyle: {
          "width": "28.6%",
          "height": "50px",
          "line-height": "50px"
        },
        attrs: {
          "aria-expanded": invObjItem.isInvExpanded,
          "aria-controls": 'collapse-' + idx + invKey
        },
        on: {
          "click": function click($event) {
            invObjItem.isInvExpanded = !invObjItem.isInvExpanded;
          }
        }
      }, [_vm._v(" INV " + _vm._s(Number(invKey) + 1) + " "), _c('span', {
        staticClass: "expandTableBtn"
      }, [_c('i', {
        staticClass: "fas",
        class: [invObjItem.isInvExpanded ? 'fa-chevron-right' : 'fa-chevron-down']
      })])]), _c('b-collapse', {
        staticStyle: {
          "width": "71.4%"
        },
        attrs: {
          "id": 'collapse-' + idx + invKey
        },
        model: {
          value: invObjItem.isInvExpanded,
          callback: function callback($$v) {
            _vm.$set(invObjItem, "isInvExpanded", $$v);
          },
          expression: "invObjItem.isInvExpanded"
        }
      }, _vm._l(invObjItem.raArray, function (pvItem, idx) {
        var _invObjItem$raArray, _invObjItem$raArray2, _invObjItem$raArray3;
        return _c('div', {
          key: idx,
          staticClass: "d-flex pvWrap"
        }, [_c('div', {
          staticStyle: {
            "width": "30%"
          },
          style: [((_invObjItem$raArray = invObjItem.raArray) === null || _invObjItem$raArray === void 0 ? void 0 : _invObjItem$raArray.length) === 1 ? {
            height: '50px',
            lineHeight: '50px'
          } : {
            height: '28px',
            lineHeight: '28px'
          }]
        }, [_vm._v(" " + _vm._s(pvItem.pv + 1) + " ")]), _c('div', {
          class: [pvItem.ra > 100 || pvItem.ra < 80 ? 'raWarning' : ''],
          staticStyle: {
            "width": "40%"
          },
          style: [((_invObjItem$raArray2 = invObjItem.raArray) === null || _invObjItem$raArray2 === void 0 ? void 0 : _invObjItem$raArray2.length) === 1 ? {
            height: '50px',
            lineHeight: '50px'
          } : {
            height: '28px',
            lineHeight: '28px'
          }]
        }, [_vm._v(" " + _vm._s(pvItem.ra) + " ")]), _c('div', {
          staticStyle: {
            "width": "30%"
          },
          style: [((_invObjItem$raArray3 = invObjItem.raArray) === null || _invObjItem$raArray3 === void 0 ? void 0 : _invObjItem$raArray3.length) === 1 ? {
            height: '50px',
            lineHeight: '50px'
          } : {
            height: '28px',
            lineHeight: '28px'
          }]
        }, [_vm._v(" " + _vm._s(pvItem.irr) + " ")])]);
      }), 0)], 1);
    }), 0)], 1)]);
  })], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };