import _objectSpread from "/home/vsts/work/1/s/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Datepicker from 'vuejs-datepicker';
import { zh } from 'vuejs-datepicker/dist/locale';
import { mapState } from 'vuex';
import downloadCsv from 'download-csv';
export default {
  name: 'ChartsAndBarItem',
  components: {
    Datepicker: Datepicker
  },
  props: {
    format: {
      type: String,
      default: 'yyyy-MM'
    },
    dateTypeClickedTitle: {
      type: String,
      default: '最近30天'
    }
  },
  data: function data() {
    return {
      zh: zh,
      disabledDates: {
        from: new Date()
      },
      barItems: ['All', 'kWh', 'IRR', 'PR', '°C', 'NTD', 'Num'].map(function (item) {
        return {
          title: item,
          isShow: true
        };
      }),
      selectedDate: new Date(),
      selectedInvNo: 1
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('ChartsAndBarItem', ['invSelectOptions'])), mapState('siteInfoDetail', ['siteInfoDetailDownloadField', 'siteInfoDetailDownloadData', 'showPR', 'showRevenue'])), {}, {
    filterBarItems: function filterBarItems() {
      if (!this.showPR && !this.showRevenue) {
        return this.barItems.filter(function (x) {
          return x.title !== 'PR' && x.title !== 'NTD';
        });
      }
      if (!this.showPR) {
        return this.barItems.filter(function (x) {
          return x.title !== 'PR';
        });
      }
      if (!this.showRevenue) {
        return this.barItems.filter(function (x) {
          return x.title !== 'NTD';
        });
      }
      return this.barItems;
    }
  }),
  methods: {
    clickDownloadCsv: function clickDownloadCsv() {
      var downloadData = this.siteInfoDetailDownloadData;
      if (!this.showPR && !this.showRevenue) {
        downloadData = downloadData.filter(function (obj) {
          return obj.name !== '發電效率' && obj.name !== '發電收入';
        });
      } else if (!this.showPR) {
        downloadData = downloadData.filter(function (obj) {
          return obj.name !== '發電效率';
        });
      } else if (!this.showRevenue) {
        downloadData = downloadData.filter(function (obj) {
          return obj.name !== '發電收入';
        });
      }
      downloadCsv(downloadData, this.siteInfoDetailDownloadField, "\u6848\u5834\u8CC7\u8A0A_".concat(this.dateTypeClickedTitle, ".csv"));
    },
    setToday: function setToday() {
      this.selectedDate = new Date();
    },
    emitDateSelect: function emitDateSelect() {
      this.$emit('date-select', this.selectedDate);
    },
    emitInvSelect: function emitInvSelect() {
      this.$emit('inv-select', this.selectedInvNo, this.selectedDate);
    },
    emitBarItemClick: function emitBarItemClick(clickedBarItem) {
      var _this = this;
      if (clickedBarItem === 'All' && this.barItems[0].isShow) {
        this.barItems.forEach(function (item) {
          item.isShow = false;
        });
        this.$emit('bar-item-click', this.barItems[0]);
      } else if (clickedBarItem === 'All' && !this.barItems[0].isShow) {
        this.barItems.forEach(function (item) {
          item.isShow = true;
        });
        this.$emit('bar-item-click', this.barItems[0]);
      } else {
        this.barItems.forEach(function (item, idx) {
          if (item.title === clickedBarItem) {
            _this.barItems[idx].isShow = !_this.barItems[idx].isShow;
            // 接下來用 highcharts event https://codepen.io/weizhenye/pen/rrKgbP?editors=1010
            _this.$emit('bar-item-click', item);
          }
        });
      }
    }
  }
};