import Vue from 'vue'
import { DateTime } from 'luxon'
import store from '@/store'
import { logoutApi } from '@/api'
import {
  projectSolarRoutes,
  projectEnergyRoutes,
  projectBatteryRoutes
} from '../routes'

export function createPermissionGuard (router) {
  router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g
    const isChunkLoadFailed = error.message.match(pattern)
    if (isChunkLoadFailed) {
      window.location.reload()
    }
  })

  router.beforeEach(async (to, from, next) => {
    const userDetail = JSON.parse(localStorage.getItem('userDetail'))

    // ---------- localStorage 沒有 role 則回到 login page ----------
    // -------------------------------------------------------------
    if (to.name === 'login' && userDetail) {
      await logoutApi()
        .then(() => {
          localStorage.removeItem('userDetail')
        })
        .catch((err) => console.log('router.beforeEach logoutApi error', err))
    }

    if (to.name !== 'login') {
      if (userDetail) {
        store.commit('ADD_USER_DETAIL', userDetail)
      } else {
        return next({ name: 'login' })
      }

      if (!store.state.roleArray.length) {
        await store.dispatch('getAllGroups')
      }
    }

    // 展示案場電視牆 / 電視牆輪播 權限 (上方 getAllGroups -> 取得 rolePermissionArray)
    if (to.name === 'tvscreen' || to.name === 'tvwalllooping') {
      if (!store.state.rolePermissionArray.includes(to.name)) {
        Vue.swal.fire('無此權限')
        return next({ name: 'realtime' })
      } else {
        store.state.currentRouteName = to.name
        return next()
      }
    }

    if (to.name === 'realtime') {
      store.state.currentRouteName = to.name
      if (!store.state.realTimeRePostBeginTime) {
        store.state.realTimeRePostBeginTime = DateTime.local()
      } else {
        const now = DateTime.local()
        const dataTime = DateTime.fromISO(store.state.realTimeRePostBeginTime, {
          zone: 'Asia/Taipei'
        })
        const diff = now.diff(dataTime, 'minutes').toObject()
        if (diff.minutes >= 5) {
          store.state.realTimeRePostBeginTime = null
          store.state.realTimeDataReload = true
        }
      }
      return next()
    } else {
      // 進入非首頁
      // ------ 檢查當前路由的權限 ------
      // 若 to.name 在 menuNameList 中但沒有權限
      const menuNameList = [
        ...projectSolarRoutes,
        ...projectEnergyRoutes,
        ...projectBatteryRoutes
      ].map((item) => item.name)

      const permissions = [
        ...store.state.rolePermissionArray,
        ...store.state.energyPermissionArray,
        ...store.state.batteryPermissionArray
      ]
      if (menuNameList.includes(to.name) && !permissions.includes(to.name)) {
        return next({ name: 'Not Found' })
      }

      // 檢查是否有權限
      if (to.query.siteName && to.name !== 'tvscreen') {
        if (!store.state.factoryInfo.length) {
          await store.dispatch('asyncInfo')
        }
      }

      store.state.currentRouteName = to.name
      return next()
    }
  })
}
