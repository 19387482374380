import _objectSpread from "/home/vsts/work/1/s/client/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import DualListBox from 'dual-listbox-vue';
import { mapState, mapMutations } from 'vuex';
import 'dual-listbox-vue/dist/dual-listbox.css';
export default {
  name: 'GroupConfig',
  components: {
    DualListBox: DualListBox
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapState('pvStringFailure', ['dualSource', 'dualDestination', 'groups'])),
  methods: _objectSpread(_objectSpread({}, mapMutations('pvStringFailure', ['HANDLE_DUAL_LISTS', 'ADD_GROUP', 'CANCEL_GROUP'])), {}, {
    onChangeList: function onChangeList(_ref) {
      var source = _ref.source,
        destination = _ref.destination;
      console.log('destination', destination);
      this.HANDLE_DUAL_LISTS({
        source: source,
        destination: destination
      });
    },
    addGroup: function addGroup() {
      this.ADD_GROUP();
    },
    cancelGroup: function cancelGroup(item, idx) {
      console.log(idx);
      this.CANCEL_GROUP({
        item: item,
        idx: idx
      });
    }
  })
};