function darkMode () {
  const set = document.documentElement.style
  set.setProperty('--acmeNavbar', '#161B22', 'important')
  set.setProperty('--sidebar', '#161B22', 'important')
  set.setProperty('--appBackground', '#0D1117', 'important')
  set.setProperty('--normaltextcolor', '#C9D1D9', 'important')
  set.setProperty('--icon', 'white', 'important')
  set.setProperty('--acmecelltitle', 'linear-gradient(167deg, rgba(3,3,3,1) 15%, rgba(43,45,46,1) 84%)', 'important')
  set.setProperty('--acmeTableTitle', 'linear-gradient(167deg, rgba(22,4,12,1) 14%, rgba(45,47,59,1) 100%)', 'important')
  set.setProperty('--acmeTabledata', 'linear-gradient(167deg, rgba(3,3,3,1) 15%, rgba(43,45,46,1) 84%)', 'important')
  set.setProperty('--acmePageTitle', 'white', 'important')
  set.setProperty('--acmeLogo', 'white', 'important')
  set.setProperty('--styleButton', '#e0102b', 'important')
  set.setProperty('--linearButtonAndTitle', '#161B22', 'important')
  set.setProperty('--borderColor', '#0D1117', 'important')
  set.setProperty('--siteinfoBackground', '#0D1117', 'important')
  set.setProperty('--barItemBackground', '#0D1117', 'important')
  set.setProperty('--siteInfoDetailBorder', '#181a1a', 'important')
  set.setProperty('--infoCategoryBackground', '#21262D', 'important')
  set.setProperty('--infoCategoryBefore', '#21262D', 'important')
  set.setProperty('--siteinfoKeys', '#21262D', 'important')
  set.setProperty('--barItem', 'rgba(3,3,3,1)', 'important')
  set.setProperty('--barItemClicked', '#21262D', 'important')
  set.setProperty('--chooseButton', '#21262D', 'important')
  set.setProperty('--titleLinear', '#161B22', 'important')
  set.setProperty('--borderBoxShow', '#271919', 'important')
}

export {
  darkMode
}
