import "core-js/modules/es.number.to-fixed.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "searchBar d-flex flex-column p-3 position-relative",
    class: {
      searchBar__expanded: _vm.isSearchBarExpanded
    }
  }, [_c('div', {
    staticClass: "btn_expand p-1 py-2 cursor-pointer position-absolute",
    on: {
      "click": _vm.handleClickExpandBtn
    }
  }, [_c('i', {
    staticClass: "fas",
    class: _vm.isSearchBarExpanded ? 'fa-chevron-left' : 'fa-chevron-right'
  })]), _c('b-form-input', {
    staticClass: "searchInput",
    attrs: {
      "size": "sm",
      "placeholder": "搜尋案場"
    }
  }), _vm._m(0)], 1), _c('div', {
    staticClass: "card_view w-100",
    class: {
      card_view__expanded: !_vm.isSearchBarExpanded
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": "",
      "active-nav-item-class": "tab__active"
    }
  }, [_c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "用電",
      "active": ""
    }
  }, [_c('div', {
    staticClass: "text-right mx-3 renew_time"
  }, [_vm._v(" 最後更新: 2024-12-25 15:33:26 ")]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.data, function (d) {
    return _c('div', {
      key: d.title,
      staticClass: "demandInfo bg-white p-4 m-3 rounded-lg d-flex align-items-center"
    }, [_c('i', {
      staticClass: "demandInfo_icon",
      class: d.icon
    }), _c('div', {
      staticClass: "ml-3"
    }, [_c('div', {
      staticClass: "demandInfo_title"
    }, [_vm._v(_vm._s(d.title))]), _c('span', {
      staticClass: "demandInfo_value"
    }, [_vm._v(_vm._s(d.value))]), _c('span', {
      staticClass: "demandInfo_unit ml-3"
    }, [_vm._v(_vm._s(d.unit))])])]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.officeData, function (o) {
    return _c('div', {
      key: o.officeNo,
      staticClass: "office bg-white m-3 rounded-lg d-flex flex-column overflow-hidden"
    }, [_c('div', {
      staticClass: "office_title"
    }, [_c('i', {
      staticClass: "far fa-building"
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(o.officeNo))])]), _c('div', {
      staticClass: "d-flex p-3"
    }, [_c('div', {
      staticClass: "office_left"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("即時需量")]), _c('div', {
      staticClass: "office_left_value mt-1"
    }, [_vm._v(_vm._s(o.power))]), _c('span', {
      staticClass: "office_left_unit"
    }, [_vm._v("kW")])]), _c('div', {
      staticClass: "ml-3 pl-4 pr-2 w-100 border-left d-flex flex-column justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("今日用電")]), _c('span', {
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.todayPower) + " ")]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("本月用電")]), _c('span', {
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.monthPower) + " ")]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "office_text"
    }, [_vm._v("上月用電")]), _c('span', {
      staticClass: "office_right_value ml-auto"
    }, [_vm._v(" " + _vm._s(o.lastMonthPower) + " ")]), _c('span', {
      staticClass: "office_right_unit ml-2"
    }, [_vm._v("度")])])])])]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    ref: "refPieChart",
    staticClass: "chart_left m-3 rounded-lg bg-white overflow-hidden"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("本月用電佔比")]), _c('highcharts', {
    attrs: {
      "options": _vm.pieChartOptions
    }
  })], 1), _c('div', {
    staticClass: "chart_right m-3 rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("本月用電需量")]), _c('highcharts', {
    ref: "lineColumnChartRef",
    staticClass: "px-3",
    attrs: {
      "options": _vm.lineColumnChartOptions
    }
  })], 1)])]), _c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "綠電"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.greenData, function (d) {
    return _c('div', {
      key: d.title,
      staticClass: "greenInfo bg-white p-4 m-3 rounded-lg"
    }, [_c('div', {
      staticClass: "ml-2"
    }, [_c('div', {
      staticClass: "greenInfo_title mb-2 d-flex align-items-center"
    }, [_vm._v(" " + _vm._s(d.title) + " "), _c('b-badge', {
      staticClass: "ml-auto",
      style: {
        background: d.badgeColor
      }
    }, [_vm._v(" 已達標 ")])], 1), _c('div', {
      staticClass: "d-flex mt-2"
    }, [_c('i', {
      staticClass: "greenInfo_icon mr-4",
      class: d.icon
    }), _c('div', [_c('div', {
      staticClass: "greenInfo_subtitle"
    }, [_vm._v("佔比")]), _c('span', {
      staticClass: "greenInfo_ratio"
    }, [_vm._v(_vm._s(d.ratio) + " %")])]), _c('div', {
      staticClass: "ml-5"
    }, [_c('div', {
      staticClass: "greenInfo_subtitle"
    }, [_vm._v("電量")]), _c('span', {
      staticClass: "greenInfo_value mr-2"
    }, [_vm._v(_vm._s(d.value))]), _c('span', {
      staticClass: "greenInfo_unit"
    }, [_vm._v("kWh")])])])])]);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-100 m-3 rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("綠電佔比趨勢")]), _c('highcharts', {
    ref: "lineColumnChartRef",
    staticClass: "px-3",
    attrs: {
      "options": _vm.greenChartOptions
    }
  })], 1)])]), _c('b-tab', {
    staticClass: "tab_view",
    attrs: {
      "title": "發電用電"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-1 mx-3 mt-3"
  }, [_c('date-picker', {
    staticClass: "mr-3",
    staticStyle: {
      "width": "160px"
    },
    attrs: {
      "type": "date",
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "placeholder": "請選擇日期",
      "disabled-date": _vm.disabledDateAfterToday,
      "prefix-class": "xmx"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(_ref) {
        var emit = _ref.emit;
        return [_c('button', {
          staticClass: "xmx-btn xmx-btn-text px-2 py-1 border",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              emit(new Date());
            }
          }
        }, [_vm._v(" 今天 ")])];
      }
    }]),
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "acme_blue",
      "size": "sm"
    }
  }, [_vm._v(" 查詢 ")])], 1), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.powerData, function (p) {
    return _c('div', {
      key: p.title,
      staticClass: "powerInfo bg-white p-4 m-3 rounded-lg"
    }, [_c('div', {
      staticClass: "powerInfo_title"
    }, [_vm._v(_vm._s(p.title))]), _c('b-progress', {
      staticClass: "mt-3 mb-2 rounded-lg",
      attrs: {
        "show-progress": "",
        "max": p.greenValue + p.utilityValue,
        "height": "36px",
        "precision": 1
      }
    }, [_c('b-progress-bar', {
      staticClass: "p-3 text-left font-weight-bold",
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        backgroundColor: p.greenColor
      },
      attrs: {
        "value": p.greenValue,
        "label": "".concat((p.greenValue / (p.greenValue + p.utilityValue) * 100).toFixed(1), " %")
      }
    }), _c('b-progress-bar', {
      staticClass: "p-3 text-right font-weight-bold",
      staticStyle: {
        "font-size": "20px"
      },
      style: {
        backgroundColor: p.utilityColor
      },
      attrs: {
        "value": p.utilityValue,
        "label": "".concat((p.utilityValue / (p.greenValue + p.utilityValue) * 100).toFixed(1), " %")
      }
    })], 1), _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('div', [_c('span', {
      staticClass: "powerInfo_text"
    }, [_vm._v(_vm._s(p.greenTitle))]), _c('span', {
      staticClass: "powerInfo_value mx-2",
      style: {
        color: p.greenColor
      }
    }, [_vm._v(_vm._s(p.greenValue))]), _c('span', {
      staticClass: "powerInfo_unit"
    }, [_vm._v("kWh")])]), _c('div', [_c('span', {
      staticClass: "powerInfo_text"
    }, [_vm._v(_vm._s(p.utilityTitle))]), _c('span', {
      staticClass: "powerInfo_value mx-2",
      style: {
        color: p.utilityColor
      }
    }, [_vm._v(_vm._s(p.utilityValue))]), _c('span', {
      staticClass: "powerInfo_unit"
    }, [_vm._v("kWh")])])])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-100 m-3 rounded-lg bg-white"
  }, [_c('div', {
    staticClass: "chart_title border-bottom"
  }, [_vm._v("發電用電趨勢")]), _c('highcharts', {
    ref: "lineColumnChartRef",
    staticClass: "px-3",
    attrs: {
      "options": _vm.powerChartOptions
    }
  })], 1)])])], 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "office_list"
  }, [_c('div', {
    staticClass: "cursor-pointer my-2"
  }, [_c('i', {
    staticClass: "far fa-building mr-2"
  }), _vm._v("南區辦公室")]), _c('div', {
    staticClass: "cursor-pointer my-2"
  }, [_c('i', {
    staticClass: "far fa-building mr-2"
  }), _vm._v("道達爾能源")]), _c('div', {
    staticClass: "cursor-pointer my-2"
  }, [_c('i', {
    staticClass: "far fa-building mr-2"
  }), _vm._v("同欣電子")])]);
}];
export { render, staticRenderFns };