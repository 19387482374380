import "/home/vsts/work/1/s/client/node_modules/core-js/modules/es.array.iterator.js";
import "/home/vsts/work/1/s/client/node_modules/core-js/modules/es.promise.js";
import "/home/vsts/work/1/s/client/node_modules/core-js/modules/es.object.assign.js";
import "/home/vsts/work/1/s/client/node_modules/core-js/modules/es.promise.finally.js";
import vuetify from '@/plugins/vuetify';
import './assets/scss/App.scss'; // 置於 vuetify 後, 讓 bootstrap 覆蓋一些 vuetify 的設定

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueAxios from 'vue-axios';
import i18n from './lang/lang';
import VueCookies from 'vue-cookies';
import interceptors from './http';
import EasySlider from 'vue-easy-slider';
import VueExcelEditor from 'vue-excel-editor';
import { TooltipPlugin, ModalPlugin } from 'bootstrap-vue';
import VueHighcharts from 'vue-highcharts';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/zh-tw';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueDarkMode from '@growthbunker/vuedarkmode';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.use(VueAxios, interceptors);
Vue.use(VueDarkMode);
Vue.use(VueExcelEditor);
Vue.use(EasySlider);
Vue.use(TooltipPlugin).use(ModalPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDpGxb1y_1wDhpa_daBqy1pGWOB8EHDu6k',
    libraries: 'places'
  }
});
Vue.use(VueHighcharts);
Vue.use(DatePicker);
Vue.use(VueCookies);
Vue.config.productionTip = false;
new Vue({
  vuetify: vuetify,
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');