var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("外來監控資訊")]), !_vm.hasGotForeignList && !_vm.foreignFactoryList.length ? _c('b-spinner', {
    staticClass: "mx-3 my-4",
    attrs: {
      "variant": "secondary"
    }
  }) : _vm._e(), _c('b-alert', {
    staticClass: "text-center mx-2 my-4",
    attrs: {
      "show": _vm.hasGotForeignList && !_vm.foreignFactoryList.length,
      "variant": "secondary"
    }
  }, [_vm._v(" 目前暫無資料 ")]), _c('div', {
    staticClass: "mx-2 my-4 listWrap"
  }, _vm._l(_vm.foreignFactoryList, function (_ref) {
    var factoryId = _ref.factoryId,
      factory = _ref.factory;
    return _c('div', {
      key: factoryId,
      staticClass: "border py-2 px-3 mb-2 rounded listItem",
      on: {
        "click": function click($event) {
          return _vm.goToSingleSiteInfo(factoryId, factory);
        }
      }
    }, [_c('span', {
      staticClass: "text-secondary"
    }, [_vm._v(_vm._s(factoryId))]), _vm._v("  " + _vm._s(factory) + " ")]);
  }), 0)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };