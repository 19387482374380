import "core-js/modules/es.number.constructor.js";
export default {
  name: 'TimeSeparator',
  props: {
    dateTypeClickedIdx: {
      type: Number,
      default: 0
    },
    dateType: Array
  },
  methods: {
    emitDateTypeClick: function emitDateTypeClick(idx) {
      this.$emit('date-type-click', idx);
    }
  }
};