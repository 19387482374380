import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("告警事件")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center m-2 mb-3"
  }, [_c('div', {
    staticClass: "position-relative mr-2 d-flex align-items-center"
  }, [_c('i', {
    staticClass: "fas fa-search mr-2 text-secondary"
  }), _c('b-form-input', {
    staticClass: "filterInput textInput rounded-0",
    attrs: {
      "size": "sm",
      "placeholder": '依' + _vm.selectedFilter.text + '搜尋'
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('span', {
    staticClass: "focus-border position-absolute"
  })], 1), _c('b-form-select', {
    staticClass: "w-auto mr-4",
    attrs: {
      "options": _vm.filterOptions,
      "size": "sm"
    },
    model: {
      value: _vm.selectedFilter,
      callback: function callback($$v) {
        _vm.selectedFilter = $$v;
      },
      expression: "selectedFilter"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("依區域篩選: ")]), _c('b-form-select', {
    staticClass: "w-auto mr-4",
    attrs: {
      "options": _vm.filterSiteOptions,
      "size": "sm"
    },
    model: {
      value: _vm.filterSite,
      callback: function callback($$v) {
        _vm.filterSite = $$v;
      },
      expression: "filterSite"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" 資料筆數: " + _vm._s(_vm.historyFilterSearch.length) + " ")]), _c('button', {
    staticClass: "confirmButton p-1 my-1 ml-auto",
    class: {
      disableButton: _vm.loadingData
    },
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.getRecentAlarms
    }
  }, [_vm._v(" 更新 ")])], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingData
    }
  }, [_c('div', {
    staticClass: "otherTableStyle tableWrap"
  }, [_c('table', {
    staticClass: "w-100 text-center text-nowrap"
  }, [_c('tr', {
    staticClass: "sticky-top"
  }, [_vm._l(_vm.thTitle, function (i, index) {
    return _c('th', {
      key: index,
      staticClass: "sort",
      class: {
        down: !i.isSort,
        up: i.isSort
      },
      on: {
        "click": function click($event) {
          return _vm.sortHistoryData(i.value, index);
        }
      }
    }, [i.text === '案場' ? _c('i', {
      staticClass: "fas fa-globe-americas mr-1",
      staticStyle: {
        "color": "#4294cf"
      }
    }) : _vm._e(), _vm._v(_vm._s(i.text) + " ")]);
  }), _c('th', [_vm._v("內容")])], 2), _vm._l(_vm.historyFilterSearch, function (i, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.openMap(i.factoryName);
        }
      }
    }, [_vm._v(" " + _vm._s(i.factoryName) + " ")]), _c('td', [_vm._v(_vm._s(i.county))]), _c('td', [_vm._v(_vm._s(i.maintainer))]), _c('td', [_vm._v(_vm._s(i.which))]), _c('td', [_vm._v(_vm._s(i.lastAlarmType))]), _c('td', [_vm._v(_vm._s(i.time))]), _c('td', {
      style: "".concat(_vm.levelBgColor(i.lastColorLabel), " width: 50px")
    }, [_vm._v(" " + _vm._s(i.allAlarms.length) + " ")]), _c('td', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.getModalData(i.allAlarms, i.factoryName);
        }
      }
    }, [_vm._v(" ... ")])]);
  })], 2)])])], 1)]), _c('b-modal', {
    attrs: {
      "id": "settingModal",
      "size": "xl",
      "title": _vm.modalTitle,
      "ok-only": ""
    }
  }, [_c('div', {
    staticClass: "otherTableStyle"
  }, [_c('table', {
    staticClass: "w-100 text-center"
  }, [_c('tr', {
    staticClass: "text-nowrap"
  }, [_c('th', [_vm._v("設備")]), _c('th', [_vm._v("屬性")]), _c('th', [_vm._v("詳細內容")])]), _vm._l(_vm.modalData, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(_vm.invOrNot(i.inv)))]), _c('td', {
      staticClass: "text-nowrap"
    }, [_vm._v(_vm._s(i.alarmType))]), _c('td', {
      staticClass: "text-left text-break"
    }, [_vm._v(" " + _vm._s(i.desc) + " ")])]);
  })], 2)])]), _c('b-modal', {
    attrs: {
      "id": "mapModal",
      "size": "xl",
      "title": "告警地圖",
      "ok-only": ""
    }
  }, [_c('div', [_c('GmapMap', {
    staticStyle: {
      "height": "600px"
    },
    attrs: {
      "center": _vm.center,
      "zoom": 12,
      "options": {
        scrollwheel: true
      }
    }
  }, _vm._l(_vm.markers, function (x, index) {
    return _c('InfoWindow', {
      key: index,
      attrs: {
        "position": x.position,
        "opened": true,
        "draggable": false,
        "options": {
          content: "<span style='".concat(x.backgroundColor, "'>").concat(x.factoryName, "</span>")
        }
      }
    });
  }), 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };