import "core-js/modules/es.array.splice.js";
var render = function render() {
  var _vm$heatmapChart$seri, _vm$dataAnalysisChart, _vm$heatmapChart$seri2, _vm$dataAnalysisChart2;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-alert', {
    staticClass: "text-center font-weight-bold",
    attrs: {
      "show": _vm.dataAnalysisResError,
      "variant": "secondary"
    }
  }, [_vm._v(" 此區間 暫無資料，請重新選擇日期或資料 ")]), _c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap text-center"
  }, _vm._l(_vm.analysisSelections, function (select) {
    return _c('div', {
      key: select.order,
      staticClass: "font-weight-bold pageButton datepickerButton",
      class: {
        active: _vm.activeOrder === select.order
      },
      on: {
        "click": function click($event) {
          return _vm.selectAnalysis(select.order);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(select.translation)) + " ")]);
  }), 0), _c('div', {
    staticStyle: {
      "min-height": "80px"
    }
  }, [_vm.activeOrder !== 2 && _vm.activeOrder !== 3 && _vm.activeOrder !== 10 && _vm.activeOrder !== 11 ? _c('div', {
    staticClass: "checkBoxStyle"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-3",
    attrs: {
      "value": "selected",
      "unchecked-value": "unSelected"
    },
    on: {
      "change": _vm.selectedToggleAll
    },
    model: {
      value: _vm.selectedInvAll,
      callback: function callback($$v) {
        _vm.selectedInvAll = $$v;
      },
      expression: "selectedInvAll"
    }
  }, [_vm._v("全選 ")]), _c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.invSelections
    },
    model: {
      value: _vm.invSelectedList,
      callback: function callback($$v) {
        _vm.invSelectedList = $$v;
      },
      expression: "invSelectedList"
    }
  })], 1) : _vm._e(), _vm.activeOrder === 10 && _vm.anemometerSelections.length ? _c('div', {
    staticClass: "checkBoxStyle"
  }, [_c('b-form-checkbox', {
    staticClass: "mr-3",
    attrs: {
      "value": "selected",
      "unchecked-value": "unSelected"
    },
    on: {
      "change": _vm.selectedToggleAll
    },
    model: {
      value: _vm.selectedAnemometerAll,
      callback: function callback($$v) {
        _vm.selectedAnemometerAll = $$v;
      },
      expression: "selectedAnemometerAll"
    }
  }, [_vm._v("全選 ")]), _c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.anemometerSelections
    },
    model: {
      value: _vm.anemometerSelectedList,
      callback: function callback($$v) {
        _vm.anemometerSelectedList = $$v;
      },
      expression: "anemometerSelectedList"
    }
  })], 1) : _vm._e(), _vm.activeOrder !== 2 && _vm.activeOrder !== 3 && _vm.activeOrder !== 11 || _vm.activeOrder === 10 && _vm.anemometerSelections.length ? _c('button', {
    staticClass: "confirmButton m-1 mr-3 d-flex ml-auto",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.reSetSelectedList
    }
  }, [_vm._v(" 重新選擇 ")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center flex-wrap mb-3"
  }, [_vm.dateCateActive !== 0 ? _c('div', {
    staticClass: "d-flex align-items-center mr-3 mt-3",
    staticStyle: {
      "color": "var(--normaltextcolor)"
    }
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": "checkbox"
    }
  }, [_vm._v("選擇區間 ")]), _c('b-form-checkbox', {
    staticClass: "ml-1",
    attrs: {
      "id": "checkbox"
    },
    on: {
      "change": _vm.selectedToggleAll
    },
    model: {
      value: _vm.hasSelectedInterval,
      callback: function callback($$v) {
        _vm.hasSelectedInterval = $$v;
      },
      expression: "hasSelectedInterval"
    }
  })], 1) : _vm._e(), !_vm.hasSelectedInterval ? _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_vm._v(" 選擇日期 :  "), _c('datePicker', {
    staticClass: "inputStyle ml-1 mr-3",
    attrs: {
      "format": _vm.format,
      "minimum-view": _vm.minimumView,
      "disabled-dates": _vm.disabledDates,
      "language": _vm.zh
    },
    model: {
      value: _vm.datepickerDate,
      callback: function callback($$v) {
        _vm.datepickerDate = $$v;
      },
      expression: "datepickerDate"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center mt-3"
  }, [_c('datePicker', {
    staticClass: "inputStyle",
    attrs: {
      "format": _vm.choose,
      "minimum-view": _vm.chooseView,
      "disabled-dates": _vm.disabledDates,
      "language": _vm.zh
    },
    model: {
      value: _vm.beginDate,
      callback: function callback($$v) {
        _vm.beginDate = $$v;
      },
      expression: "beginDate"
    }
  }), _c('div', {
    staticClass: "mx-2"
  }, [_vm._v("~")]), _c('datePicker', {
    staticClass: "inputStyle mr-3",
    attrs: {
      "format": _vm.choose,
      "minimum-view": _vm.chooseView,
      "disabled-dates": _vm.disabledDates,
      "language": _vm.zh
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1), _c('div', {
    staticClass: "mt-3"
  }, [_vm.hasDataAnalysisTimePermission && _vm.activeOrder !== 6 && _vm.activeOrder !== 10 ? _c('button', {
    staticClass: "datepickerButton",
    class: {
      'date-active': _vm.dateCateActive === 0
    },
    staticStyle: {
      "margin": "0px 3px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.selectDateCate(0);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dataAnalysis.hour")) + " ")]) : _vm._e(), _vm.activeOrder !== 6 && _vm.activeOrder !== 10 ? _c('button', {
    staticClass: "datepickerButton",
    class: {
      'date-active': _vm.dateCateActive === 1
    },
    staticStyle: {
      "margin": "0px 3px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.selectDateCate(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dataAnalysis.date")) + " ")]) : _vm._e(), _vm.activeOrder !== 6 && _vm.activeOrder !== 10 ? _c('button', {
    staticClass: "datepickerButton mr-3",
    class: {
      'date-active': _vm.dateCateActive === 2
    },
    staticStyle: {
      "margin": "0px 3px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.selectDateCate(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dataAnalysis.month")) + " ")]) : _vm._e()]), _c('button', {
    staticClass: "confirmButton mt-3",
    on: {
      "click": _vm.buttonConfirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("deviceStatus.confirm")) + " ")])])]), _c('div', {
    staticClass: "borderColor borderColorBackground mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-3"
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.multiClassSelections,
      "switches": ""
    },
    model: {
      value: _vm.classSelection,
      callback: function callback($$v) {
        _vm.classSelection = $$v;
      },
      expression: "classSelection"
    }
  }), (_vm$heatmapChart$seri = _vm.heatmapChart.series[0]) !== null && _vm$heatmapChart$seri !== void 0 && _vm$heatmapChart$seri.data.length || (_vm$dataAnalysisChart = _vm.dataAnalysisChart.series[0]) !== null && _vm$dataAnalysisChart !== void 0 && _vm$dataAnalysisChart.data.length ? _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" 資料下載 ")]) : _vm._e()], 1), !((_vm$heatmapChart$seri2 = _vm.heatmapChart.series[0]) !== null && _vm$heatmapChart$seri2 !== void 0 && _vm$heatmapChart$seri2.data.length) && !((_vm$dataAnalysisChart2 = _vm.dataAnalysisChart.series[0]) !== null && _vm$dataAnalysisChart2 !== void 0 && _vm$dataAnalysisChart2.data.length) ? _c('div', {
    staticClass: "bg-white text-center",
    staticStyle: {
      "line-height": "350px"
    }
  }, [_vm._v(" 無數據資料，請重新選擇日期或選項 ")]) : _vm.activeOrder === 6 ? _c('div', {
    staticClass: "bg-white"
  }, [_c('highcharts', {
    staticClass: "heatmapSection",
    attrs: {
      "options": _vm.heatmapChart
    }
  })], 1) : _c('highcharts', {
    staticClass: "bg-white",
    attrs: {
      "options": _vm.dataAnalysisChart
    }
  })], 1), _vm.hasRoleAdmin && _vm.activeOrder === 11 ? _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('b-table', {
    staticClass: "tableStyle",
    attrs: {
      "items": _vm.remarkData,
      "fields": _vm.tableFields,
      "hover": "",
      "small": "",
      "responsive": true,
      "foot-clone": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(edit)",
      fn: function fn(data) {
        return [_c('button', {
          staticClass: "btn btn-sm mr-3",
          class: [_vm.remarkData[data.index].isEditing ? 'btn-secondary' : 'btn-outline-secondary'],
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCheckRemark(data.index);
            }
          }
        }, [!_vm.remarkData[data.index].isEditing ? _c('i', {
          staticClass: "fas fa-pen"
        }) : _c('i', {
          staticClass: "fas fa-check"
        })]), _c('button', {
          staticClass: "btn btn-outline-secondary btn-sm mr-4",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.remarkData.splice(data.index, 1);
            }
          }
        }, [_c('i', {
          staticClass: "far fa-trash-alt"
        })])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(data) {
        var _vm$remarkData, _vm$remarkData$data$i;
        return [!((_vm$remarkData = _vm.remarkData) !== null && _vm$remarkData !== void 0 && (_vm$remarkData$data$i = _vm$remarkData[data.index]) !== null && _vm$remarkData$data$i !== void 0 && _vm$remarkData$data$i.isEditing) ? _c('div', [_vm._v(" " + _vm._s(data.value) + " ")]) : _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-radio-group', {
          staticClass: "mr-3",
          attrs: {
            "options": _vm.remarkDateRadioOptions,
            "buttons": "",
            "button-variant": "outline-secondary",
            "size": "sm"
          },
          on: {
            "change": function change($event) {
              return _vm.handleChangeRemarkDateType(data.index, data.item.radio);
            }
          },
          model: {
            value: data.item.radio,
            callback: function callback($$v) {
              _vm.$set(data.item, "radio", $$v);
            },
            expression: "data.item.radio"
          }
        }), _c('vue-date-picker', {
          staticClass: "mr-4",
          attrs: {
            "type": data.item.radio,
            "value-type": "format",
            "clearable": false
          },
          on: {
            "change": function change($event) {
              return _vm.handleSelectRemarkDate(data.index, data.field.key, data.value);
            }
          },
          model: {
            value: data.value,
            callback: function callback($$v) {
              _vm.$set(data, "value", $$v);
            },
            expression: "data.value"
          }
        })], 1)];
      }
    }, {
      key: "cell(time)",
      fn: function fn(data) {
        var _vm$remarkData2, _vm$remarkData2$data$;
        return [!((_vm$remarkData2 = _vm.remarkData) !== null && _vm$remarkData2 !== void 0 && (_vm$remarkData2$data$ = _vm$remarkData2[data.index]) !== null && _vm$remarkData2$data$ !== void 0 && _vm$remarkData2$data$.isEditing) ? _c('div', [_vm._v(" " + _vm._s(data.value) + " ")]) : _c('b-form-select', {
          staticClass: "mr-4",
          attrs: {
            "size": "sm",
            "options": _vm.remarkTimeOptions,
            "disabled": data.item.radio !== 'date'
          },
          on: {
            "change": function change($event) {
              return _vm.handleSelectRemarkTime(data.index, data.field.key, data.value);
            }
          },
          model: {
            value: data.value,
            callback: function callback($$v) {
              _vm.$set(data, "value", $$v);
            },
            expression: "data.value"
          }
        })];
      }
    }, {
      key: "cell(remark)",
      fn: function fn(data) {
        var _vm$remarkData3, _vm$remarkData3$data$;
        return [!((_vm$remarkData3 = _vm.remarkData) !== null && _vm$remarkData3 !== void 0 && (_vm$remarkData3$data$ = _vm$remarkData3[data.index]) !== null && _vm$remarkData3$data$ !== void 0 && _vm$remarkData3$data$.isEditing) ? _c('div', [_vm._v(" " + _vm._s(data.value) + " ")]) : _c('b-form-input', {
          staticClass: "w-100 remarkInput",
          attrs: {
            "type": "text",
            "size": "sm"
          },
          on: {
            "input": function input($event) {
              return _vm.handleInputRemark(data.index, data.field.key, data.value);
            }
          },
          model: {
            value: data.value,
            callback: function callback($$v) {
              _vm.$set(data, "value", $$v);
            },
            expression: "data.value"
          }
        })];
      }
    }, {
      key: "foot(edit)",
      fn: function fn(data) {
        return [_c('button', {
          staticClass: "btn btn-outline-info btn-sm",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.remarkData.splice(_vm.remarkData.length, 0, {
                radio: 'date',
                date: '',
                time: null,
                remark: '',
                isEditing: true
              });
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-plus",
          staticStyle: {
            "font-size": "16px"
          }
        })])];
      }
    }, {
      key: "foot()",
      fn: function fn(data) {
        return [_vm._v(_vm._s(""))];
      }
    }], null, false, 4177134442)
  }), _c('button', {
    staticClass: "confirmButton ml-1 mb-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateAvailabilityRemark
    }
  }, [_vm._v(" 更新 ")])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };