var render = function render() {
  var _vm$demoSelect;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("使用者管理")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center m-2 mb-3"
  }, [_c('div', {
    staticClass: "position-relative mr-2 d-flex align-items-center"
  }, [_c('i', {
    staticClass: "fas fa-search mr-2 text-secondary"
  }), _c('b-form-input', {
    staticClass: "filterInput textInput rounded-0",
    attrs: {
      "size": "sm",
      "placeholder": '依' + _vm.selectedFilter.text + '搜尋'
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('span', {
    staticClass: "focus-border position-absolute"
  })], 1), _c('b-form-select', {
    staticClass: "w-auto mr-4",
    attrs: {
      "options": _vm.filterOptions,
      "size": "sm"
    },
    model: {
      value: _vm.selectedFilter,
      callback: function callback($$v) {
        _vm.selectedFilter = $$v;
      },
      expression: "selectedFilter"
    }
  }), _c('span', {
    staticClass: "m-0 p-0 font-weight-bold"
  }, [_vm._v(" 資料筆數: " + _vm._s(_vm.allUserDataLength) + " ")]), _c('button', {
    staticClass: "confirmButton p-1 my-1 ml-auto",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openCreateUserModal
    }
  }, [_vm._v(" 新增帳戶 ")])], 1), _c('b-overlay', {
    style: _vm.minHeight,
    attrs: {
      "show": _vm.loadingData
    }
  }, [_c('div', {
    staticClass: "otherTableStyle tableWrap"
  }, [_c('table', {
    staticClass: "w-100 text-center"
  }, [_c('tr', {
    staticClass: "sticky-top text-nowrap"
  }, _vm._l(_vm.thTitle, function (i, index) {
    return _c('th', {
      key: index,
      staticClass: "sort"
    }, [_vm._v(" " + _vm._s(i.text) + " ")]);
  }), 0), _vm._l(_vm.allUserSearch, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(i.account))]), _c('td', [_vm._v(_vm._s(i.displayName))]), _c('td', [_c('div', _vm._l(i.factoriesList, function (x, index) {
      return _c('span', {
        key: index
      }, [_vm._v(_vm._s(x)), index !== i.factoriesList.length - 1 ? _c('span', [_vm._v("，")]) : _vm._e()]);
    }), 0)]), _c('td', [_c('div', _vm._l(i.demoList, function (x, index) {
      return _c('span', {
        key: index
      }, [_vm._v(_vm._s(x)), index !== i.demoList.length - 1 ? _c('span', [_vm._v("，")]) : _vm._e()]);
    }), 0)]), _c('td', [_vm._v(_vm._s(i.role))]), _c('td', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.openEditUserModal(i.account, i.displayName, i.role, i.factoriesList, i.demoList, i.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-user-cog"
    })])]);
  })], 2)])])], 1), _c('b-modal', {
    attrs: {
      "id": "userModal",
      "size": "lg",
      "title": _vm.modalTitle,
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "otherTableStyle"
  }, [_c('table', {
    staticClass: "w-100 text-center newUserStyle"
  }, [_c('tr', [_c('th', [_vm._v("標題")]), _c('th', [_vm._v("內容")])]), _c('tr', [_c('td', [_vm._v("帳號")]), _c('td', [_c('b-form-input', {
    staticClass: "textInput",
    attrs: {
      "disabled": _vm.isUpdateAccount,
      "placeholder": "請輸入帳號",
      "size": "sm",
      "onkeyup": "value=value.replace(/[^a-zA-Z0-9]/g,'')"
    },
    model: {
      value: _vm.account,
      callback: function callback($$v) {
        _vm.account = $$v;
      },
      expression: "account"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("密碼")]), _c('td', [_c('b-form-input', {
    staticClass: "textInput",
    attrs: {
      "placeholder": _vm.passwordPlaceholder,
      "size": "sm",
      "autocomplete": "off",
      "onkeyup": "value=value.replace(/[\\W]/g,'')"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("稱呼")]), _c('td', [_c('b-form-input', {
    staticClass: "textInput",
    attrs: {
      "placeholder": "中英文皆可",
      "size": "sm",
      "onkeyup": "this.value=this.value.replace(/[, ]/g,'')"
    },
    model: {
      value: _vm.displayName,
      callback: function callback($$v) {
        _vm.displayName = $$v;
      },
      expression: "displayName"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("可觀看案場")]), _c('td', [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "text-left cursor-pointer",
    staticStyle: {
      "min-height": "20px"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openFactoryCheckBox.apply(null, arguments);
      }
    }
  }, [_vm.factorySelect.length ? _c('span', _vm._l(_vm.factorySelect, function (i, idx) {
    return _c('span', {
      key: idx
    }, [_vm._v(" " + _vm._s(i) + " "), idx !== _vm.factorySelect.length - 1 ? _c('span', [_vm._v("，")]) : _vm._e()]);
  }), 0) : _c('span', [_vm._v(" 尚未選擇 ")])]), _vm.isOpenFactoryCheckBox ? _c('div', {
    staticClass: "checkBoxStyle"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_vm._v(" 篩選: "), _c('b-form-input', {
    staticClass: "textInput ml-2 mr-auto w-auto",
    attrs: {
      "placeholder": "案場名稱搜尋",
      "size": "sm"
    },
    model: {
      value: _vm.factoryCheckBoxFilterInput,
      callback: function callback($$v) {
        _vm.factoryCheckBoxFilterInput = $$v;
      },
      expression: "factoryCheckBoxFilterInput"
    }
  }), _c('b-button', {
    staticClass: "py-1 px-3",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openFactoryCheckBox.apply(null, arguments);
      }
    }
  }, [_vm._v(" 關閉 ")])], 1), _c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.factoryCheckBoxFilter,
      "plain": "",
      "stacked": ""
    },
    on: {
      "change": _vm.handleChangeFactorySelect
    },
    model: {
      value: _vm.factorySelect,
      callback: function callback($$v) {
        _vm.factorySelect = $$v;
      },
      expression: "factorySelect"
    }
  })], 1) : _vm._e()])])]), _c('tr', [_c('td', [_vm._v("可觀看展示案場")]), _c('td', [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "text-left cursor-pointer",
    staticStyle: {
      "min-height": "20px"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openDemoCheckBox.apply(null, arguments);
      }
    }
  }, [(_vm$demoSelect = _vm.demoSelect) !== null && _vm$demoSelect !== void 0 && _vm$demoSelect.length ? _c('span', _vm._l(_vm.demoSelect, function (i, idx) {
    return _c('span', {
      key: idx
    }, [_vm._v(" " + _vm._s(i) + " "), idx !== _vm.demoSelect.length - 1 ? _c('span', [_vm._v("，")]) : _vm._e()]);
  }), 0) : _c('span', [_vm._v(" 尚未選擇 ")])]), _vm.isOpenDemoCheckBox ? _c('div', {
    staticClass: "checkBoxStyle"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_vm._v(" 篩選: "), _c('b-form-input', {
    staticClass: "textInput ml-2 mr-auto w-auto",
    attrs: {
      "placeholder": "案場名稱搜尋",
      "size": "sm"
    },
    model: {
      value: _vm.demoCheckBoxFilterInput,
      callback: function callback($$v) {
        _vm.demoCheckBoxFilterInput = $$v;
      },
      expression: "demoCheckBoxFilterInput"
    }
  }), _c('b-button', {
    staticClass: "py-1 px-3",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.openDemoCheckBox.apply(null, arguments);
      }
    }
  }, [_vm._v(" 關閉 ")])], 1), _c('div', {
    staticClass: "my-1"
  }, [_vm._v("(最多選擇 5 個)")]), _c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.demoCheckBoxFilter,
      "plain": "",
      "stacked": ""
    },
    on: {
      "change": _vm.handleChangeDemoSelect
    },
    model: {
      value: _vm.demoSelect,
      callback: function callback($$v) {
        _vm.demoSelect = $$v;
      },
      expression: "demoSelect"
    }
  })], 1) : _vm._e()])])]), _c('tr', [_c('td', [_vm._v("權限")]), _c('td', [_c('b-form-select', {
    attrs: {
      "options": _vm.roleOptions,
      "size": "sm"
    },
    model: {
      value: _vm.registerRole,
      callback: function callback($$v) {
        _vm.registerRole = $$v;
      },
      expression: "registerRole"
    }
  })], 1)])])]), !_vm.isUpdateAccount ? _c('button', {
    staticClass: "confirmButton p-1 mt-3 d-flex ml-auto",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.createUser
    }
  }, [_vm._v(" 創建 ")]) : _c('b-overlay', {
    staticClass: "d-flex align-items-center mt-2",
    attrs: {
      "show": _vm.isLoadingUpdate
    }
  }, [_c('b-button', {
    staticClass: "py-1 px-3 ml-auto mr-3 mt-1",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": _vm.checkDeleteUser
    }
  }, [_vm._v(" 刪除使用者 ")]), _c('button', {
    staticClass: "confirmButton p-1 mt-1",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.updateUserInfo
    }
  }, [_vm._v(" 更新 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };