var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    on: {
      "scroll": _vm.scrollScreen
    }
  }, [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("案場排序")]), _c('div', {
    staticClass: "borderColor p-4 mb-4"
  }, [_c('button', {
    staticClass: "confirmButton my-2 mr-3",
    staticStyle: {
      "width": "100px",
      "padding": "3px"
    },
    on: {
      "click": _vm.resetSortedData
    }
  }, [_c('i', {
    staticClass: "fas fa-undo"
  }), _vm._v(" 還原 ")]), _c('button', {
    staticClass: "confirmButton my-2",
    staticStyle: {
      "width": "120px",
      "padding": "3px"
    },
    on: {
      "click": _vm.checkUpdateSortedData
    }
  }, [_vm._v(" 更新排序 ")]), _c('div', {
    staticClass: "my-3"
  }, [_vm._v("請拖曳、排序案場名稱:")]), _c('div', {
    ref: "sortableList",
    staticClass: "listWrap"
  }, _vm._l(_vm.sortingData, function (factoryName, idx) {
    return _c('div', {
      key: idx,
      staticClass: "listItem d-flex border rounded",
      attrs: {
        "id": "sortableListId".concat(idx)
      }
    }, [_c('span', {
      staticClass: "text-secondary listNum"
    }, [_vm._v(_vm._s(idx + 1))]), _vm._v(" " + _vm._s(factoryName) + " ")]);
  }), 0)]), !_vm.clientTop ? _c('div', {
    staticClass: "backTop",
    on: {
      "click": _vm.backTop
    }
  }, [_c('i', {
    staticClass: "fas fa-chevron-up"
  })]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };