var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "borderColor",
    staticStyle: {
      "background": "#fff"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, _vm._l(_vm.fakeArr, function (i, index) {
    return _c('div', {
      key: index,
      staticClass: "infoWidth",
      staticStyle: {
        "font-weight": "700"
      }
    }, [_c('div', {
      staticClass: "colorBox"
    }), _c('div', {
      staticClass: "infoBackground",
      on: {
        "click": function click($event) {
          return _vm.change(index);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "insideWidth"
    }, [_vm._v("id")]), _c('div', {
      staticClass: "insideWidth"
    }, [_vm._v("I/F")])]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('div', {
      staticClass: "insideWidth"
    }, [_vm._v("Virtual PORT1")]), _c('div', {
      staticClass: "insideWidth"
    }, [_vm._v("連線: OK")]), _c('div', {
      staticClass: "insideWidth"
    }, [_vm._v("回應時間:6138ms")])])]), _c('table', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: i.show,
        expression: "i.show"
      }]
    }, [_c('tr', [_c('th', [_vm._v("name")]), _c('th', [_vm._v("value")])]), _vm._l(10, function (i) {
      return _c('tr', {
        key: i
      }, [_c('td', {
        attrs: {
          "width": "30%"
        }
      }, [_vm._v("電流")]), _c('td', {
        attrs: {
          "width": "70%"
        }
      }, [_vm._v("2.11111545478")])]);
    })], 2)]);
  }), 0)])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };