export default {
  centerDemo: '雲端監控中心',
  login: '登入',
  sideBarItems: {
    realTime: '即時資訊',
    siteComparation: '案場比較',
    fangDe: '案場2',
    fuQiangXin: '案場3',
    fuQiangXinDemoPage: '展示畫面即時資訊',
    lineChart: '電廠電量比較'
  },
  realTime: {
    title: '即時資訊',
    status: {
      systemNormal: '系統正常營運',
      noPowerData: '發電數據異常',
      partialSystemError: '部分通訊異常',
      backfill: '資料缺漏回補',
      deviceDisconnected: '系統未連線',
      deviceDisconnectedShort: '系統短暫斷線',
      noData: '尚無資料',
      poorPower: '發電效率不佳'
    },
    table: {
      Capacity: '設置容量',
      irradiance: '日照量(W/m²)',
      PVTemperature: '模組溫度(°C)',
      DCPower: '直流功率(kW)',
      ACPower: '交流功率(kW)',
      TodayCommission: '今日發電量(kWh)',
      INV: 'INV效率(%)',
      RA: 'RA(%)',
      PR: 'PR(%)',
      SiteStatus: '案場狀態'
    },
    dataFound: '資料筆數'
  },
  siteComparation: {
    goodRate: '妥善率分析',
    peakWatt: '平均峰瓩值 (kWh/kWp) 比較表',
    INVpeakWatt: '各變流器峰瓩值 (kWh/kWp) 比較表'
  },
  fangDe: {
    title: '發電與用電資訊',
    selectTime: '請選擇下載資料的日期',
    to: '至',
    chartTitleHour: '每小時發電與用電資訊',
    chartTitleDay: '每日發電與用電資訊',
    chartTitleMon: '每月發電與用電資訊'
  },
  fuQiangXin: {
    title: '發電資訊彙整',
    chartTitle: '太陽光電系統監控畫面 (即時資訊)'
  },
  FuQiangXinDemoPage: {
    title: '展示畫面即時資訊',
    status: {
      systemNormal: '系統營運中',
      partialSystemError: '部分設備異常',
      systemError: '系統異常',
      deviceDisconnected: '系統未連線'
    }
  },
  lineChart: {
    title: '電廠電量比較',
    selectDate: '選擇日期',
    startTime: '起始時間',
    endTime: '結束時間',
    endDate: '結束日期',
    tableTr: {
      id: '序號',
      device: '裝置',
      curveColor: '曲線顏色',
      curveType: '曲線型態',
      func: '功能'
    }
  },
  tableTr: {
    delete: '刪除',
    show: '顯示'
  },
  siteInfo: {
    energyInfo: '案場資訊',
    DeviceStatus: '裝置即時資訊',
    DeviceHistory: '裝置歷史資料',
    DataAnalysis: '資料分析',
    DesignInfo: '設計資訊',
    FailureAnalysis: '串列分析',
    LatestInformation: '最新資料',
    InvestmentAnalysis: '投資分析',
    report: '案場報表',
    otherInfo: '其他資訊'
  },
  siteInfoDetail: {
    siteInfo: '案場資訊',
    siteInfoKey: {
      startUpTime: '系統啟動時間',
      customId: '自訂編號',
      deviceDirection: '案場座向',
      deviceRegion: '案場區域',
      country: '國別',
      county: '縣市',
      townShip: '鄉鎮區'
    },
    siteInfoChoseDate: {
      分: '分',
      時: '時',
      日: '日',
      月: '月',
      年: '年',
      最近30天: '最近30天',
      時歷史: '時歷史',
      日歷史: '日歷史',
      月歷史: '月歷史',
      年歷史: '年歷史',
      變流器歷史: '變流器歷史'
    },
    hightChartName: {
      Energy: '發電量 (kWh)',
      insolation: '日照量 (kWh/m²)',
      PerformanceRatio: '發電效率',
      PVTemperature: '模組溫度 (°C)',
      Revenue: '發電收入 (NT)'
    },
    accumulativePowerGeneration: '累積發電量',
    todayPowerGeneration: '今日發電量',
    showMore: '顯示更多',
    liveInfo: '即時資訊',
    irr: '目前日照強度 (w/m²)',
    eToday: '今日日照量 (kWh/m²)',
    peak: '今日峰瓩值 (kWh/kWp)',
    pr: '今日 PR (%)',
    lowCurrent: '低壓功率 (kW)',
    highCurrent: '高壓功率 (kW)',
    moduleTemp: '模組溫度 (°C)',
    envTemp: '環境溫度 (°C)',
    anemometer: '即時風速 (m/s)',
    windVane: '即時風向 (°)',
    waterLevel: '即時水位值 (m)'
  },
  deviceStatus: {
    deviceLiveInformation: '裝置即時資訊(每分鐘更新一次)',
    invLiveInformation: '每台變流器之即時詳細資訊',
    connectTime: '連線時間:',
    download: '下載',
    confirm: '確認',
    hightChartName: {
      cumulativePowerGeneration: '累積發電量 (kWh)',
      todayPowerGeneration: '今日發電量 (kWh)',
      acPowerGeneration: '交流發電功率',
      dcPowerGeneration: '直流發電功率'
    },
    tableTitle: {
      inv: 'INV 名稱',
      connectionStatus: '連線狀態',
      accumulativePowerGeneration: '累積發電量 (kWh)',
      todayPowerGeneration: '今日發電量 (kWh)',
      dcPowerGeneration: '直流發電功率 (kW)',
      acPowerGeneration: '交流發電功率 (kW)',
      invEffectiveness: 'INV 效率 (%)',
      frequency: '頻率 (Hz)',
      abVoltage: 'Vab 電壓 (V)',
      bcVoltage: 'Vbc 電壓 (V)',
      caVoltage: 'Vca 電壓 (V)',
      APhaseCurrent: 'A相電流 (A)',
      BPhaseCurrent: 'B相電流 (A)',
      CPhaseCurrent: 'C相電流 (A)'
    }
  },
  deviceHistory: {
    selectINV: '選擇變流器',
    Date: '日期',
    download: '下載',
    specifiedINVInfoPerMin: '每分鐘裝置資訊表',
    confirm: '確認',
    tableTitle: {
      Time: '時間',
      frequency: '頻率 (Hz)',
      abVoltage: 'Vab 電壓 (V)',
      bcVoltage: 'Vbc 電壓 (V)',
      caVoltage: 'Vca 電壓 (V)',
      APhaseCurrent: 'A相電流 (A)',
      BPhaseCurrent: 'B相電流 (A)',
      CPhaseCurrent: 'C相電流 (A)'
    }
  },
  dataAnalysis: {
    electricityRevenue: '發電與收入',
    INVEfficiency: '變流器轉換效率',
    averageInsolation: '平均日照分析',
    moduleTemp: '溫度分析',
    PR: 'PR值分析',
    peakWatt: '峰瓩值',
    peakWattHeatmap: '峰瓩值熱圖',
    CO2Reduction: '二氧化碳抑低量',
    invTemperature: 'INV溫度',
    insulation: 'Insulation',
    anemometer: '風速計',
    availability: '妥善率',
    selectINV: '選擇變流器',
    today: '起始日期',
    hour: '時',
    date: '日',
    month: '月',
    year: '年',
    INVComparison: '發電統計與發電收入圖',
    INVComparison1: '變流器轉換效率分析圖',
    INVComparison2: '日照分析圖',
    INVComparison3: '溫度分析圖',
    INVComparison4: 'PR 值分析圖',
    INVComparison5: '峰瓩值分析圖',
    INVComparison6: '峰瓩值熱圖',
    INVComparison7: '二氧化碳排放抑制量圖',
    INVComparison8: 'INV溫度',
    INVComparison9: 'Insulation',
    INVComparison10: '風速計',
    INVComparison11: '妥善率',
    powerGeneration: '發電量',
    investmentIncome: '投資收入',
    download: '資料下載',
    INV: '變流器',
    dataAnalysisChartName: {
      time: '時間',
      eleHour: '發電時數 (h)',
      PowerGeneration: '發電量 (kWh)',
      investmentIncome: '投資收入 (NTD)',
      Conversionefficiency: '轉換效率 (%)',
      Sunshine: '日照量 (kWh/m²)',
      avgSunshine: '平均日照量 (kWh/m²)',
      PVTemperature: '模組溫度 (°C)',
      Temperature: '環境溫度 (°C)',
      PR: 'PR值 (%)',
      PeakWatt: '峰瓩值 (kWh/kWp)',
      SunshineHour: '等效日照時數 (h)',
      CO2: '二氧化碳排放抑制量 (kg)',
      tree: '種樹 (棵)'
    }
  },
  pvstringfailureanalysis: {
    liveInfo: '即時資訊',
    groupConfiguration: '群組設定',
    circleChart: '圓餅圖',
    historyData: '歷史資料查詢'
  },
  queryhistorydata: {
    selectTime: '選擇時間'
  },
  powerFee: {
    pvInfo: '串列監控值之即時詳細資訊',
    connectTime: '連線時間',
    download: '下載'
  },
  weekday: {
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六',
    Sunday: '星期日'
  }
}
