var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "center"
    }
  }, [_c('div', {
    staticClass: "text-center mt-4 h5"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("powerFee.pvInfo")) + " ")]), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" (" + _vm._s(_vm.$t("powerFee.connectTime")) + ": 2019/12/05 13:32:50) ")])])]), _c('downloadBtn', {
    staticClass: "mb-4 d-flex justify-content-center"
  }), _c('b-row', [_c('b-table', {
    attrs: {
      "items": _vm.tableGroup,
      "striped": "",
      "hover": "",
      "thead-class": "g-class"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };