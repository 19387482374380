/**
 * Replace some site names with their aliases 替換案場名稱
 * @param {string} siteName the site name to be replaced
 * @returns {string} the replaced site name
 */
export const getSiteAliasName = (siteName) => {
  if (siteName === '海創一期') return '海創中心'
  if (siteName === '海創二期') return '海創人培中心'
  return siteName
}

/**
 * Restore a site name with alias back to its original name 還原替換的案場名稱
 * @param {string} siteName the site name with alias
 * @returns {string} the original site name
 */
export const getRestoreSiteAliasName = (siteName) => {
  if (siteName === '海創中心') return '海創一期'
  if (siteName === '海創人培中心') return '海創二期'
  return siteName
}
