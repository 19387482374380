import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-alert', {
    staticClass: "text-center font-weight-bold",
    attrs: {
      "show": _vm.resError,
      "variant": "secondary"
    }
  }, [_vm._v(" 目前 " + _vm._s(_vm.resChoose) + " 暫無資料，請重新選擇日期或資料 ")]), _c('div', {
    staticClass: "borderColor mb-3"
  }, [_c('ChartsAndBarItem', {
    ref: "chartsAndBarItemRef",
    attrs: {
      "format": _vm.datePickerFormat,
      "date-type-clicked-title": _vm.dateTypeClickedTitle
    },
    on: {
      "bar-item-click": _vm.handleClickBarItem,
      "inv-select": _vm.handleSelectInv,
      "date-select": _vm.handleSelectDate
    }
  }), _c('TimeSeparator', {
    staticClass: "m-5px",
    attrs: {
      "date-type": _vm.dateType,
      "date-type-clicked-idx": _vm.dateTypeClickedIdx
    },
    on: {
      "date-type-click": _vm.handleClickDateType
    }
  }), _c('Highcharts', {
    ref: "highchartsRef",
    attrs: {
      "options": _vm.siteInfoDetailChartOptions
    }
  })], 1), _c('div', {
    staticClass: "info_section"
  }, [_c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "info_title"
  }, [_vm._v(_vm._s(_vm.$t("siteInfoDetail.siteInfo")))]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "info_wrap"
  }, _vm._l(_vm.siteInfoValue, function (info, key, idx) {
    return _c('div', {
      key: key
    }, [idx < 10 && idx !== 6 ? _c('div', {
      staticClass: "key-values"
    }, [_c('div', {
      staticClass: "keys"
    }, [_vm._v(_vm._s(_vm.changeInfoLanguage(key)))]), _c('div', {
      staticClass: "values"
    }, [_vm._v(" " + _vm._s(_vm.urlDemo === "true" ? idx === 0 ? _vm.urlSiteName : _vm.demoContent[idx] : info || "-") + " ")])]) : _vm._e()]);
  }), 0), _c('div', {
    staticClass: "info_wrap"
  }, [_vm._l(_vm.siteInfoValue, function (info, key, idx) {
    return _c('div', {
      key: key
    }, [idx > 9 && idx <= 13 || idx === 17 ? _c('div', {
      staticClass: "key-values"
    }, [_c('div', {
      staticClass: "keys"
    }, [_vm._v(_vm._s(_vm.changeInfoLanguage(key)))]), _c('div', {
      staticClass: "values"
    }, [_vm._v(" " + _vm._s(_vm.urlDemo === "true" ? _vm.demoContent[idx] : info) + " ")])]) : _vm._e()]);
  }), _vm._m(0)], 2)]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.site-instant-modal",
      modifiers: {
        "site-instant-modal": true
      }
    }],
    staticClass: "btn_showMore"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteInfoDetail.showMore")) + " ")])], 1), _c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "info_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteInfoDetail.accumulativePowerGeneration")) + " ")]), _c('div', {
    staticClass: "vol-value"
  }, [_c('span', {
    class: {
      surprise: _vm.isNewData,
      surprise1: !_vm.isNewData
    }
  }, [_vm._v(" " + _vm._s(_vm.voltages.accVal) + " ")]), _c('div', [_vm._v("kWh")])]), _c('div', {
    staticClass: "info_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteInfoDetail.todayPowerGeneration")) + " ")]), _c('div', {
    staticClass: "vol-value px-5"
  }, [_c('span', {
    class: {
      surprise: _vm.isNewData,
      surprise1: !_vm.isNewData
    }
  }, [_vm._v(" " + _vm._s(_vm.voltages.todayVal) + " ")]), _c('div', [_vm._v("kWh")])])]), _c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "info_title"
  }, [_vm._v(" " + _vm._s(_vm.$t("siteInfoDetail.liveInfo")) + " ")]), _c('div', {
    staticClass: "instant_info",
    style: {
      'grid-template-columns': !_vm.roleOthersMenu[1].hasPermission && '1fr'
    }
  }, [_c('div', {
    staticClass: "grid_weather"
  }, [_c('img', {
    staticClass: "weather_img",
    attrs: {
      "src": _vm.weatherData.iconUrl
    }
  }), _c('div', {
    staticClass: "weather_text"
  }, [_c('div', {
    staticClass: "num mr-0",
    class: {
      surprise: _vm.isNewData,
      surprise1: !_vm.isNewData
    }
  }, [_vm._v(" " + _vm._s(_vm.weatherData.temp.toFixed(1)) + " ")]), _c('div', {
    staticClass: "temperature_unit"
  }, [_vm._v("°C")])]), _c('div', {
    staticClass: "instant_cell_text"
  }, [_vm._v(_vm._s(_vm.weatherData.desc))])]), _vm._l(_vm.instantInfo, function (instant, instantIdx) {
    var _instant$title;
    return _c('div', {
      key: instantIdx,
      staticClass: "instant_cell"
    }, [_c('div', [_c('div', {
      staticClass: "instant_cell_text"
    }, [_vm._v(" " + _vm._s((_instant$title = instant.title) === null || _instant$title === void 0 ? void 0 : _instant$title.split(" (")[0]) + " ")]), _c('div', {
      staticClass: "num_wrap"
    }, [_c('div', {
      staticClass: "num",
      class: {
        surprise: _vm.isNewData,
        surprise1: !_vm.isNewData
      }
    }, [_vm._v(" " + _vm._s(instant.value) + _vm._s(_vm.valueSuffix(instant.value, instant.title)) + " ")]), _c('div', {
      staticClass: "unit"
    }, [_vm._v(" " + _vm._s(_vm.unitText(instant.value, instant.title)) + " ")])])])]);
  })], 2)])]), _c('div', {
    staticClass: "borderColor mb-3"
  }, [_c('GmapMap', {
    staticStyle: {
      "height": "300px"
    },
    attrs: {
      "center": _vm.mapCenter,
      "zoom": 9,
      "options": {
        scrollwheel: false
      }
    }
  }, [_c('GmapMarker', {
    attrs: {
      "clickable": false,
      "position": _vm.mapCenter,
      "draggable": false
    }
  }), _c('InfoWindow', {
    attrs: {
      "position": _vm.mapCenter,
      "opened": true,
      "draggable": true,
      "options": {
        content: _vm.infoWindowContent,
        pixelOffset: {
          height: -40,
          width: 0
        }
      }
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "site-instant-modal",
      "size": "lg",
      "title": "案場完整資訊",
      "ok-only": ""
    }
  }, [_c('b-table', {
    staticClass: "b-table-custom",
    attrs: {
      "bordered": "",
      "hover": "",
      "responsive": "",
      "items": _vm.modalItems
    },
    scopedSlots: _vm._u([{
      key: "cell(value)",
      fn: function fn(data) {
        return [data.item.name.indexOf("IP CAM") > -1 && data.item.value !== '-' ? _c('a', {
          attrs: {
            "target": "_blank",
            "href": data.value
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")]) : data.value === 'Link' ? _c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": _vm.gotoScreen
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")]) : _c('div', [_vm._v(_vm._s(data.value))])];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "key-values"
  }, [_c('div', {
    staticClass: "keys line"
  }, [_vm._v("客服 Line")]), _c('div', {
    staticClass: "values text-center"
  }, [_c('img', {
    staticClass: "p-1 p-sm-2 mr-1 line_img",
    attrs: {
      "src": require("../../assets/line_qrCode.png"),
      "alt": "line QR code",
      "width": "120"
    }
  })])]);
}];
export { render, staticRenderFns };