export const projectBatteryRoutes = [
  {
    path: 'overview',
    name: 'BatteryOverview',
    component: () => import('@/views/projectBattery/overview/index.vue'),
    meta: {
      release: false,
      zhTw: '總覽',
      icon: 'fas fa-comment-dots'
    }
  },
  {
    path: 'management',
    name: 'BatteryManagement',
    component: () => import('@/views/projectBattery/management/index.vue'),
    meta: {
      release: false,
      zhTw: '儲能管理',
      icon: 'fas fa-cog'
    }
  }
]
