var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "download",
    attrs: {
      "title": "下載"
    },
    on: {
      "click": _vm.downloadC
    }
  }, [_c('i', {
    staticClass: "fas fa-download"
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };