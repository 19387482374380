var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "borderColor mb-3"
  }, [_c('div', {
    staticClass: "text-center d-flex flex-column font-weight-bold titleBackground"
  }, [_c('div', {
    staticClass: "highcharts-title p-3"
  }, [_vm._v(" " + _vm._s(_vm.$t("deviceStatus.deviceLiveInformation")) + " ")])]), _c('Highcharts', {
    attrs: {
      "options": _vm.deviceStatusChartOptions
    }
  })], 1), _c('div', {
    staticClass: "borderColor"
  }, [_c('div', {
    staticClass: "text-center d-flex flex-column titleBackground"
  }, [_c('div', {
    staticClass: "highcharts-title mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("deviceStatus.invLiveInformation")) + " ")]), _c('div', {
    staticClass: "highcharts-title"
  }, [_vm._v(_vm._s(_vm.dataTime))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm._v(" 顯示筆數: "), _c('b-form-select', {
    staticClass: "ml-2 mr-3",
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('b-form-checkbox', {
    staticClass: "text-dark",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isOnlyPV,
      callback: function callback($$v) {
        _vm.isOnlyPV = $$v;
      },
      expression: "isOnlyPV"
    }
  }, [_vm._v(" 僅顯示 PV ")]), _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dataAnalysis.download")) + " ")])], 1)]), _c('b-table', {
    staticClass: "tableStyle bTableSticky",
    attrs: {
      "items": _vm.tableData,
      "fields": _vm.tableField,
      "hover": "",
      "head-variant": _vm.tableThColor,
      "dark": _vm.dark,
      "responsive": true,
      "sort-by": _vm.sortBy,
      "sort-icon-left": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "tbody-transition-props": _vm.transProps,
      "primary-key": "INV 名稱",
      "sticky-header": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(連線狀態)",
      fn: function fn(data) {
        return [data.value === 0 ? _c('i', {
          class: 'fas fa-check-circle',
          staticStyle: {
            "color": "var(--success)"
          }
        }) : _c('i', {
          class: 'fas fa-times-circle',
          staticStyle: {
            "color": "var(--purple)"
          }
        })];
      }
    }])
  })], 1), _c('b-pagination', {
    staticClass: "mt-2 mb-4",
    attrs: {
      "per-page": _vm.perPage,
      "total-rows": _vm.rows
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };