import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm$sortedTableData, _vm$sortedTableData2;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("KPI 每月報告")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2"
  }, [_c('div', {
    staticClass: "ml-2 mr-4 my-2"
  }, [_vm._v(" 日期: "), _c('date-picker', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM",
      "type": "month",
      "placeholder": "請選擇日期",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.selectedYearMonth,
      callback: function callback($$v) {
        _vm.selectedYearMonth = $$v;
      },
      expression: "selectedYearMonth"
    }
  })], 1), _c('button', {
    staticClass: "confirmButton p-1",
    class: {
      disableButton: _vm.isLoadingData || !_vm.selectedYearMonth
    },
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.handleSearchKpi
    }
  }, [_vm._v(" 查詢 ")]), _c('button', {
    staticClass: "confirmButton ml-auto mr-2 p-1",
    class: {
      disableButton: _vm.isDownloadingData || !((_vm$sortedTableData = _vm.sortedTableData) !== null && _vm$sortedTableData !== void 0 && _vm$sortedTableData.length)
    },
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.downloadExcel
    }
  }, [_vm._v(" 下載 ")])]), _c('b-overlay', {
    staticClass: "mx-2",
    attrs: {
      "show": _vm.isLoadingData,
      "spinner-variant": "secondary",
      "variant": "white",
      "z-index": "60"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap relative m-2"
  }, [_c('b-table-simple', {
    staticClass: "tableStyle summaryTable",
    attrs: {
      "bordered": "",
      "small": "",
      "responsive": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("符合該年月份計算的案場數")]), _c('b-th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("目標值 案場數合格狀況")]), _c('b-th', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("保證值 案場數合格狀況")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.summaryData.searchedYear) + " 年 ")]), _c('b-th', {
    staticClass: "align-middle"
  }, [_vm._v(" " + _vm._s(_vm.summaryData.searchedMonth) + " 月 ")]), _c('b-th', {
    staticClass: "c-blue"
  }, [_c('span', [_vm._v("符合計算的"), _c('br'), _vm._v("案場數")])]), _c('b-th', {
    staticClass: "c-orange"
  }, [_c('span', [_vm._v("目標值達標的"), _c('br'), _vm._v("案場數")])]), _c('b-th', [_c('span', {
    staticClass: "c-orange"
  }, [_vm._v("達標案場數")]), _vm._v(" /"), _c('br'), _c('span', {
    staticClass: "c-blue"
  }, [_vm._v("符合計算案場數")]), _vm._v(" (%) ")]), _c('b-th', {
    staticClass: "c-blue"
  }, [_c('span', [_vm._v("符合計算的"), _c('br'), _vm._v("案場數")])]), _c('b-th', {
    staticClass: "c-green"
  }, [_c('span', [_vm._v("保證值達標的"), _c('br'), _vm._v("案場數")])]), _c('b-th', [_c('span', {
    staticClass: "c-green"
  }, [_vm._v("達標案場數")]), _vm._v(" /"), _c('br'), _c('span', {
    staticClass: "c-blue"
  }, [_vm._v("符合計算案場數")]), _vm._v(" (%) ")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-th', {
    staticClass: "align-middle",
    attrs: {
      "sticky-column": "",
      "rowspan": "3"
    }
  }, [_vm._v(" 合格率統計項目 ")]), _c('b-th', {
    attrs: {
      "sticky-column": ""
    }
  }, [_vm._v("PR 值 狀況統計")]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRAchievedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRAchievedQualifiedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRAchievementCountRatio))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRGuaranteeCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRGuaranteeQualifiedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.PRGuaranteeCountRatio))])], 1), _c('b-tr', [_c('b-th', {
    attrs: {
      "sticky-column": ""
    }
  }, [_vm._v("發電量 狀況統計")]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerAchievedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerAchievedQualifiedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerAchievementCountRatio))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerGuaranteeCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerGuaranteeQualifiedCount))]), _c('b-td', [_vm._v(_vm._s(_vm.summaryData.powerGuaranteeCountRatio))])], 1)], 1)], 1), _c('b-table', {
    staticClass: "tableStyle dataTable",
    style: {
      minHeight: !((_vm$sortedTableData2 = _vm.sortedTableData) !== null && _vm$sortedTableData2 !== void 0 && _vm$sortedTableData2.length) ? 'min-content' : '425px'
    },
    attrs: {
      "hover": "",
      "small": "",
      "items": _vm.sortedTableData,
      "fields": _vm.tableFields,
      "responsive": true,
      "current-page": _vm.currentPage,
      "per-page": _vm.perPage,
      "bordered": "",
      "sort-icon-left": "",
      "no-sort-reset": ""
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function fn(_data) {
        return [_c('b-tr', [_c('b-th', {
          attrs: {
            "colspan": "8"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "mx-1"
        }, [_vm._v("每頁顯示")]), _c('b-form-select', {
          staticClass: "mx-2",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "size": "sm",
            "options": _vm.pageOptions
          },
          model: {
            value: _vm.perPage,
            callback: function callback($$v) {
              _vm.perPage = $$v;
            },
            expression: "perPage"
          }
        }), _c('span', {
          staticClass: "mx-2"
        }, [_vm._v(" 筆 ， 共 "), _c('span', {
          staticClass: "mx-3"
        }, [_vm._v(_vm._s(_vm.tableDataNum))]), _vm._v(" 筆 ")])], 1)]), _c('b-th', {
          staticClass: "align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(" PR 值 達標狀況 (%) ")]), _c('b-th', {
          staticClass: "align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(" 發電量 達標狀況 (%) ")])], 1)];
      }
    }, {
      key: "head()",
      fn: function fn(data) {
        return [data.label === '實際值 / 目標值' ? _c('div', [_c('span', {
          staticClass: "c-blue"
        }, [_vm._v("實際值")]), _vm._v(" / "), _c('span', {
          staticClass: "c-orange"
        }, [_vm._v("目標值")])]) : data.label === '實際值 / 保證值' ? _c('div', [_c('span', {
          staticClass: "c-blue"
        }, [_vm._v("實際值")]), _vm._v(" / "), _c('span', {
          staticClass: "c-green"
        }, [_vm._v("保證值")])]) : data.label.includes('目標') ? _c('div', {
          staticClass: "c-orange"
        }, [_vm._v(" " + _vm._s(data.label) + " ")]) : data.label.includes('實際') ? _c('div', {
          staticClass: "c-blue"
        }, [_vm._v(" " + _vm._s(data.label) + " ")]) : data.label.includes('保證') ? _c('div', {
          staticClass: "c-green"
        }, [_vm._v(" " + _vm._s(data.label) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell()",
      fn: function fn(data) {
        return [['prAchieveGoal', 'prAchieveGuarantee', 'powerAchieveGoal', 'powerAchieveGuarantee'].includes(data.field.key) ? _c('span', {
          class: {
            'text-danger': _vm.isNumber(data.value) && data.value < 100,
            'c-green': _vm.isNumber(data.value) && data.value >= 100
          }
        }, [_vm._v(" " + _vm._s(_vm.getNumberOrDash(data.value)) + " ")]) : ['factoryName', 'capacity'].includes(data.field.key) ? _c('span', [_vm._v(" " + _vm._s(data.value) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.getNumberOrDash(data.value)))])];
      }
    }])
  }), _c('b-pagination', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableDataNum > _vm.perPage,
      expression: "tableDataNum > perPage"
    }],
    staticClass: "mb-1",
    attrs: {
      "per-page": _vm.perPage,
      "total-rows": _vm.tableDataNum
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };