import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('v-app', [!['login', 'tvscreen', 'tvwalllooping'].includes(_vm.currentRouteName) ? _c('b-navbar', {
    staticClass: "navbar py-1",
    attrs: {
      "toggleable": "lg"
    }
  }, [_c('b-navbar-brand', {
    staticClass: "d-flex align-items-center py-0",
    attrs: {
      "to": _vm.logoLink
    }
  }, [_c('img', {
    staticClass: "logo mr-2",
    staticStyle: {
      "height": "36px"
    },
    attrs: {
      "src": require("./assets/acme-logo.png"),
      "alt": "logo"
    }
  }), _c('transition', {
    attrs: {
      "name": "fade-slide"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isChangingProject,
      expression: "!isChangingProject"
    }],
    staticClass: "font-weight-bold",
    staticStyle: {
      "color": "var(--acmeLogo)"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectName) + " ")])])], 1), _c('b-navbar-toggle', {
    staticClass: "border-0",
    attrs: {
      "target": "nav-collapse"
    }
  }), _c('b-collapse', {
    attrs: {
      "id": "nav-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end flex-wrap mt-lg-0"
  }, [_vm._l(_vm.managementButtonList, function (item) {
    return _c('b-button', {
      key: item.label,
      staticClass: "mx-2",
      attrs: {
        "size": "sm",
        "pill": "",
        "variant": item.label === _vm.projectName ? 'dark' : 'outline-dark'
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeProject(item.label);
        }
      }
    }, [_c('i', {
      staticClass: "mr-1",
      class: item.icon
    }), _vm._v(" " + _vm._s(item.label) + " ")]);
  }), _c('b-button', {
    staticClass: "text-decoration-none text-dark btn_navbar mx-1",
    attrs: {
      "variant": "link",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        _vm.changeMode ? _vm.setLightMode() : _vm.setDarkMode();
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-adjust"
  })]), _c('div', {
    staticClass: "position-relative",
    on: {
      "mouseleave": _vm.mouseleave
    }
  }, [_c('b-button', {
    staticClass: "text-decoration-none text-dark btn_navbar mx-1",
    attrs: {
      "variant": "link",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        _vm.langSelection = !_vm.langSelection;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-globe-asia"
  })]), _c('transition', [_vm.langSelection ? _c('div', {
    staticClass: "dropdown"
  }, [_c('div', {
    staticClass: "dropdown_link cursor-pointer",
    class: {
      dropdown_link__active: _vm.langSelected === 'tw'
    },
    on: {
      "click": function click($event) {
        return _vm.chooseLanguage('tw');
      }
    }
  }, [_vm._v(" 繁體中文 ")]), _c('div', {
    staticClass: "dropdown_link cursor-pointer",
    class: {
      dropdown_link__active: _vm.langSelected === 'en'
    },
    on: {
      "click": function click($event) {
        return _vm.chooseLanguage('en');
      }
    }
  }, [_vm._v(" English ")])]) : _vm._e()])], 1), _c('div', {
    staticClass: "position-relative",
    on: {
      "mouseleave": _vm.mouseleave
    }
  }, [_c('b-button', {
    staticClass: "text-decoration-none text-dark btn_navbar mx-1",
    attrs: {
      "variant": "link",
      "pill": ""
    },
    on: {
      "click": function click($event) {
        _vm.factorySelection = !_vm.factorySelection;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-bars"
  })]), _c('transition', [_vm.factorySelection ? _c('div', {
    staticClass: "dropdown"
  }, [_vm._l(_vm.menuLinkList, function (item) {
    return _c('router-link', {
      key: item.name,
      staticClass: "dropdown_link",
      class: {
        dropdown_link__active: _vm.currentRouteName === item.name || _vm.isRealtimeSiteInfoActive(item.name)
      },
      attrs: {
        "to": {
          name: item.name,
          params: {
            lang: _vm.lang
          }
        }
      }
    }, [_c('i', {
      class: item.icon
    }), _vm._v(_vm._s(item.zhTw) + " ")]);
  }), _vm.hasRoleAdmin && _vm.projectName.includes('雲端監控中心') ? _c('a', {
    staticClass: "dropdown_link",
    attrs: {
      "href": "https://factoryinfo.azurewebsites.net",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "fas fa-file-upload"
  }), _vm._v("案場資訊輸入 ")]) : _vm._e()], 2) : _vm._e()])], 1), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: '點擊登出',
      expression: "'點擊登出'",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "text-decoration-none text-dark btn_logout",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.signOut
    }
  }, [_c('i', {
    staticClass: "fa fa-user",
    attrs: {
      "aria-hidden": "true"
    }
  }), _c('span', {
    staticClass: "ml-1 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.userDetail.displayName || "使用者") + " ")])])], 2)])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "view_wrapper"
  }, [!['login', 'tvscreen', 'tvwalllooping'].includes(_vm.currentRouteName) ? _c('div', {
    staticClass: "sidebar d-none d-lg-block",
    class: {
      sidebar__expanded: _vm.isSideMenuExpanded
    }
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('i', {
    staticClass: "fas fa-exchange-alt btn_sidebar_expand",
    on: {
      "click": function click($event) {
        _vm.isSideMenuExpanded = !_vm.isSideMenuExpanded;
      }
    }
  })]), _c('ul', {
    staticClass: "sidebar_ul"
  }, [_vm._l(_vm.menuLinkList, function (item) {
    return _c('li', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.window.hover.right",
        value: item.zhTw,
        expression: "item.zhTw",
        modifiers: {
          "window": true,
          "hover": true,
          "right": true
        }
      }],
      key: item.name,
      staticClass: "sidebar_li",
      class: {
        sidebar_li__active: _vm.currentRouteName === item.name || _vm.isRealtimeSiteInfoActive(item.name)
      }
    }, [_c('router-link', {
      staticClass: "sidebar_link",
      attrs: {
        "to": {
          name: item.name,
          params: {
            lang: _vm.lang
          }
        }
      }
    }, [_c('i', {
      staticClass: "sidebar_icon",
      class: item.icon
    }), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isSideMenuExpanded,
        expression: "isSideMenuExpanded"
      }],
      staticClass: "sidebar_text"
    }, [_vm._v(" " + _vm._s(item.zhTw) + " ")])])], 1);
  }), _vm.hasRoleAdmin && _vm.projectName.includes('雲端監控中心') ? _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.window.hover.right",
      value: '案場資訊輸入',
      expression: "'案場資訊輸入'",
      modifiers: {
        "window": true,
        "hover": true,
        "right": true
      }
    }],
    staticClass: "sidebar_li"
  }, [_c('a', {
    staticClass: "sidebar_link",
    attrs: {
      "href": "https://factoryinfo.azurewebsites.net",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "fas fa-file-upload sidebar_icon"
  }), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isSideMenuExpanded,
      expression: "isSideMenuExpanded"
    }],
    staticClass: "sidebar_text"
  }, [_vm._v("案場資訊輸入")])])]) : _vm._e()], 2)]) : _vm._e(), _vm.isLoggedIn ? _c('keep-alive', [_vm.$route.meta.keepAlive ? _c('router-view', {
    staticClass: "router-view"
  }) : _vm._e()], 1) : _vm._e(), !_vm.$route.meta.keepAlive || !_vm.isLoggedIn ? _c('router-view', {
    staticClass: "router-view"
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };