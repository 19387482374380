var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "6"
    }
  }, [_c('Highcharts', {
    attrs: {
      "options": _vm.options
    }
  })], 1), _c('b-col', {
    attrs: {
      "xs": "6"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": ['name', 'age', 'gender']
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };