export const projectEnergyRoutes = [
  {
    path: 'overview',
    name: 'EnergyOverview',
    component: () => import('@/views/projectEnergy/overview/index.vue'),
    meta: {
      release: false,
      zhTw: '總覽',
      icon: 'fas fa-desktop'
    }
  },
  {
    path: 'management',
    name: 'EnergyManagement',
    component: () => import('@/views/projectEnergy/management/index.vue'),
    meta: {
      release: false,
      zhTw: '電能管理',
      icon: 'fas fa-cog'
    }
  }
]
