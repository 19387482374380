var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("跨案場分析")]), _c('div', {
    staticClass: "borderColor borderColorBackground mb-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-wrap m-2"
  }, [_c('button', {
    staticClass: "datepickerButton",
    class: {
      'date-active': _vm.dateTypeActive === 'hour'
    },
    on: {
      "click": function click($event) {
        return _vm.changeDateType('date', 'yyyy-MM-dd', 'hour');
      }
    }
  }, [_vm._v(" 時 ")]), _c('button', {
    staticClass: "datepickerButton",
    class: {
      'date-active': _vm.dateTypeActive === 'date'
    },
    on: {
      "click": function click($event) {
        return _vm.changeDateType('date', 'yyyy-MM-dd', 'date');
      }
    }
  }, [_vm._v(" 日 ")]), _c('button', {
    staticClass: "datepickerButton",
    class: {
      'date-active': _vm.dateTypeActive === 'month'
    },
    on: {
      "click": function click($event) {
        return _vm.changeDateType('month', 'yyyy-MM', 'month');
      }
    }
  }, [_vm._v(" 月 ")]), _c('button', {
    staticClass: "datepickerButton mr-4",
    class: {
      'date-active': _vm.dateTypeActive === 'year'
    },
    on: {
      "click": function click($event) {
        return _vm.changeDateType('year', 'yyyy', 'year');
      }
    }
  }, [_vm._v(" 年 ")]), _c('div', {
    staticClass: "my-2 mr-4"
  }, [_vm._v(" 起始時間 :  "), _c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "placeholder": "起始時間",
      "type": _vm.dateType,
      "valueType": "format",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.beginDate,
      callback: function callback($$v) {
        _vm.beginDate = $$v;
      },
      expression: "beginDate"
    }
  })], 1), _vm.dateTypeActive !== 'hour' ? _c('div', {
    staticClass: "my-2 mr-4"
  }, [_vm._v(" 結束時間 :  "), _c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "placeholder": "結束時間",
      "type": _vm.dateType,
      "valueType": "format",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  })], 1) : _vm._e(), _c('button', {
    staticClass: "datepickerButton",
    on: {
      "click": _vm.multipleDataPush
    }
  }, [_vm._v(" 新增一列 ")])]), _c('div', {
    staticClass: "otherTableStyle bg-white"
  }, [_c('table', {
    staticClass: "w-100 text-center"
  }, [_vm._m(0), _vm._l(_vm.multipleDataArr, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(idx + 1))]), _c('td', [_c('b-form-select', {
      staticClass: "m-5px w-auto",
      attrs: {
        "size": "sm"
      },
      on: {
        "change": function change($event) {
          i.factory = '';
        }
      },
      model: {
        value: i.county,
        callback: function callback($$v) {
          _vm.$set(i, "county", $$v);
        },
        expression: "i.county"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": "null"
      }
    }, [_vm._v("縣市")]), _vm._l(_vm.countyArr, function (x) {
      return _c('b-form-select-option', {
        key: x,
        attrs: {
          "value": x
        }
      }, [_vm._v(" " + _vm._s(x) + " ")]);
    })], 2), _c('b-form-select', {
      staticClass: "m-5px w-auto",
      attrs: {
        "size": "sm"
      },
      on: {
        "change": function change($event) {
          i.factory = '';
        }
      },
      model: {
        value: i.factory,
        callback: function callback($$v) {
          _vm.$set(i, "factory", $$v);
        },
        expression: "i.factory"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("案場")]), _vm._l(_vm.filterFactoryArr(i.county), function (x, xidx) {
      return _c('b-form-select-option', {
        key: xidx,
        attrs: {
          "value": x.factory
        }
      }, [_vm._v(" " + _vm._s(x.factory) + " ")]);
    })], 2)], 1), _c('td', [_c('b-form-input', {
      staticClass: "mx-auto",
      staticStyle: {
        "width": "60px"
      },
      attrs: {
        "type": "color",
        "size": "sm"
      },
      model: {
        value: i.color,
        callback: function callback($$v) {
          _vm.$set(i, "color", $$v);
        },
        expression: "i.color"
      }
    })], 1), _c('td', [_c('button', {
      staticClass: "deleteButton m-5px",
      on: {
        "click": function click($event) {
          return _vm.multipleDataSplice(idx);
        }
      }
    }, [_vm._v(" 刪除 ")])])]);
  })], 2)]), _c('div', {
    staticClass: "bg-white text-center"
  }, [_c('button', {
    staticClass: "confirmButton m-2",
    on: {
      "click": _vm.confirmData
    }
  }, [_vm._v("確認")])])]), _c('div', {
    staticClass: "borderColor borderColorBackground mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-3"
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.contextArr,
      "switches": ""
    },
    model: {
      value: _vm.contextDataClass,
      callback: function callback($$v) {
        _vm.contextDataClass = $$v;
      },
      expression: "contextDataClass"
    }
  }), _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" 資料下載 ")])], 1), _c('highcharts', {
    ref: "chart",
    staticClass: "bg-white pt-3",
    attrs: {
      "options": _vm.multipleChart
    }
  })], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', [_c('th', [_vm._v("序號")]), _c('th', [_vm._v("案場")]), _c('th', [_vm._v("曲線顏色")]), _c('th', [_vm._v("功能")])]);
}];
export { render, staticRenderFns };