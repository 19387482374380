var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "flexbox"
  }, [_vm._m(0), _c('transition', [_c('div', {
    staticClass: "main",
    class: {
      animation: !_vm.animation && _vm.animationON,
      reanimation: _vm.animation && _vm.animationON
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header_title"
  }, [_vm._v(" " + _vm._s(this.$route.query.demo === "true" ? this.$route.query.siteName : _vm.getSiteAliasName(_vm.sliderInfo.site)) + " "), _c('div', {
    staticClass: "header_text_wrap d-flex align-items-center"
  }, [_c('div', {
    staticClass: "text-nowrap"
  }, [_vm._v(" 設置容量"), _c('br'), _c('span', {
    staticClass: "text_translate"
  }, [_vm._v("Capacity")])]), _c('div', [_vm._v("：")]), _c('div', {
    staticClass: "text-nowrap"
  }, [_c('span', {
    staticClass: "header_text_num"
  }, [_vm._v(_vm._s(_vm.dataHandle(_vm.sliderInfo.capacity)))]), _vm._v(" kWp ")])])])]), _c('div', {
    staticClass: "connectStyle"
  }, [_c('span', [_vm._v(_vm._s(_vm.sliderInfo.msg))])]), _c('div', {
    staticClass: "s_info"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "text_title"
  }, [_vm._v("案場畫面")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Photo(s)")])])]), _vm.imglist ? _c('div', {
    staticClass: "imgbox"
  }, [_c('Slider', {
    attrs: {
      "animation": "fade",
      "height": "100%",
      "duration": 1500,
      "speed": 5000,
      "control-btn": this.imglist.length === 1 ? false : true,
      "indicators": this.imglist.length === 1 ? false : 'center'
    },
    model: {
      value: _vm.sliderValue,
      callback: function callback($$v) {
        _vm.sliderValue = $$v;
      },
      expression: "sliderValue"
    }
  }, _vm._l(_vm.imglist, function (i, index) {
    return _c('SliderItem', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.changeIndex(1);
        }
      }
    }, [_c('img', {
      staticStyle: {
        "height": "100%",
        "width": "100%"
      },
      attrs: {
        "src": i.imgsrc
      }
    })]);
  }), 1)], 1) : _vm.photoload ? _c('div', {
    staticClass: "imgbox center"
  }, [_c('h3', [_vm._v("尚未上傳照片，請聯絡管理人員")])]) : _vm._e()]), _c('div', {
    staticClass: "right"
  }, [_c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-bolt",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 今日發電量")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Power Generation Today")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.eToday)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kWh")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-bolt",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 發電總量")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Total Power Generation")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.acKwh)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kWh")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-bolt",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 本月發電量")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Power Generation this Month")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.acKwhThisMon)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kWh")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-signal",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 峰瓩值")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Peak Value")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.peakWatt)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kWh/kWp")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-chart-line",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" PR 值")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Performance Ratio")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.PR * 100, 1)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("%")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-sun",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 日照強度")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Intensity of Sunlight")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.handleIrr(_vm.sliderInfo.IRR) ? _vm.dataHandle(_vm.sliderInfo.IRR * 1000) : "null") + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("W/m²")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-grip-lines",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 直流功率")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("   DC Power")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.dcp)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kW")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-bacon",
    staticStyle: {
      "color": "yellow"
    }
  }), _c('span', [_vm._v(" 交流功率")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("   AC Power")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.acp)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("kW")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    staticClass: "s_title d-flex flex-column align-items-center justify-content-center",
    style: {
      height: _vm.moduleTempTitleHeight + 'px'
    }
  }, [_c('div', [_c('i', {
    staticClass: "fas fa-thermometer-half",
    staticStyle: {
      "color": "red"
    }
  }), _c('span', [_vm._v(" 模組溫度")])]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v("Module Temperature")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.moduleTemp)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("°C")])])])]), _c('div', {
    staticClass: "infobox"
  }, [_c('div', {
    ref: "refCo2Title",
    staticClass: "s_title"
  }, [_c('i', {
    staticClass: "fas fa-leaf",
    staticStyle: {
      "color": "green"
    }
  }), _c('span', [_vm._v(" 累積 CO2 減排量")]), _c('div', {
    staticClass: "text_translate"
  }, [_vm._v(" Cumulative CO2 Emissions Reduction ")])]), _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.sliderInfo.CO2)) + " "), _c('span', {
    staticClass: "unit"
  }, [_c('p', [_vm._v("ton")])])])]), _c('div', {
    staticClass: "update"
  }, [_vm._v(" 最後更新時間 Last Updated : "), _c('span', {
    staticStyle: {
      "color": "#ff0"
    }
  }, [_vm._v(" " + _vm._s(_vm.sliderInfo.time))])])])]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "bottomText"
  }, [_vm._v(" 太陽能系統啟動時間 "), _c('span', {
    staticClass: "text_translate"
  }, [_vm._v("Solar System Startup Time")]), _vm._v("：" + _vm._s(_vm.sliderInfo.systemTime) + " ")])])])]), !_vm.singleFactory ? _c('i', {
    staticClass: "leftButton fas fa-chevron-circle-left",
    staticStyle: {
      "font-size": "45px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.sliderCount('minus');
      }
    }
  }) : _vm._e(), !_vm.singleFactory ? _c('i', {
    staticClass: "rightButton fas fa-chevron-circle-right",
    staticStyle: {
      "font-size": "45px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.sliderCount('plus');
      }
    }
  }) : _vm._e(), !_vm.singleFactory ? _c('a', {
    staticClass: "lock",
    on: {
      "click": _vm.lockSlider
    }
  }, [_vm._v(_vm._s(_vm.lockText))]) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bg"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/bg2.jpg")
    }
  })]);
}];
export { render, staticRenderFns };