// import { BButton } from 'bootstrap-vue'
// import { VBtn } from 'vuetify/lib'
// import CButton from './CButton.vue'

export default {
  name: 'BatteryOverview',
  components: {
    // VBtn
    // CButton
    // BButton
  },
  data: function data() {
    return {
      title: '總覽',
      checkbox: false
    };
  }
};