import datePicker from 'vuejs-datepicker';
export default {
  name: 'QueryHistoryData',
  components: {
    datePicker: datePicker
  },
  data: function data() {
    return {
      chartOptions: {
        series: [{
          data: [1, 2, 3]
        }]
      },
      format: 'yyyy-MM-dd',
      selectedDate: null
    };
  },
  computed: {
    formatDateObj: function formatDateObj() {
      return this.selectedDate.getDate(); // 轉換成日期
    }
  }
};