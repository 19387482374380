var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('div', {
    staticClass: "date-selection mt-3"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.$t("queryhistorydata.selectTime")) + ":")]), _c('datePicker', {
    attrs: {
      "format": _vm.format
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1)]), _c('highcharts', {
    staticClass: "mt-5",
    attrs: {
      "options": _vm.chartOptions
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };