import axios from 'axios'

axios.defaults.baseURL = '/api/cosmos'
// const api = '/api/cosmos'

const Api = {
  // auth
  Login: '/auth/login',
  Logout: '/auth/logout',

  // realtime page
  GetFactoriesInfo: '/factoriesinfo/getFactoriesInfo',
  GetAllAlarms: '/factories/getAllAlarms',
  GetFactoryError: '/realtime/factoryError',
  GetLastSpecificDocuments: '/factories/getLastSpecificDocuments',

  // foreign factory list
  GetOtherSystemFactories: '/factories/getOtherSystemFactories',

  // factories
  GetLastDocument: '/factories/getLastDocument',

  // siteinfo - siteinfodetail page
  GetRecentThirtyDays: '/siteinfo/getRecentThirtyDays',
  GetHours: '/siteinfo/getHours',
  GetDays: '/siteinfo/getDays',
  GetMonth: '/siteinfo/getMonth',
  GetYear: '/siteinfo/getYear',
  GetInvs: '/siteinfo/getInvs',

  // tv screen page
  GetLinkGroup: '/siteinfo/getLinkGroup',
  GetLinkData: '/siteinfo/getLinkData',
  GetLinkBlob: '/siteinfo/getLinkBlob',

  // siteinfo - devicehistory page
  GetDeviceHistory: '/devicehistory/getDeviceHistory',

  // siteinfo - dataanalysis page
  GetIntervalAnalysis: '/dataanalysis/getIntervalAnalysis',
  GetAllAnalysisData: '/dataanalysis/getAllAnalysisData',
  GetAnalysisAvailability: '/dataanalysis/getavailability',
  GetAvailabilityRemark: '/dataanalysis/getavailabilityremark',
  UpdateAvailabilityRemark: '/dataanalysis/setavailabilityremark',

  // siteinfo - mpptanalysis page
  GetMpptLen: '/multplemppt/getmpptlen',
  GetMpptData: '/multplemppt/getmpptdata',

  // siteinfo - dataexcel page
  CreateKpiTable: '/dataanalysis/createkpitable',
  GetKpiTable: '/dataanalysis/getkpitable',
  GetPdf: '/siteinfo/getpdf',
  GetCsv: '/siteinfo/getcsv',
  UpdateCsv: '/siteinfo/updatecsv',
  DeletePdf: '/siteinfo/deletepdf',

  // siteinfo - investmentanalysis page
  GetPowerAmount: '/investment/poweramount',
  GetPowerRevenue: '/investment/powerRevenue',
  GetAvailability: '/investment/availability',
  GetPerformantRate: '/investment/performantRate',
  GetBills: '/investment/getbills',
  GetWriteBills: '/investment/writebills',
  GetDataRetention: '/investment/dataretention',

  // siteinfo - monthlyreport page
  // month
  GetMonSumTable: '/monthreport/monsumtable',
  GetDaysTable: '/monthreport/daystable',
  GetDaysChart: '/monthreport/dayschart',
  // year
  GetYearSumTable: '/yearreport/yearsumtable',
  GetMonthsTable: '/yearreport/montable',
  GetMonthsChart: '/yearreport/monchart',

  // factoryStatus page
  GetRecentAlarms: '/devicestatus/recentalarms',
  UpdateDeviceConfig: '/devicestatus/updatedeviceconfig',
  GetLastEtcData: '/devicestatus/getlastetcdata',

  // historyalarms page
  GetHistoryAlarms: '/devicestatus/historyalarms',

  // usermanage page
  GetAllUsers: '/usermanage/getallusers',
  CreateUser: '/usermanage/createuser?operator=',
  UpdateAccount: '/usermanage/updateaccount?operator=',
  DeleteUser: '/usermanage/deleteuser?operator=',

  // groupmanage page
  GetAllGroups: '/groupmanage/getallgroups',
  CreateGroup: '/groupmanage/creategroup?operator=',
  UpdateGroup: '/groupmanage/updategroup?operator=',
  DeleteGroup: '/groupmanage/deletegroup?operator=',

  // emailsend page
  GetDailyReportList: '/reportresend/dailyreportlist',
  GetDailyReport: '/reportresend/getdailyreport',
  GetSendMails: '/reportresend/sendmails',

  // multiplefactoryanalysis page
  GetMultipleHourData: '/multplefactory/getmultiplehourdata',
  GetMultipleDayData: '/multplefactory/getmultipledaydata',
  GetMultipleMonthData: '/multplefactory/getmultiplemonthdata',
  GetMultipleYearData: '/multplefactory/getmultipleyeardata',

  // rawdatadownload page
  GetRawData: '/rawdata/getrawdata',

  // raanalysis page
  GetPvRa: '/pvra/get',

  // kpi month report page
  GetKpi: '/kpi/table',

  // 富強鑫展示頁面
  GetFuqiangxin: '/fcs/compose',

  // 案場排序
  GetSortedFactories: '/factories/getSortedFactories',
  UpdateSortedFactories: '/factories/writeSortedFactories',

  // 電視牆輪播
  Get14fSelected: '/siteinfo/selected14fData',
  Get14fData: '/siteinfo/14f'
}

// --------------------------------------------------------
// ------------------------- auth -------------------------
// --------------------------------------------------------
/**
 * login
 * @param {string} account 'test'
 * @param {string} password test
 * @returns data: { some user's information }, msg: 'logged in'
 */
export const loginApi = (account, password) =>
  axios.post(Api.Login, { account, password })

/**
 * logout
 * @returns msg: 'logged out'
 */
export const logoutApi = () => axios.post(Api.Logout)

// -------------------------------------------------------------
// ------------------------- realtime --------------------------
// -------------------------------------------------------------
/**
 * 案場名稱清單 取得 案場清單資訊 [ info ]
 * @param {string[]} factoriesList ['','']
 */
export const getFactoriesInfoApi = (factoriesList) =>
  axios.post(Api.GetFactoriesInfo, { factoriesList })

/**
 * 案場名稱清單 取得 所有案場狀態數量
 */
export const getAllAlarmsApi = () => axios.post(Api.GetAllAlarms)

/**
 * 案場告警
 * @param {string} startDate '2023-02-08'
 * @param {string} endDate '2023-02-08'
 * @param {string} factoryName 'all' | '鳳山圳滯洪池'
 */
export const getFactoryErrorApi = (startDate, endDate, factoryName) =>
  axios.post(Api.GetFactoryError, { startDate, endDate, factoryName })

/**
 * 案場資訊 [ info ] 取得 案場詳細資料 [ data ]
 * @param {Array<object>} factoriesInfoArray [{},{}]
 */
export const getLastSpecificDocumentsApi = (factoriesInfoArray) =>
  axios.post(Api.GetLastSpecificDocuments, factoriesInfoArray)

// -------------------------------------------------------------
// ------------------- foreign factories -----------------------
// -------------------------------------------------------------
/**
 * 取得 外來監控 list
 */
export const getOtherSystemFactoriesApi = () =>
  axios.post(Api.GetOtherSystemFactories)

// -------------------------------------------------------------
// ------------------------- factories -------------------------
// -------------------------------------------------------------
/**
 * 案場名稱 取得 案場詳細資料 [ data ]
 * @param {string} factoryName '鳳山圳滯洪池'
 */
export const getLastDocumentApi = (factoryName) =>
  axios.post(Api.GetLastDocument, { factoryName })

// -------------------------------------------------------------
// ---------------------- siteinfodetail -----------------------
// -------------------------------------------------------------
/**
 * 最近 30 天
 * @param {string} fId 'f001'
 */
export const getRecentThirtyDaysApi = (fId) =>
  axios.post(Api.GetRecentThirtyDays, { fId })
/**
 * 時歷史
 * @param {string} todayDate '2023-02-08'
 * @param {string} fId 'f001'
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getHoursApi = (todayDate, fId, siteName) =>
  axios.post(Api.GetHours, { todayDate, fId, siteName })
/**
 * 日歷史
 * @param {string} thisMon '2023-02'
 * @param {string} fId 'f001'
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getDaysApi = (thisMon, fId, siteName) =>
  axios.post(Api.GetDays, { thisMon, fId, siteName })
/**
 * 月歷史
 * @param {string} thisYear '2023'
 * @param {string} fId fId 'f001'
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getMonthApi = (thisYear, fId, siteName) =>
  axios.post(Api.GetMonth, { thisYear, fId, siteName })
/**
 * 年歷史
 * @param {string} fId fId 'f001'
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getYearApi = (fId, siteName) =>
  axios.post(Api.GetYear, { fId, siteName })
/**
 * 變流器歷史
 * @param {string} date '2023-02-08'
 * @param {string} invNum 6
 * @param {string} fId 'f001'
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getInvsApi = (date, invNum, fId, siteName) =>
  axios.post(Api.GetInvs, { date, invNum, fId, siteName })

// -------------------------------------------------------------
// ------------------------- tv screen -------------------------
// -------------------------------------------------------------
/**
 * tv screen 群組數
 * @param {string} linkGroupNumber '16'
 */
export const getLinkGroupApi = (linkGroupNumber) =>
  axios.post(Api.GetLinkGroup, { linkGroupNumber })

/**
 * tv screen 群組內之案場，各別取得資料
 * @param {string} factoryName '生利二期13區'
 * @param {string} id 'f142'
 * @param {string} thisMon '2023-02'
 */
export const getLinkDataApi = (factoryName, id, thisMon) =>
  axios.post(Api.GetLinkData, { factoryName, id, thisMon })

/**
 * tv screen 案場照片
 * @param {string} id 'f142'
 * @returns data: ['照片連結網址']
 */
export const getLinkBlobApi = (id) => axios.post(Api.GetLinkBlob, { id })

// -------------------------------------------------------------
// ------------------------ devicehistory ----------------------
// -------------------------------------------------------------
/**
 * 裝置歷史資料
 * @param {string} fId 'f001'
 * @param {string} factoryName '鳳山圳滯洪池'
 * @param {string} inv '0'
 * @param {string} startDate '2023-02-16'
 * @param {string} endDate '2023-02-17'
 */
export const getDeviceHistoryApi = (
  fId,
  factoryName,
  inv,
  startDate,
  endDate
) =>
  axios.post(Api.GetDeviceHistory, {
    fId,
    factoryName,
    inv,
    startDate,
    endDate
  })

// -------------------------------------------------------------
// ------------------------- dataanalysis ----------------------
// -------------------------------------------------------------
/**
 * 資料分析 analysis data 每 15 min
 * @param {string} fId 'f001'
 * @param {string} date '2023-02-08'
 */
export const getIntervalAnalysisApi = (fId, date) =>
  axios.post(Api.GetIntervalAnalysis, { fId, date })

/**
 * 資料分析 all analysis data
 * @param {string} siteName '鳳山圳滯洪池'
 * @param {string} fId 'f001'
 * @param {string} date '2023-02-08'
 */
export const getAllAnalysisDataApi = (siteName, fId, date) =>
  axios.post(Api.GetAllAnalysisData, { siteName, fId, date })

/**
 * 資料分析 availability data
 * @param {string} siteName '鳳山圳滯洪池'
 * @param {string} fId 'f001'
 * @param {string} date '2023-02-08'
 */
export const getAnalysisAvailabilityApi = (siteName, fId, date) =>
  axios.post(Api.GetAnalysisAvailability, { siteName, fId, date })

/**
 * 資料分析 取得 availability remarks
 * @param {string} fId 'f001'
 */
export const getAvailabilityRemarkApi = (fId) =>
  axios.post(Api.GetAvailabilityRemark, { fId })

/**
 * 資料分析 更新 availability remarks
 * @param {string} fId 'f001'
 * @param {string} remarks [['2024-02', 'xxxx 備註文字']]
 */
export const updateAvailabilityRemarkApi = (fId, remarks) =>
  axios.post(Api.UpdateAvailabilityRemark, { fId, remarks })

// -------------------------------------------------------------
// ------------------------- mpptanalysis ----------------------
// -------------------------------------------------------------

/**
 * MPPT 分析 mppt 數量
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getMpptLenApi = (siteName) =>
  axios.post(Api.GetMpptLen, { siteName })

/**
 * MPPT 分析 data
 * @param {string} siteName '鳳山圳滯洪池'
 * @param {string} beginTime '13:30'
 * @param {string} endTime '13:31'
 * @param {string} date '2023-02-15'
 * @param {string} fId 'f001'
 */
export const getMpptDataApi = (siteName, beginTime, endTime, date, fId) =>
  axios.post(Api.GetMpptData, { siteName, beginTime, endTime, date, fId })

// -------------------------------------------------------------
// -------------------------- dataexcel ------------------------
// -------------------------------------------------------------
/**
 * 設計資訊 - 串接資料、PVsyst
 * @param {string} fileName '鳳山圳滯洪池_串列資料.json'
 */
export const getCsvApi = (fileName) => axios.post(Api.GetCsv, { fileName })

/**
 * 設計資訊 - PVsyst
 * @param {string} fileName '鳳山圳滯洪池_PVSYS.json'
 * @param {string[]} csvHeader ['','',...]
 * @param {Array<object>} csvData [{},{},...]
 * @returns msg: 'OK'
 */
export const updateCsvApi = (fileName, csvHeader, csvData) =>
  axios.put(Api.UpdateCsv, { csvData: { fileName, csvHeader, csvData } })

/**
 * 設計資訊 - 檔案資料
 * @param {string} factoryId 'f001'
 */
export const getPdfApi = (factoryId) => axios.post(Api.GetPdf, { factoryId })

/**
 * 設計資訊 - 檔案資料 刪除
 * @param {string} factoryName '鳳山圳滯洪池'
 * @param {string} pdfName '鳳山圳滯洪池_系統單線圖-高雄市-鳳山圳(二期)-00-20200205.pdf'
 */
export const deletePdfApi = (factoryName, pdfName) =>
  axios.delete(Api.DeletePdf, { data: { factoryName, pdfName } })

/**
 * 設計資訊 - KPI 設定
 * @param {string} filename '鳳山圳滯洪池.json'
 */
export const getKpiTableApi = (filename) =>
  axios.post(Api.GetKpiTable, { filename })

/**
 * 設計資訊 - KPI 設定 新增
 * @param {string} filename '鳳山圳滯洪池.json'
 * @param {Array<object>} tables [{},{},...]
 */
export const createKpiTableApi = (filename, tables) =>
  axios.post(Api.CreateKpiTable, { filename, tables })

// -------------------------------------------------------------
// ---------------------- investmentanalysis -------------------
// -------------------------------------------------------------
/**
 * 投資分析 - 發電量
 * @param {string} fId 'f001'
 * @param {string} kpiFilename '鳳山圳滯洪池.json'
 * @param {string[]} months ['2022-05','2022-06', ...]
 */
export const getPowerAmountApi = (fId, kpiFilename, months) =>
  axios.post(Api.GetPowerAmount, { fId, kpiFilename, months })

/**
 * 投資分析 - 發電收入
 * @param {string} fId 'f001'
 * @param {string} kpiFilename '鳳山圳滯洪池.json'
 * @param {string[]} months ['2022-05','2022-06', ...]
 */
export const getPowerRevenueApi = (fId, kpiFilename, months) =>
  axios.post(Api.GetPowerRevenue, { fId, kpiFilename, months })

/**
 * 投資分析 - 妥善率
 * @param {string} fId 'f001'
 * @param {string} kpiFilename '鳳山圳滯洪池.json'
 * @param {string[]} months ['2022-05','2022-06', ...]
 */
export const getAvailabilityApi = (fId, kpiFilename, months) =>
  axios.post(Api.GetAvailability, { fId, kpiFilename, months })

/**
 * 投資分析 - PR 值
 * @param {string} fId 'f001'
 * @param {string} kpiFilename '鳳山圳滯洪池.json'
 * @param {string[]} months ['2022-05','2022-06', ...]
 */
export const getPerformantRateApi = (fId, kpiFilename, months) =>
  axios.post(Api.GetPerformantRate, { fId, kpiFilename, months })

/**
 * 投資分析 - 監控值與電費單比較
 * @param {string} fId 'f001'
 * @param {string} fName '鳳山圳滯洪池'
 */
export const getBillsApi = (fId, fName) =>
  axios.post(Api.GetBills, { fId, fName })

/**
 * 投資分析 - 監控值與電費單比較
 * @param {string} fName '鳳山圳滯洪池'
 * @param {Array<object>} data [{},{},...]
 */
export const getWriteBillsApi = (fName, data) =>
  axios.post(Api.GetWriteBills, { fName, data })

/**
 * 投資分析 - 資料妥善率
 * @param {string} fId 'f001'
 * @param {string[]} months ['2024-01', '2024-02', ...]
 */
export const getDataRetentionApi = (fId, months) =>
  axios.post(Api.GetDataRetention, { fId, months })

// -------------------------------------------------------------
// ----------------------- Monthlyreport -----------------------
// -------------------------------------------------------------
/**
 * 案場報表 (月)
 * @param {string} fId 'f001'
 * @param {string} date '2023-01'
 */
export const getMonSumTableApi = (fId, date) =>
  axios.post(Api.GetMonSumTable, { fId, date })

/**
 * 案場報表 (月)
 * @param {string} fId 'f001'
 * @param {string} date '2023-01'
 */
export const getDaysTableApi = (fId, date) =>
  axios.post(Api.GetDaysTable, { fId, date })

/**
 * 案場報表 (月)
 * @param {string} fId 'f001'
 * @param {string} date '2023-01'
 */
export const getDaysChartApi = (fId, date) =>
  axios.post(Api.GetDaysChart, { fId, date })
/**
 * 案場報表 (年)
 * @param {string} fId 'f001'
 * @param {string} date '2023'
 */
export const getYearSumTableApi = (fId, date) =>
  axios.post(Api.GetYearSumTable, { fId, date })

/**
 * 案場報表 (年)
 * @param {string} fId 'f001'
 * @param {string} date '2023'
 */
export const getMonthsTableApi = (fId, date) =>
  axios.post(Api.GetMonthsTable, { fId, date })

/**
 * 案場報表 (年)
 * @param {string} fId 'f001'
 * @param {string} date '2023'
 */
export const getMonthsChartApi = (fId, date) =>
  axios.post(Api.GetMonthsChart, { fId, date })

// -------------------------------------------------------------
// ----------------------- factoryStatus -----------------------
// -------------------------------------------------------------
/**
 * 案場即時狀態
 */
export const getRecentAlarmsApi = () => axios.post(Api.GetRecentAlarms)

/**
 * 案場裝置設定上傳
 * @param {string} factoryName '鳳山圳滯洪池'
 * @param {string} docId 'f001'
 * @param {object} deviceConfig {}
 */
export const updateDeviceConfigApi = (factoryName, docId, deviceConfig) =>
  axios.put(Api.UpdateDeviceConfig, {
    factoryName,
    docId,
    deviceConfig
  })

/**
 * 案場裝置狀態 電表資料
 * @param {string} siteName '鳳山圳滯洪池'
 */
export const getLastEtcDataApi = (factoryName) =>
  axios.post(Api.GetLastEtcData, { factoryName })

// -------------------------------------------------------------
// ----------------------- historyalarms -----------------------
// -------------------------------------------------------------
/**
 * 告警事件 案場歷史狀態
 */
export const getHistoryAlarmsApi = () => axios.post(Api.GetHistoryAlarms)

// -------------------------------------------------------------
// ------------------------- usermanage -----------------------
// -------------------------------------------------------------
/**
 * user 清單, 可看 users
 */
export const getAllUsersApi = () => axios.post(Api.GetAllUsers)

/**
 * 新增帳號
 * @param {string} operator ''
 * @param {string} account ''
 * @param {string} password ''
 * @param {string} displayName '稱呼'
 * @param {string} role 'Admin(權限)'
 * @param {string[]} factoriesList 可觀看案場 ['','']
 * @param {string[]} demoList 可觀看展示案場 ['','']
 * @param {string} applicant '創建者帳號'
 * @returns msg: 'user xxx created'
 */
export const createUserApi = (
  operator,
  account,
  password,
  displayName,
  role,
  factoriesList,
  demoList,
  applicant
) =>
  axios.post(Api.CreateUser + `${operator}`, {
    operator,
    account,
    password,
    displayName,
    role,
    factoriesList,
    demoList,
    applicant
  })

/**
 * 更新帳號
 * @param {string} operator ''
 * @param {string} id ''
 * @param {string} account ''
 * @param {string} password ''
 * @param {string} displayName '稱呼'
 * @param {string} role 'Admin(權限)'
 * @param {string[]} factoriesList 可觀看案場 ['','']
 * @param {string[]} demoList 可觀看展示案場 ['','']
 * @returns msg: 'OK'
 */
export const updateAccountApi = (
  operator,
  id,
  account,
  password,
  displayName,
  role,
  factoriesList,
  demoList
) =>
  axios.put(Api.UpdateAccount + `${operator}`, {
    operator,
    id,
    account,
    password,
    displayName,
    role,
    factoriesList,
    demoList
  })

/**
 * 刪除帳號
 * @param {string} operator 'userName'
 * @param {string} id 'id'
 * @param {string} account 'account'
 * @returns msg: 'xxx deleted'
 */
export const deleteUserApi = (operator, id, account) =>
  axios.delete(Api.DeleteUser + `${operator}`, { data: { id, account } })

// -------------------------------------------------------------
// ------------------------- groupmanage -----------------------
// -------------------------------------------------------------
/**
 * role 清單, role 可看 menu
 */
export const getAllGroupsApi = () => axios.post(Api.GetAllGroups)

/**
 * 新增群組
 * @param {string} operator 'userName'
 * @param {string} role 'groupName'
 * @param {string[]} sideMenu ['','']
 * @param {string[]} componentMenu ['','']
 * @param {string[]} othersMenu ['','']
 * @param {object[]} otherSites
 * {
 *    name: 'projectEnergy',
 *    sideMenu: ['',''],
 *    componentMenu: ['',''],
 *    othersMenu: ['','']
 * }
 * @returns msg: 'OK'
 */
export const createGroupApi = (
  operator,
  role,
  sideMenu,
  componentMenu,
  othersMenu,
  otherSites
) =>
  axios.post(Api.CreateGroup + `${operator}`, {
    role,
    sideMenu,
    componentMenu,
    othersMenu,
    otherSites
  })

/**
 * 更新群組
 * @param {string} id 'id'
 * @param {string} operator 'userName'
 * @param {string} role 'groupName'
 * @param {string[]} sideMenu ['','']
 * @param {string[]} componentMenu ['','']
 * @param {string[]} othersMenu ['','']
 * @returns msg: 'OK'
 */
export const updateGroupApi = (
  id,
  operator,
  role,
  sideMenu,
  componentMenu,
  othersMenu,
  otherSites
) =>
  axios.put(Api.UpdateGroup + `${operator}`, {
    id,
    role,
    sideMenu,
    componentMenu,
    othersMenu,
    otherSites
  })

/**
 * 刪除群組
 * @param {string} operator 'userName'
 * @param {string} role 'groupName'
 * @param {string} id 'id'
 * @returns msg: 'OK'
 */
export const deleteGroupApi = (operator, role, id) =>
  axios.delete(Api.DeleteGroup + `${operator}`, { data: { role, id } })

// -------------------------------------------------------------
// -------------------------- emailsend ------------------------
// -------------------------------------------------------------
/**
 * 信件補發 業主案場清單、群組名稱
 */
export const getDailyReportListApi = () => axios.post(Api.GetDailyReportList)

/**
 * 信件補發 案場資訊
 * @param {string} group 'Wuri.High.Speed.Rail'
 * @param {string} date '2023-02-15'
 */
export const getDailyReportApi = (group, date) =>
  axios.post(Api.GetDailyReport, { group, date })

/**
 * 信件補發 信件寄送
 * @param {string} group 'Wuri.High.Speed.Rail'
 * @param {string} date '2023-02-15'
 * @param {Array<object>} reportData 寄件報告 [{}]
 * @param {string[]} mails 寄件人 ['','',...]
 */
export const getSendMailsApi = (group, date, reportData, mails) =>
  axios.post(Api.GetSendMails, { group, date, reportData, mails })

// -------------------------------------------------------------
// ------------------- multiplefactoryanalysis -----------------
// -------------------------------------------------------------
/**
 * 跨案場分析 - 時
 * @param {Array<object>} data
 * [
 *    { fId: 'f001',
 *      siteName: '鳳山圳滯洪池',
 *      todayDate: '2023-02-16' },
 *    {},
 *    ...
 * ]
 */
export const getMultipleHourDataApi = (data) =>
  axios.post(Api.GetMultipleHourData, data)

/**
 * 跨案場分析 - 日
 * @param {Array<object>} data
 * [
 *    { fId: 'f001',
 *      siteName: '鳳山圳滯洪池',
 *      beginDate: '2023-02-16',
 *      endDate: '2023-02-16' },
 *    {},
 *    ...
 * ]
 */
export const getMultipleDayDataApi = (data) =>
  axios.post(Api.GetMultipleDayData, data)

/**
 * 跨案場分析 - 月
 * @param {Array<object>} data
 * [
 *    { fId: 'f001',
 *      siteName: '鳳山圳滯洪池',
 *      beginDate: '2023-02',
 *      endDate: '2023-02' },
 *    {},
 *    ...
 * ]
 */
export const getMultipleMonthDataApi = (data) =>
  axios.post(Api.GetMultipleMonthData, data)

/**
 * 跨案場分析 - 年
 * @param {Array<object>} data
 * [
 *    { fId: 'f001',
 *      siteName: '鳳山圳滯洪池',
 *      beginDate: '2023',
 *      endDate: '2023' },
 *    {},
 *    ...
 * ]
 */
export const getMultipleYearDataApi = (data) =>
  axios.post(Api.GetMultipleYearData, data)

// -------------------------------------------------------------
// ---------------------- rawdatadownload ----------------------
// -------------------------------------------------------------
/**
 * 原始資料下載
 * @param {string} site '鳳山圳滯洪池_f001'
 * @param {string} date '2023-02-09'
 */
export const getRawDataApi = (site, date) =>
  axios.post(Api.GetRawData, { site, date })

// -------------------------------------------------------------
// ------------------------- raanalysis -----------------------
// -------------------------------------------------------------
/**
 * RA 資料分析
 * @param {string} fId 'f108'
 * @param {string} factoryName '奇加企業'
 * @param {string} timeStart '2023-02-11T16:00:00+08:00'
 * @param {string} timeEnd '2023-02-11T17:00:00+08:00'
 */
export const getPvRaApi = (fId, factoryName, timeStart, timeEnd) =>
  axios.post(Api.GetPvRa, { fId, factoryName, timeStart, timeEnd })

// -------------------------------------------------------------
// ------------------------- kpireport ------------------------
// -------------------------------------------------------------
/**
 * RA 資料分析
 * @param {string} date '2024-03'
 */
export const getKpiApi = (date) => axios.post(Api.GetKpi, { date })

// -------------------------------------------------------------
// ------------------------- 富強鑫 fuqiangxindemo --------------
// -------------------------------------------------------------
export const getFuqiangxinApi = () => axios.post(Api.GetFuqiangxin)

// -------------------------------------------------------------
// ------------------------- 案場排序 sortsetting ---------------
// -------------------------------------------------------------
export const getSortedFactoriesApi = () => axios.post(Api.GetSortedFactories)

/**
 * 案場排序列表上傳
 * @param {string[]} sortArray ['nameA', 'nameB', ...]
 */
export const updateSortedFactoriesApi = (sortArray) =>
  axios.post(Api.UpdateSortedFactories, { sortArray })

// -------------------------------------------------------------
// ------------------------- 電視牆輪播 tvwalllooping -----------
// -------------------------------------------------------------
export const get14fSelectedApi = () => axios.post(Api.Get14fSelected)
export const get14fDataApi = (factoryName) =>
  axios.post(Api.Get14fData, { factoryName })
