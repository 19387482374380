import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.entries.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("案場狀態")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center m-2 mb-3"
  }, [_c('div', {
    staticClass: "position-relative mr-2 d-flex align-items-center"
  }, [_c('i', {
    staticClass: "fas fa-search mr-2 text-secondary"
  }), _c('b-form-input', {
    staticClass: "filterInput textInput rounded-0",
    attrs: {
      "size": "sm",
      "placeholder": '依' + _vm.selectedFilter.text + '搜尋'
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }), _c('span', {
    staticClass: "focus-border position-absolute"
  })], 1), _c('b-form-select', {
    staticClass: "w-auto mr-4",
    attrs: {
      "options": _vm.filterOptions,
      "size": "sm"
    },
    model: {
      value: _vm.selectedFilter,
      callback: function callback($$v) {
        _vm.selectedFilter = $$v;
      },
      expression: "selectedFilter"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("依區域篩選: ")]), _c('b-form-select', {
    staticClass: "w-auto mr-4",
    attrs: {
      "options": _vm.filterSiteOptions,
      "size": "sm"
    },
    model: {
      value: _vm.filterSite,
      callback: function callback($$v) {
        _vm.filterSite = $$v;
      },
      expression: "filterSite"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" 資料筆數: " + _vm._s(_vm.factoryStatusFilterSearch.length) + " ")]), _c('button', {
    staticClass: "confirmButton p-1 my-1 ml-auto mr-3",
    class: {
      disableButton: _vm.loadingData
    },
    staticStyle: {
      "width": "80px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.getRecentAlarms
    }
  }, [_vm._v(" 更新 ")]), _c('button', {
    staticClass: "confirmButton py-1 my-1",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.goMoxaPage
    }
  }, [_vm._v(" MOXA 頁面 ")])], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingData
    }
  }, [_c('div', {
    staticClass: "otherTableStyle tableWrap"
  }, [_c('table', {
    staticClass: "w-100 text-center text-nowrap"
  }, [_c('tr', {
    staticClass: "sticky-top"
  }, [_vm._l(_vm.thTitle, function (i, index) {
    return _c('th', {
      key: index,
      staticClass: "sort",
      class: {
        down: !i.sort,
        up: i.sort
      },
      on: {
        "click": function click($event) {
          return _vm.sortHistoryData(i.value, index);
        }
      }
    }, [_vm._v(" " + _vm._s(i.text) + " ")]);
  }), _c('th', [_vm._v("設定")])], 2), _vm._l(_vm.factoryStatusFilterSearch, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(i.factoryName))]), _c('td', [_vm._v(_vm._s(i.projectNumber))]), _c('td', [_vm._v(_vm._s(i.county))]), _c('td', [_vm._v(_vm._s(i.maintainer))]), _c('td', [_vm._v(_vm._s(i.maintainerNum))]), _c('td', [_c('i', {
      staticClass: "fas fa-wifi statusIcon",
      style: _vm.StatusFunction(i.statusResult.disconnect),
      attrs: {
        "id": i.factoryName + 'disconnect'
      }
    }), _c('i', {
      staticClass: "fas fa-car-battery statusIcon",
      style: _vm.StatusFunction(i.statusResult.invsTotalStatus),
      attrs: {
        "id": i.factoryName + 'invs'
      }
    }), _c('i', {
      staticClass: "fas fa-bolt statusIcon cursor-pointer",
      style: _vm.StatusFunction(i.statusResult.meter),
      attrs: {
        "id": i.factoryName + 'meter'
      },
      on: {
        "click": function click($event) {
          _vm.showMeter(i.factoryName, _vm.StatusFunction(i.statusResult.meter));
        }
      }
    }), _c('i', {
      staticClass: "fas fa-sun statusIcon",
      style: _vm.StatusFunction(i.statusResult.irr),
      attrs: {
        "id": i.factoryName + 'irr'
      }
    }), _c('i', {
      staticClass: "far fa-square statusIcon",
      style: _vm.StatusFunction(i.statusResult.moduleTemp),
      attrs: {
        "id": i.factoryName + 'moduleTemp'
      }
    }), _c('i', {
      staticClass: "fas fa-thermometer-half statusIcon",
      style: _vm.StatusFunction(i.statusResult.envTemp),
      attrs: {
        "id": i.factoryName + 'envTemp'
      }
    }), _c('i', {
      staticClass: "fas fa-wind statusIcon",
      style: _vm.StatusFunction(i.statusResult.windSpeed),
      attrs: {
        "id": i.factoryName + 'windSpeed'
      }
    }), _c('i', {
      staticClass: "fas fa-water statusIcon",
      style: _vm.StatusFunction(i.statusResult.waterLevel),
      attrs: {
        "id": i.factoryName + 'waterLevel'
      }
    })]), _c('td', [_vm._v(_vm._s(i.timestamp))]), _c('td', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openSetting(i.config, i.factoryName, i.docId, i.defaultConfig);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-sliders-h"
    })]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'disconnect',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("資料蒐集器")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [_vm._v(" " + _vm._s(i.statusResult.disconnect ? "斷線" : "正常") + " ")])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'invs',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("變流器")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.invsTotalStatus && i.statusResult.invsTotalStatus.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.invsTotalStatus && i.statusResult.invsTotalStatus.length > 0 ? _c('div', _vm._l(i.statusResult.invsTotalStatus, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'meter',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("電表")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.meter && i.statusResult.meter.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.meter && i.statusResult.meter.length > 0 ? _c('div', _vm._l(i.statusResult.meter, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      staticClass: "test",
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'irr',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("日照")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.irr && i.statusResult.irr.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.irr && i.statusResult.irr.length > 0 ? _c('div', _vm._l(i.statusResult.irr, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'moduleTemp',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("模溫")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.moduleTemp && i.statusResult.moduleTemp.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.moduleTemp && i.statusResult.moduleTemp.length > 0 ? _c('div', _vm._l(i.statusResult.moduleTemp, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'envTemp',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("環溫")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.envTemp && i.statusResult.envTemp.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.envTemp && i.statusResult.envTemp.length > 0 ? _c('div', _vm._l(i.statusResult.envTemp, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'windSpeed',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("風速")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.windSpeed && i.statusResult.windSpeed.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.windSpeed && i.statusResult.windSpeed.length > 0 ? _c('div', _vm._l(i.statusResult.windSpeed, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])]), _c('b-popover', {
      attrs: {
        "placement": "rightbottom",
        "target": i.factoryName + 'waterLevel',
        "triggers": "hover"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("水位")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "popoverContent"
    }, [i.statusResult.waterLevel && i.statusResult.waterLevel.length === 0 ? _c('div', [_vm._v(" 正常 ")]) : i.statusResult.waterLevel && i.statusResult.waterLevel.length > 0 ? _c('div', _vm._l(i.statusResult.waterLevel, function (x, idx) {
      return _c('p', {
        key: idx
      }, [_vm._v(" " + _vm._s(x.msg) + " ")]);
    }), 0) : _c('div', [_vm._v("無裝置或是未填設定值")])])])], 1);
  })], 2)])])], 1), _c('b-modal', {
    attrs: {
      "id": "settingModal",
      "size": "xl",
      "title": "設定",
      "hide-footer": ""
    }
  }, [_c('b-alert', {
    staticClass: "text-center font-weight-bold mt-3",
    attrs: {
      "show": _vm.configExist,
      "variant": "secondary"
    }
  }, [_vm._v(" 此案場尚未上傳設定值 ")]), _c('div', {
    staticClass: "otherTableStyle overflow-auto"
  }, [_c('table', {
    staticClass: "w-100 text-center text-nowrap"
  }, [_c('tr', [_c('th', [_vm._v("資料異常之判定")]), _c('th', [_vm._v("數值")]), _c('th', [_vm._v("上限值")]), _c('th', [_vm._v("下限值")]), _c('th', [_vm._v("備註")])]), _vm._l(_vm.settingArray, function (i, idx) {
    return _c('tr', {
      key: idx
    }, [idx === 'invs' ? _c('td', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v("變流器")]), _c('b-form-select', {
      staticClass: "w-auto",
      attrs: {
        "size": "sm"
      },
      model: {
        value: _vm.invSelect,
        callback: function callback($$v) {
          _vm.invSelect = $$v;
        },
        expression: "invSelect"
      }
    }, _vm._l(i, function (j, key, index) {
      return _c('b-form-select-option', {
        key: index,
        attrs: {
          "value": key
        }
      }, [_vm._v(" " + _vm._s(_vm.transferConfig(key)) + " ")]);
    }), 1)], 1) : _c('td', [_vm._v(_vm._s(_vm.transferConfig(idx)))]), i["".concat(_vm.invSelect)] && idx === 'invs' ? _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i["".concat(_vm.invSelect)].unit
      },
      on: {
        "input": function input($event) {
          i["".concat(_vm.invSelect)].unit = $event.target.value;
        }
      }
    })], 1) : _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i.unit
      },
      on: {
        "input": function input($event) {
          i.unit = $event.target.value;
        }
      }
    })], 1), i["".concat(_vm.invSelect)] && idx === 'invs' ? _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i["".concat(_vm.invSelect)].max
      },
      on: {
        "input": function input($event) {
          i["".concat(_vm.invSelect)].max = $event.target.value;
        }
      }
    })], 1) : _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i.max
      },
      on: {
        "input": function input($event) {
          i.max = Number($event.target.value);
        }
      }
    })], 1), i["".concat(_vm.invSelect)] && idx === 'invs' ? _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i["".concat(_vm.invSelect)].min
      },
      on: {
        "input": function input($event) {
          i["".concat(_vm.invSelect)].min = $event.target.value;
        }
      }
    })], 1) : _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i.min
      },
      on: {
        "input": function input($event) {
          i.min = Number($event.target.value);
        }
      }
    })], 1), i["".concat(_vm.invSelect)] && idx === 'invs' ? _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i["".concat(_vm.invSelect)].note
      },
      on: {
        "input": function input($event) {
          i["".concat(_vm.invSelect)].note = $event.target.value;
        }
      }
    })], 1) : _c('td', [_c('b-form-input', {
      staticClass: "textInput inputWidth mx-auto",
      attrs: {
        "size": "sm",
        "value": i.note
      },
      on: {
        "input": function input($event) {
          i.note = $event.target.value;
        }
      }
    })], 1)]);
  })], 2)]), _c('button', {
    staticClass: "confirmButton p-1 mt-3 d-flex mx-auto",
    staticStyle: {
      "width": "80px"
    },
    on: {
      "click": function click($event) {
        return _vm.upDateConfig(_vm.settingArray);
      }
    }
  }, [_vm._v(" 上傳 ")])], 1), _vm.meterData ? _c('b-modal', {
    attrs: {
      "id": "meterModal",
      "size": "xl",
      "title": _vm.meterFactory + ' 電表資料',
      "hide-footer": ""
    }
  }, [_c('p', [_vm._v(" 資料更新時間: " + _vm._s(_vm.DateTime.fromISO(_vm.meterData.timestamp).minus({
    hour: 8
  }).toFormat("yyyy-MM-dd HH:mm:ss")) + " ")]), _c('div', {
    staticClass: "otherTableStyle d-flex text-center"
  }, [_vm.meterData.etc['HV-meter'] ? _c('table', {
    staticClass: "w-100 m-5px"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("HV-METER")])]), _vm._l(Object.entries(_vm.meterData.etc['HV-meter']), function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(i[0]))]), _c('td', [_vm._v(_vm._s(i[1]))])]);
  })], 2) : _vm._e(), _vm.meterData.etc['LV-meter'] ? _c('table', {
    staticClass: "w-100 m-5px"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("LV-METER")])]), _vm._l(Object.entries(_vm.meterData.etc['LV-meter']), function (i, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(i[0]))]), _c('td', [_vm._v(_vm._s(i[1]))])]);
  })], 2) : _vm._e()])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };