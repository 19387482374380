var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("群組管理")]), _c('div', {
    staticClass: "borderColor mb-4"
  }, [_c('div', {
    staticClass: "d-flex m-2 mb-3"
  }, [_c('v-btn', {
    staticClass: "ml-auto",
    attrs: {
      "color": "primary",
      "depressed": "",
      "elevation": "2"
    },
    on: {
      "click": _vm.handleUpdateGroupData
    }
  }, [_vm._v(" 更新群組 ")])], 1), _c('div', {
    staticClass: "tableWrap"
  }, [_c('table', {
    staticClass: "text-center"
  }, [_c('tr', {
    staticClass: "sticky-top"
  }, [_vm._l(_vm.thTitle, function (tableTitle, thIdx) {
    return _c('th', {
      key: thIdx
    }, [_vm._v(" " + _vm._s(tableTitle) + " ")]);
  }), _c('th', [_vm._v("設定")])], 2), _vm._l(_vm.data, function (group, groupIdx) {
    return _c('tr', {
      key: groupIdx
    }, [_c('td', [_vm._v(_vm._s(group.role))]), _c('td', _vm._l(_vm.sideMenuOptions, function (item) {
      return _c('v-checkbox', {
        key: item.text,
        staticClass: "checkBox w-75",
        attrs: {
          "label": item.text,
          "value": item.value,
          "hide-details": ""
        },
        on: {
          "change": function change($event) {
            return _vm.sortSelectedSideMenu(groupIdx);
          }
        },
        model: {
          value: _vm.data[groupIdx].sideMenu,
          callback: function callback($$v) {
            _vm.$set(_vm.data[groupIdx], "sideMenu", $$v);
          },
          expression: "data[groupIdx].sideMenu"
        }
      });
    }), 1), _c('td', _vm._l(_vm.componentMenuOptions, function (compItem) {
      return _c('v-checkbox', {
        key: compItem.text,
        staticClass: "checkBox w-50",
        attrs: {
          "label": compItem.text,
          "value": compItem.value,
          "hide-details": ""
        },
        on: {
          "change": function change($event) {
            return _vm.sortSelectedComponentMenu(groupIdx);
          }
        },
        model: {
          value: _vm.data[groupIdx].componentMenu,
          callback: function callback($$v) {
            _vm.$set(_vm.data[groupIdx], "componentMenu", $$v);
          },
          expression: "data[groupIdx].componentMenu"
        }
      });
    }), 1), _c('td', _vm._l(_vm.data[groupIdx].othersMenu, function (obj, updateIdx) {
      var _obj$list, _obj$list2;
      return _c('div', {
        key: updateIdx,
        staticClass: "mb-5"
      }, [_c('v-checkbox', {
        staticClass: "checkBox mb-3",
        attrs: {
          "label": obj.menuName,
          "hide-details": ""
        },
        on: {
          "change": function change($event) {
            return _vm.handleSelectedOthersMenuTitle(obj.hasPermission, groupIdx, updateIdx);
          }
        },
        model: {
          value: obj.hasPermission,
          callback: function callback($$v) {
            _vm.$set(obj, "hasPermission", $$v);
          },
          expression: "obj.hasPermission"
        }
      }), _c('div', {
        staticClass: "section"
      }, [_c('span', [_vm._v(" " + _vm._s(_vm.othersMenuList[updateIdx].listName) + " : ")]), _c('v-select', {
        attrs: {
          "items": _vm.othersMenuList[updateIdx].options,
          "multiple": "",
          "outlined": "",
          "small-chips": "",
          "deletable-chips": "",
          "attach": "",
          "menu-props": {
            offsetY: true
          },
          "disabled": !(obj !== null && obj !== void 0 && obj.hasPermission),
          "placeholder": "請選擇",
          "error-messages": obj !== null && obj !== void 0 && obj.hasPermission && !(obj !== null && obj !== void 0 && (_obj$list = obj.list) !== null && _obj$list !== void 0 && _obj$list.length) ? ['請至少選擇一項'] : [],
          "error": (obj === null || obj === void 0 ? void 0 : obj.hasPermission) && !(obj !== null && obj !== void 0 && (_obj$list2 = obj.list) !== null && _obj$list2 !== void 0 && _obj$list2.length)
        },
        on: {
          "input": function input($event) {
            return _vm.sortSelectedOthersMenuList(groupIdx, updateIdx);
          }
        },
        model: {
          value: obj.list,
          callback: function callback($$v) {
            _vm.$set(obj, "list", $$v);
          },
          expression: "obj.list"
        }
      })], 1)], 1);
    }), 0), _c('td', [_c('v-dialog', {
      staticClass: "settingDialog",
      attrs: {
        "max-width": "600px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "settingButton my-5",
            attrs: {
              "depressed": "",
              "outlined": "",
              "block": ""
            }
          }, 'v-btn', attrs, false), on), [_vm._v(" 電能可視化管理 ")])];
        }
      }], null, true),
      model: {
        value: _vm.energyDialogs[groupIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.energyDialogs, groupIdx, $$v);
        },
        expression: "energyDialogs[groupIdx]"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(group.role) + " 電能可視化管理")])]), _c('v-simple-table', {
      staticClass: "px-3",
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          var _vm$data$groupIdx$oth, _vm$data$groupIdx$oth2;
          return [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-left w-50",
            staticStyle: {
              "font-size": "16px"
            }
          }, [_vm._v(" 可觀看 SIDE 網頁 ")])])]), _c('tbody', [_c('tr', [_c('td', {
            staticClass: "pt-3 pb-4"
          }, [(_vm$data$groupIdx$oth = _vm.data[groupIdx].otherSites) !== null && _vm$data$groupIdx$oth !== void 0 && (_vm$data$groupIdx$oth2 = _vm$data$groupIdx$oth[0]) !== null && _vm$data$groupIdx$oth2 !== void 0 && _vm$data$groupIdx$oth2.sideMenu ? _c('div', _vm._l(_vm.energySideMenuOptions, function (energyItem) {
            return _c('v-checkbox', {
              key: energyItem.text,
              staticClass: "checkBox",
              attrs: {
                "label": energyItem.text,
                "value": energyItem.value,
                "hide-details": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.sortSelectedEnergySideMenu(groupIdx);
                }
              },
              model: {
                value: _vm.data[groupIdx].otherSites[0].sideMenu,
                callback: function callback($$v) {
                  _vm.$set(_vm.data[groupIdx].otherSites[0], "sideMenu", $$v);
                },
                expression: "data[groupIdx].otherSites[0].sideMenu"
              }
            });
          }), 1) : _vm._e()])])]), _c('v-alert', {
            staticClass: "mt-3",
            attrs: {
              "color": "primary",
              "dense": "",
              "text": "",
              "type": "info"
            }
          }, [_vm._v(" 修改後，請更新群組 ")])];
        },
        proxy: true
      }], null, true)
    })], 1)], 1), _c('v-dialog', {
      staticClass: "settingDialog",
      attrs: {
        "max-width": "600px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "settingButton d-block mx-auto my-5",
            attrs: {
              "depressed": "",
              "outlined": "",
              "block": ""
            }
          }, 'v-btn', attrs, false), on), [_vm._v(" 儲能管理 ")])];
        }
      }], null, true),
      model: {
        value: _vm.batteryDialogs[groupIdx],
        callback: function callback($$v) {
          _vm.$set(_vm.batteryDialogs, groupIdx, $$v);
        },
        expression: "batteryDialogs[groupIdx]"
      }
    }, [_c('v-card', [_c('v-card-title', [_c('span', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(group.role) + " 儲能管理")])]), _c('v-simple-table', {
      staticClass: "px-3",
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          var _vm$data$groupIdx$oth3, _vm$data$groupIdx$oth4;
          return [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-left w-50",
            staticStyle: {
              "font-size": "16px"
            }
          }, [_vm._v(" 可觀看 SIDE 網頁 ")])])]), _c('tbody', [_c('tr', [_c('td', {
            staticClass: "pt-3 pb-4"
          }, [(_vm$data$groupIdx$oth3 = _vm.data[groupIdx].otherSites) !== null && _vm$data$groupIdx$oth3 !== void 0 && (_vm$data$groupIdx$oth4 = _vm$data$groupIdx$oth3[1]) !== null && _vm$data$groupIdx$oth4 !== void 0 && _vm$data$groupIdx$oth4.sideMenu ? _c('div', _vm._l(_vm.batterySideMenuOptions, function (batteryItem) {
            return _c('v-checkbox', {
              key: batteryItem.text,
              staticClass: "checkBox",
              attrs: {
                "label": batteryItem.text,
                "value": batteryItem.value,
                "hide-details": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.sortSelectedBatterySideMenu(groupIdx);
                }
              },
              model: {
                value: _vm.data[groupIdx].otherSites[1].sideMenu,
                callback: function callback($$v) {
                  _vm.$set(_vm.data[groupIdx].otherSites[1], "sideMenu", $$v);
                },
                expression: "data[groupIdx].otherSites[1].sideMenu"
              }
            });
          }), 1) : _vm._e()])])]), _c('v-alert', {
            staticClass: "mt-3",
            attrs: {
              "color": "primary",
              "dense": "",
              "text": "",
              "type": "info"
            }
          }, [_vm._v(" 修改後，請更新群組 ")])];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)], 1), _c('td', [_c('v-btn', {
      staticClass: "settingButton",
      attrs: {
        "color": "error",
        "depressed": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleDeleteGroupData(group.role, group.id);
        }
      }
    }, [_vm._v(" 刪除群組 ")])], 1)]);
  }), _c('tr', [_c('td', [_c('v-text-field', {
    staticClass: "textInput w-75 mx-auto",
    attrs: {
      "label": "輸入等級名稱"
    },
    model: {
      value: _vm.createRoleName,
      callback: function callback($$v) {
        _vm.createRoleName = $$v;
      },
      expression: "createRoleName"
    }
  })], 1), _c('td', _vm._l(_vm.sideMenuOptions, function (item) {
    return _c('v-checkbox', {
      key: item.text,
      staticClass: "checkBox w-75",
      attrs: {
        "label": item.text,
        "value": item.value,
        "hide-details": ""
      },
      on: {
        "change": function change($event) {
          return _vm.sortSelectedSideMenu('createNew');
        }
      },
      model: {
        value: _vm.createSideMenu,
        callback: function callback($$v) {
          _vm.createSideMenu = $$v;
        },
        expression: "createSideMenu"
      }
    });
  }), 1), _c('td', _vm._l(_vm.componentMenuOptions, function (compItem) {
    return _c('v-checkbox', {
      key: compItem.text,
      staticClass: "checkBox w-50",
      attrs: {
        "label": compItem.text,
        "value": compItem.value,
        "hide-details": ""
      },
      on: {
        "change": function change($event) {
          return _vm.sortSelectedComponentMenu('createNew');
        }
      },
      model: {
        value: _vm.createComponentMenu,
        callback: function callback($$v) {
          _vm.createComponentMenu = $$v;
        },
        expression: "createComponentMenu"
      }
    });
  }), 1), _c('td', _vm._l(_vm.createOthersMenu, function (createObj, createIdx) {
    var _createObj$list, _createObj$list2;
    return _c('div', {
      key: createIdx,
      staticClass: "mb-5"
    }, [_c('v-checkbox', {
      staticClass: "checkBox mb-3",
      attrs: {
        "label": createObj.menuName,
        "hide-details": ""
      },
      on: {
        "change": function change($event) {
          return _vm.handleSelectedOthersMenuTitle(createObj.hasPermission, 'createNew', createIdx);
        }
      },
      model: {
        value: createObj.hasPermission,
        callback: function callback($$v) {
          _vm.$set(createObj, "hasPermission", $$v);
        },
        expression: "createObj.hasPermission"
      }
    }), _c('div', {
      staticClass: "section"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.othersMenuList[createIdx].listName) + " : ")]), _c('v-select', {
      attrs: {
        "items": _vm.othersMenuList[createIdx].options,
        "multiple": "",
        "outlined": "",
        "small-chips": "",
        "deletable-chips": "",
        "attach": "",
        "menu-props": {
          offsetY: true
        },
        "disabled": !(createObj !== null && createObj !== void 0 && createObj.hasPermission),
        "placeholder": "請選擇",
        "error-messages": createObj !== null && createObj !== void 0 && createObj.hasPermission && !(createObj !== null && createObj !== void 0 && (_createObj$list = createObj.list) !== null && _createObj$list !== void 0 && _createObj$list.length) ? ['請至少選擇一項'] : [],
        "error": (createObj === null || createObj === void 0 ? void 0 : createObj.hasPermission) && !(createObj !== null && createObj !== void 0 && (_createObj$list2 = createObj.list) !== null && _createObj$list2 !== void 0 && _createObj$list2.length)
      },
      on: {
        "input": function input($event) {
          return _vm.sortSelectedOthersMenuList('createNew', createIdx);
        }
      },
      model: {
        value: createObj.list,
        callback: function callback($$v) {
          _vm.$set(createObj, "list", $$v);
        },
        expression: "createObj.list"
      }
    })], 1)], 1);
  }), 0), _c('td', [_c('v-dialog', {
    staticClass: "settingDialog",
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "settingButton my-5",
          attrs: {
            "depressed": "",
            "outlined": "",
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 電能可視化管理 ")])];
      }
    }]),
    model: {
      value: _vm.createEnergyDialog,
      callback: function callback($$v) {
        _vm.createEnergyDialog = $$v;
      },
      expression: "createEnergyDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.createRoleName) + " 電能可視化管理")])]), _c('v-simple-table', {
    staticClass: "px-3",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left w-50",
          staticStyle: {
            "font-size": "16px"
          }
        }, [_vm._v(" 可觀看 SIDE 網頁 ")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "pt-3 pb-4"
        }, _vm._l(_vm.energySideMenuOptions, function (energyItem) {
          return _c('v-checkbox', {
            key: energyItem.text,
            staticClass: "checkBox",
            attrs: {
              "label": energyItem.text,
              "value": energyItem.value,
              "hide-details": ""
            },
            on: {
              "change": function change($event) {
                return _vm.sortSelectedEnergySideMenu('createNew');
              }
            },
            model: {
              value: _vm.createEnergySideMenu,
              callback: function callback($$v) {
                _vm.createEnergySideMenu = $$v;
              },
              expression: "createEnergySideMenu"
            }
          });
        }), 1)])]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "color": "primary",
            "dense": "",
            "text": "",
            "type": "info"
          }
        }, [_vm._v(" 修改後，請更新群組 ")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-dialog', {
    staticClass: "settingDialog",
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "settingButton my-5",
          attrs: {
            "depressed": "",
            "outlined": "",
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 儲能管理 ")])];
      }
    }]),
    model: {
      value: _vm.createBatteryDialog,
      callback: function callback($$v) {
        _vm.createBatteryDialog = $$v;
      },
      expression: "createBatteryDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(_vm._s(_vm.createRoleName) + " 儲能管理")])]), _c('v-simple-table', {
    staticClass: "px-3",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left w-50",
          staticStyle: {
            "font-size": "16px"
          }
        }, [_vm._v(" 可觀看 SIDE 網頁 ")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "pt-3 pb-5"
        }, _vm._l(_vm.batterySideMenuOptions, function (batteryItem) {
          return _c('v-checkbox', {
            key: batteryItem.text,
            staticClass: "checkBox",
            attrs: {
              "label": batteryItem.text,
              "value": batteryItem.value,
              "hide-details": ""
            },
            on: {
              "change": function change($event) {
                return _vm.sortSelectedBatterySideMenu('createNew');
              }
            },
            model: {
              value: _vm.createBatterySideMenu,
              callback: function callback($$v) {
                _vm.createBatterySideMenu = $$v;
              },
              expression: "createBatterySideMenu"
            }
          });
        }), 1)])]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "color": "primary",
            "dense": "",
            "text": "",
            "type": "info"
          }
        }, [_vm._v(" 修改後，請更新群組 ")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('td', [_c('v-btn', {
    staticClass: "settingButton",
    attrs: {
      "color": "primary",
      "depressed": "",
      "outlined": ""
    },
    on: {
      "click": _vm.handleCreateGroupData
    }
  }, [_vm._v(" 新增群組 ")])], 1)])], 2)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };