var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "borderColor my-2"
  }, [_c('div', {
    staticClass: "otherTableStyle overflow-auto"
  }, [_c('table', {
    staticClass: "w-100 text-center font-weight-bold dataTable"
  }, [_c('tr', [_c('th', [_c('i', {
    staticClass: "far fa-question-circle text-danger mr-2",
    staticStyle: {
      "font-size": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.showMonitorModal
    }
  }), _vm._v("計費期間 ")]), _c('th'), _c('th', [_vm._v("電費單度數 (kWh)")]), _c('th', [_vm._v("監控度數 (kWh)")]), _c('th', [_vm._v("差異 (%)")]), _c('th')]), _c('tr', [_c('td', [_c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "type": "date",
      "placeholder": "本次抄表日",
      "valueType": "format",
      "format": "YYYY-MM-DD",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.lastDayInput,
      callback: function callback($$v) {
        _vm.lastDayInput = $$v;
      },
      expression: "lastDayInput"
    }
  })], 1), _c('td', [_c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "type": "date",
      "placeholder": "下次抄表日",
      "valueType": "format",
      "format": "YYYY-MM-DD",
      "disabled-date": _vm.disabledDateAfterToday
    },
    model: {
      value: _vm.nextDayInput,
      callback: function callback($$v) {
        _vm.nextDayInput = $$v;
      },
      expression: "nextDayInput"
    }
  })], 1), _c('td', [_c('b-form-input', {
    staticClass: "textInput w-auto text-center mx-auto",
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.kWhInput,
      callback: function callback($$v) {
        _vm.kWhInput = $$v;
      },
      expression: "kWhInput"
    }
  })], 1), _c('td', [_vm._v("-")]), _c('td', [_vm._v("-")]), _c('td', [_c('button', {
    staticClass: "confirmButton",
    on: {
      "click": _vm.monitorDataPush
    }
  }, [_vm._v(" 加入 ")])])]), _vm._l(_vm.monitorData, function (i, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(i.dateStart))]), _c('td', [_vm._v(_vm._s(i.dateEnd))]), _c('td', [_vm._v(_vm._s(i.genKwh))]), _c('td', [_vm._v(" " + _vm._s(_vm.dbDataHandle(i.kwhSum) === 0 ? "計算中" : _vm.dbDataHandle(i.kwhSum)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.dbDataHandle(i.diffRate) === 0 ? "計算中" : _vm.dbDataHandle(i.diffRate)) + " ")]), _c('td', [_c('button', {
      staticClass: "deleteButton",
      on: {
        "click": function click($event) {
          return _vm.checkDeleteMonitorData(index);
        }
      }
    }, [_vm._v(" 刪除 ")])])]);
  })], 2)]), _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-3"
  }, [_c('p', {
    staticClass: "font-weight-bold highChartTitle"
  }, [_vm._v("監控值與電費單比較")]), _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" 資料下載 ")])]), _vm.hasNoData ? _c('div', {
    staticClass: "text-center",
    staticStyle: {
      "line-height": "250px"
    }
  }, [_vm._v(" 尚無資料 ")]) : _c('highcharts', {
    ref: "chart",
    staticClass: "bg-white",
    attrs: {
      "options": _vm.monitorChart
    }
  })], 1)]), _c('b-modal', {
    attrs: {
      "id": "monitorModal",
      "size": "lg",
      "title": "電費單說明",
      "ok-only": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/monitorPicture.png"),
      "width": "700px"
    }
  })])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };