/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./HistoryAlarms.vue?vue&type=template&id=1a97d709&scoped=true&"
import script from "./HistoryAlarms.vue?vue&type=script&lang=js&"
export * from "./HistoryAlarms.vue?vue&type=script&lang=js&"
import style0 from "./HistoryAlarms.vue?vue&type=style&index=0&id=1a97d709&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a97d709",
  null
  
)

export default component.exports