var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-center"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/acme-logo.png")
    }
  }), _c('div', {
    staticClass: "h2 mt-3"
  }, [_vm._v("找不到頁面!")]), _c('a', {
    attrs: {
      "href": "/"
    }
  }, [_vm._v("返回首頁?")])]);
}];
export { render, staticRenderFns };