var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('div', {
    staticClass: "router-links"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/pvstringfailureanalysis/powerfee"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "vol-income",
    class: {
      'active': _vm.activeOrder === 0
    },
    on: {
      "click": function click($event) {
        return _vm.selectAnalysis(0);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("pvstringfailureanalysis.liveInfo")))])]), _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/pvstringfailureanalysis/groupconfig"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "transform",
    class: {
      'active': _vm.activeOrder === 1
    },
    on: {
      "click": function click($event) {
        return _vm.selectAnalysis(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("pvstringfailureanalysis.groupConfiguration")))])]), _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/pvstringfailureanalysis/circlechart"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "circle-chart",
    class: {
      'active': _vm.activeOrder === 2
    },
    on: {
      "click": function click($event) {
        return _vm.selectAnalysis(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("pvstringfailureanalysis.circleChart")))])]), _c('router-link', {
    attrs: {
      "to": {
        path: "/".concat(_vm.lang, "/siteinfo/pvstringfailureanalysis/queryhistorydata"),
        query: _vm.routeQuery
      }
    }
  }, [_c('div', {
    staticClass: "average-shine",
    class: {
      'active': _vm.activeOrder === 3
    },
    on: {
      "click": function click($event) {
        return _vm.selectAnalysis(3);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("pvstringfailureanalysis.historyData")))])])], 1)]), _c('router-view')], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };