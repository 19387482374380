// import { BButton } from 'bootstrap-vue'

export default {
  name: 'BatteryManagement',
  // components: {
  //   BButton
  // },
  data: function data() {
    return {
      title: '儲能管理'
    };
  }
};