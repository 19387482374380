import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    ref: "refScroll",
    on: {
      "scroll": _vm.loadMore
    }
  }, [_c('div', {
    ref: "refTitle",
    staticClass: "pageTitle"
  }, [_vm._v(_vm._s(_vm.$t("realTime.title")))]), _c('div', {
    ref: "refFilterBar",
    staticClass: "realtime_Wrapper"
  }, [_c('div', {
    staticClass: "realtime_Filter"
  }, [_c('div', {
    staticClass: "realtime_Multiselect"
  }, [_c('multiselect', {
    ref: "multiselect",
    attrs: {
      "options": _vm.getFactoryInfoNameList,
      "placeholder": "案場名稱",
      "max-height": 600,
      "selectLabel": "",
      "deselectLabel": "remove",
      "preserveSearch": true,
      "closeOnSelect": false,
      "clearOnSelect": false,
      "multiple": true
    },
    on: {
      "search-change": _vm.searchChange,
      "select": _vm.searchSelect,
      "remove": _vm.searchRemove
    },
    model: {
      value: _vm.multiselectValue,
      callback: function callback($$v) {
        _vm.multiselectValue = $$v;
      },
      expression: "multiselectValue"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v(" 查詢無資料，請重新搜尋 ")])])], 1), _c('div', {
    staticClass: "realtime_DataNum text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t("realTime.dataFound")) + ": " + _vm._s(_vm.filterSearch.length) + " ")]), _c('div', {
    staticClass: "realtime_Status text-nowrap"
  }, [_vm._v(" 異常狀態: "), _c('b-form-select', {
    staticClass: "ml-2 mr-3",
    staticStyle: {
      "width": "138px"
    },
    attrs: {
      "disabled": _vm.alertLevelSelectDisAble
    },
    on: {
      "change": _vm.factoryAlertSearch
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "disabled": ""
          },
          domProps: {
            "value": null
          }
        }, [_vm._v("請選擇")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedAlert,
      callback: function callback($$v) {
        _vm.selectedAlert = $$v;
      },
      expression: "selectedAlert"
    }
  }, [_c('option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("部分通訊異常")]), _c('option', {
    attrs: {
      "value": "7"
    }
  }, [_vm._v("系統短暫斷線")]), _c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v("資料缺漏回補")]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("發電數據異常")]), _c('option', {
    attrs: {
      "value": "9"
    }
  }, [_vm._v("發電效率不佳")]), _c('option', {
    attrs: {
      "value": "6"
    }
  }, [_vm._v("系統未連線")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("- 取消選取 -")])])], 1), _vm.userDetail.role === 'Whole_Admin' ? _c('div', {
    staticClass: "loadAll_Btn--desktop"
  }, [_c('b-button', {
    staticClass: "loadAll_Btn",
    class: {
      'loadAll_Btn--disabled': _vm.isLoadingData
    },
    attrs: {
      "disabled": _vm.isLoadingData
    },
    on: {
      "click": _vm.getAllData
    }
  }, [_c('i', {
    staticClass: "fas fa-solid fa-spinner"
  }), _vm._v(" 全部載入 ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "realtime_Info"
  }, [_c('ul', [_c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "系統正常營運"
    }
  }, [_c('i', {
    staticClass: "fas fa-check-circle mr-1",
    staticStyle: {
      "font-size": "20px",
      "color": "var(--success)"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.systemNormal")) + " (" + _vm._s(_vm.alarmCount(0)) + ") ")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "停電後 Buffer 回補、斷網後計算回補、特殊計算回補、  三日內無資料"
    }
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/file-error.svg"),
      "alt": "資料缺漏回補圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.backfill")) + " (" + _vm._s(_vm.alarmCount(5)) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "依通訊裝置異常告警數值定義(不含 Inv 斷訊)"
    }
  }, [_c('i', {
    staticClass: "fas fa-radiation-alt mr-1",
    staticStyle: {
      "font-size": "20px",
      "color": "var(--yellow)"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.partialSystemError")) + " (" + _vm._s(_vm.alarmCount(3)) + ") ")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "Inv 斷訊(null)、Inv 未發電"
    }
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/electrical.svg"),
      "alt": "發電數據異常圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.noPowerData")) + " (" + _vm._s(_vm.alarmCount(2)) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "案場斷網 30 分鐘內"
    }
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/hourglass.svg"),
      "alt": "系統短暫斷線圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.deviceDisconnectedShort")) + " (" + _vm._s(_vm.alarmCount(7)) + ") ")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "案場斷網大於 30 分鐘"
    }
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/smart-plug.svg"),
      "alt": "系統未連線圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.deviceDisconnected")) + " (" + _vm._s(_vm.alarmCount(6)) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom.html",
      value: {
        title: '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標'
      },
      expression: "{\n              title:\n                '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標',\n            }",
      modifiers: {
        "hover": true,
        "bottom": true,
        "html": true
      }
    }],
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/poor-power.svg"),
      "alt": "發電效率不佳圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.poorPower")) + " (" + _vm._s(_vm.alarmCount(9)) + ") ")])])])]), _vm.userDetail.role === 'Whole_Admin' ? _c('div', {
    staticClass: "loadAll_Btn--mobile"
  }, [_c('b-button', {
    staticClass: "loadAll_Btn",
    class: {
      'loadAll_Btn--disabled': _vm.isLoadingData
    },
    attrs: {
      "disabled": _vm.isLoadingData
    },
    on: {
      "click": _vm.getAllData
    }
  }, [_c('i', {
    staticClass: "fas fa-solid fa-spinner"
  }), _vm._v(" 全部載入 ")])], 1) : _vm._e(), _vm.hasRoleAdmin ? _c('b-button', {
    staticClass: "error_Btn",
    on: {
      "click": _vm.openAllModal
    }
  }, [_c('i', {
    staticClass: "fa fa-bell",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" 告警事件 ")]) : _vm._e()], 1)]), _vm._l(7, function (i) {
    return _c('b-col', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.overlayShow,
        expression: "overlayShow"
      }],
      key: i,
      staticStyle: {
        "padding": "0px",
        "margin": "10px"
      },
      attrs: {
        "cols": "12"
      }
    }, [_c('b-skeleton-wrapper', {
      attrs: {
        "loading": true
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function fn() {
          return [true ? _c('b-skeleton-table', {
            attrs: {
              "rows": 1,
              "columns": 11,
              "table-props": {
                bordered: true,
                striped: true
              }
            }
          }) : _vm._e()];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _c('b-alert', {
    staticClass: "text-center font-weight-bold mt-3 mx-3",
    attrs: {
      "show": _vm.filterSearch.length === 0 && !_vm.overlayShow && _vm.selectedAlert === '',
      "variant": "secondary"
    }
  }, [_vm._v(" 沒有資料，請聯絡管理員 ")]), _c('div', {
    ref: "refData"
  }, [_vm._l(_vm.filterSearch, function (i, idx) {
    var _i$PrRaMon, _i$PrRaMon2;
    return _c('div', {
      key: idx,
      staticClass: "newRealTimeStyle",
      attrs: {
        "id": i.factoryId
      }
    }, [!_vm.overlayShow ? _c('table', {
      on: {
        "click": function click($event) {
          return _vm.goToSingleSiteInfo(i.displaySiteName, i.factoryId, i.isDemoSite);
        }
      }
    }, [_c('div', {
      staticClass: "rwdFactoryName"
    }, [_vm._v(" " + _vm._s(_vm.getSiteAliasName(i.displaySiteName)) + " ")]), _c('tr', [_c('th', {
      staticStyle: {
        "font-size": "22px",
        "width": "15%"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.getSiteAliasName(i.displaySiteName))), i.isDemoSite && _vm.hasRoleAdmin ? _c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.right",
        value: i.factoryName,
        expression: "i.factoryName",
        modifiers: {
          "right": true
        }
      }],
      staticClass: "ml-2 peekDisplayNameBtn"
    }, [_c('i', {
      staticClass: "fas fa-search"
    })]) : _vm._e()]), _c('th', [_vm._v("設置容量(kWp)")]), _vm._m(0, true), _c('th', [_vm._v("模組溫度(°C)")]), _c('th', [_vm._v("直流功率(kW)")]), _c('th', [_vm._v("交流功率(kW)")]), _c('th', [_vm._v("今日發電量(kWh)")]), _c('th', [_vm._v("INV效率(%)")]), _c('th', [_vm._v("RA(%)")]), _c('th', [_vm._v("今日PR(%)")]), _c('th', [_vm._v("案場狀態")])]), _c('tr', [_c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.capacityAll && i.displayAlertLevel !== 6 ? _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v(" " + _vm._s(i.capacityAll) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.IRR && i.IRR.length && !i.IRR.includes(null) && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.handleIRRArray(i.IRR) * 1000, 0)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.PVTemp && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v(" " + _vm._s(i.PVTemp) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.dcp && i.dcp.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "直流功率(kW)"
      }
    }, [_vm._v(" " + _vm._s(_vm.sumValue(i.dcp)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "直流功率(kW)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "直流功率(kW)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.acp && i.acp.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "交流功率(kW)"
      }
    }, [_vm._v(" " + _vm._s(_vm.sumValue(i.acp)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "交流功率(kW)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "交流功率(kW)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.E_today && i.E_today.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v(" " + _vm._s(_vm.sumValue(i.E_today)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.acp && i.dcp && i.acp.length && i.dcp.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dataHandle(_vm.handleInvSum(i.acp) / _vm.handleInvSum(i.dcp) * 100, 0)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.dcp && i.dcp.length && i.capacity && i.capacity.length && i.IRR && i.IRR.length && i.PrRaMon.RA && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "RA(%)"
      }
    }, [_vm._v(" " + _vm._s(_vm.handleInHundred(_vm.dataHandle(parseFloat(i.dcp.reduce(function (a, b) {
      return a + b;
    }).toPrecision(12) / parseFloat(i.capacityAll.toPrecision(12)) / _vm.handleIRRArray(i.IRR)) * 100, 2))) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "RA(%)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "RA(%)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [typeof i.PrRaMon !== 'string' && (_i$PrRaMon = i.PrRaMon) !== null && _i$PrRaMon !== void 0 && _i$PrRaMon.ACP && (_i$PrRaMon2 = i.PrRaMon) !== null && _i$PrRaMon2 !== void 0 && _i$PrRaMon2.PR && i.capacity && i.capacity.length && i.displayAlertLevel !== 6 ? _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dataHandle(i.PrRaMon.PR * 100, 1)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [_c('td', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.right",
        value: _vm.alertLevelToText(i.displayAlertLevel),
        expression: "alertLevelToText(i.displayAlertLevel)",
        modifiers: {
          "right": true
        }
      }],
      attrs: {
        "data-title": "案場狀態"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openSiteModal(i.factoryName, i.displaySiteName, i.isDemoSite);
        }
      }
    }, [i.displayAlertLevel === 0 ? _c('i', {
      staticClass: "fas fa-check-circle",
      staticStyle: {
        "color": "var(--success)"
      }
    }) : i.displayAlertLevel === 1 || i.displayAlertLevel === 2 ? _c('img', {
      staticClass: "pb-1",
      attrs: {
        "src": require("../assets/electrical.svg"),
        "alt": "發電數據異常圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 3 ? _c('i', {
      staticClass: "fas fa-radiation-alt",
      staticStyle: {
        "color": "var(--yellow)"
      }
    }) : i.displayAlertLevel === 5 ? _c('img', {
      staticClass: "pb-1",
      attrs: {
        "src": require("../assets/file-error.svg"),
        "alt": "資料缺漏回補圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 6 ? _c('img', {
      staticClass: "pb-1",
      attrs: {
        "src": require("../assets/smart-plug.svg"),
        "alt": "系統未連線圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 7 ? _c('img', {
      staticClass: "pb-1",
      attrs: {
        "src": require("../assets/hourglass.svg"),
        "alt": "系統短暫斷線圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 4 ? _c('i', {
      staticClass: "fas fa-search-minus",
      staticStyle: {
        "color": "var(--purple)"
      }
    }) : i.displayAlertLevel === 9 ? _c('img', {
      staticClass: "pb-1",
      attrs: {
        "src": require("../assets/poor-power.svg"),
        "alt": "發電效率不佳圖示",
        "width": "20"
      }
    }) : _vm._e()])])], 1)]) : _vm._e()]);
  }), _vm.isLoadingData ? _c('div', {
    staticClass: "text-center m-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }), _vm._v(" Loading... ")], 1)], 1) : _vm._e()], 2), _c('b-modal', {
    attrs: {
      "id": "siteAlertModal",
      "size": "xl",
      "title": _vm.modalTitle,
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-items-center my-2 mr-2"
  }, [_c('span', {
    staticClass: "mr-2 font-weight-bold"
  }, [_vm._v("選擇日期:")]), _c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "type": "date"
    },
    model: {
      value: _vm.alertBeginTime,
      callback: function callback($$v) {
        _vm.alertBeginTime = $$v;
      },
      expression: "alertBeginTime"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center my-2 mr-3 mr-lg-4"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("~")]), _c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "type": "date"
    },
    model: {
      value: _vm.alertEndTime,
      callback: function callback($$v) {
        _vm.alertEndTime = $$v;
      },
      expression: "alertEndTime"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 mr-lg-4 my-2 my-xl-3"
  }, [_c('b-button', {
    staticClass: "confirmButton",
    staticStyle: {
      "width": "60px"
    },
    on: {
      "click": function click($event) {
        return _vm.queryModal(_vm.modalTitle);
      }
    }
  }, [_vm._v(" 查詢 ")])], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 mr-lg-4 my-2 my-xl-3"
  }, [_c('span', {
    staticClass: "mr-2 font-weight-bold text-nowrap"
  }, [_vm._v("事件篩選:")]), _c('b-form-input', {
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.alertFilterInput,
      callback: function callback($$v) {
        _vm.alertFilterInput = $$v;
      },
      expression: "alertFilterInput"
    }
  })], 1), !_vm.alertModalNoData ? _c('div', {
    staticClass: "d-flex align-items-center font-weight-bold my-2 ml-auto"
  }, [_vm._v(" 總告警: "), _c('span', {
    staticClass: "px-2 text-center",
    staticStyle: {
      "min-width": "50px",
      "font-family": "'微軟正黑體'"
    }
  }, [_vm._v(" " + _vm._s(_vm.filteredAlertData.length) + " ")]), _vm._v(" 筆 ")]) : _vm._e()]), _c('hr'), !_vm.alertModalNoData ? _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.levelButtons, function (btn) {
    return _c('div', {
      key: btn.level,
      staticClass: "d-flex align-items-center mb-3 mr-4"
    }, [_c('button', {
      staticClass: "btn btn-sm text-nowrap",
      class: [_vm.alertFilterLevelNum.includes(btn.level) ? "btn-".concat(btn.color) : "btn-outline-".concat(btn.color)],
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleAlertLevelBtn(btn.level);
        }
      }
    }, [_vm._v(" " + _vm._s(btn.text) + " ( " + _vm._s(_vm.levelSum(btn.level)) + " ) ")])]);
  }), 0) : _vm._e(), !_vm.alertModalNoData ? _c('b-table', {
    staticClass: "alertTable",
    attrs: {
      "dark": _vm.tableMode,
      "head-variant": "light",
      "hover": "",
      "responsive": true,
      "bordered": "",
      "fields": _vm.alertField,
      "items": _vm.showFilteredAlertData,
      "busy": _vm.alertTableLoading,
      "sort-by": "告警啟動時間",
      "sortDesc": true,
      "per-page": _vm.alertPerPage,
      "current-page": _vm.alertCurrentPage,
      "tbody-tr-class": _vm.tbodyTrClass
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v("讀取中...")])], 1)];
      },
      proxy: true
    }], null, false, 622733708)
  }) : _vm._e(), _c('b-alert', {
    staticClass: "text-center font-weight-bold",
    attrs: {
      "show": _vm.alertModalNoData,
      "variant": "primary"
    }
  }, [_vm._v(" 沒有異常資料，請選擇其他日期 ")]), _vm.showFilteredAlertData.length ? _c('b-pagination', {
    attrs: {
      "per-page": _vm.alertPerPage,
      "total-rows": _vm.showFilteredAlertData.length
    },
    model: {
      value: _vm.alertCurrentPage,
      callback: function callback($$v) {
        _vm.alertCurrentPage = $$v;
      },
      expression: "alertCurrentPage"
    }
  }) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "allAlertModal",
      "size": "xl",
      "title": "告警事件",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "d-flex align-items-center my-2 mr-3 mr-sm-4 order-0"
  }, [_c('span', {
    staticClass: "mr-2 font-weight-bold"
  }, [_vm._v("選擇日期:")]), _c('date-picker', {
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "type": "date"
    },
    model: {
      value: _vm.allAlertTime,
      callback: function callback($$v) {
        _vm.allAlertTime = $$v;
      },
      expression: "allAlertTime"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center my-2 my-xl-3 mr-2 mr-lg-4 order-2 order-sm-1"
  }, [_c('b-button', {
    staticClass: "confirmButton",
    staticStyle: {
      "width": "60px"
    },
    on: {
      "click": function click($event) {
        return _vm.queryModal('all');
      }
    }
  }, [_vm._v("查詢")])], 1), _c('div', {
    staticClass: "d-flex align-items-center my-2 mr-4 order-1"
  }, [_c('span', {
    staticClass: "mr-2 font-weight-bold text-nowrap"
  }, [_vm._v("事件篩選:")]), _c('b-form-input', {
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "size": "sm"
    },
    model: {
      value: _vm.alertFilterInput,
      callback: function callback($$v) {
        _vm.alertFilterInput = $$v;
      },
      expression: "alertFilterInput"
    }
  })], 1), !_vm.alertModalNoData ? _c('div', {
    staticClass: "d-flex align-items-center font-weight-bold my-2 ml-auto order-3"
  }, [_vm._v(" 總告警: "), _c('span', {
    staticClass: "px-2 text-center",
    staticStyle: {
      "min-width": "50px",
      "font-family": "'微軟正黑體'"
    }
  }, [_vm._v(" " + _vm._s(_vm.filteredAlertData.length) + " ")]), _vm._v(" 筆 ")]) : _vm._e()]), _c('hr'), !_vm.alertModalNoData ? _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.levelButtons, function (btn) {
    return _c('div', {
      key: btn.level,
      staticClass: "d-flex align-items-center mb-3 mr-4"
    }, [_c('button', {
      staticClass: "btn btn-sm text-nowrap",
      class: [_vm.alertFilterLevelNum.includes(btn.level) ? "btn-".concat(btn.color) : "btn-outline-".concat(btn.color)],
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleAlertLevelBtn(btn.level);
        }
      }
    }, [_vm._v(" " + _vm._s(btn.text) + " ( " + _vm._s(_vm.levelSum(btn.level)) + " ) ")])]);
  }), 0) : _vm._e(), !_vm.alertModalNoData ? _c('div', [_c('b-table', {
    staticClass: "alertTable",
    attrs: {
      "head-variant": "light",
      "hover": "",
      "responsive": true,
      "bordered": "",
      "fields": _vm.alertField,
      "items": _vm.showFilteredAlertData,
      "busy": _vm.alertTableLoading,
      "sort-by": "告警啟動時間",
      "sortDesc": true,
      "per-page": _vm.alertPerPage,
      "current-page": _vm.alertCurrentPage,
      "tbody-tr-class": _vm.tbodyTrClass
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle mr-3"
        }), _c('strong', [_vm._v("讀取中...")])], 1)];
      },
      proxy: true
    }], null, false, 622733708)
  })], 1) : _vm._e(), _c('b-alert', {
    staticClass: "text-center font-weight-bold",
    attrs: {
      "show": _vm.alertModalNoData,
      "variant": "primary"
    }
  }, [_vm._v(" 沒有異常資料，請選擇其他日期 ")]), _vm.showFilteredAlertData.length ? _c('b-pagination', {
    attrs: {
      "per-page": _vm.alertPerPage,
      "total-rows": _vm.showFilteredAlertData.length
    },
    model: {
      value: _vm.alertCurrentPage,
      callback: function callback($$v) {
        _vm.alertCurrentPage = $$v;
      },
      expression: "alertCurrentPage"
    }
  }) : _vm._e()], 1), !_vm.clientTop ? _c('div', {
    staticClass: "backTop",
    on: {
      "click": _vm.backTop
    }
  }, [_c('i', {
    staticClass: "fas fa-chevron-up"
  })]) : _vm._e()], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', [_vm._v("日照強度"), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("(W/㎡)")])]);
}];
export { render, staticRenderFns };