import "core-js/modules/es.string.small.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "borderColor borderColorBackground my-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center m-3"
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "options": _vm.dataClass,
      "switches": ""
    },
    model: {
      value: _vm.dataClassArr,
      callback: function callback($$v) {
        _vm.dataClassArr = $$v;
      },
      expression: "dataClassArr"
    }
  }), _c('button', {
    staticClass: "confirmButton ml-auto",
    staticStyle: {
      "width": "120px"
    },
    on: {
      "click": _vm.downloadAnalysis
    }
  }, [_vm._v(" 資料下載 ")])], 1), _vm.hasNoData ? _c('div', {
    staticClass: "bg-white text-center",
    staticStyle: {
      "line-height": "350px"
    }
  }, [_vm._v(" 尚無資料 ")]) : _c('highcharts', {
    ref: "chart",
    staticClass: "bg-white",
    attrs: {
      "options": _vm.retentionChart
    }
  })], 1), _c('div', {
    staticClass: "borderColor"
  }, [_c('b-table', {
    staticClass: "tableStyle",
    attrs: {
      "items": _vm.retentionData,
      "fields": _vm.tableField,
      "hover": "",
      "responsive": true,
      "small": _vm.small
    },
    scopedSlots: _vm._u([{
      key: "cell()",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value === "" ? "-" : data.value) + " ")];
      }
    }])
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };